<template>
  <div class="mx-0 mx-md-3 mx-lg-6">
    <div class="row row-gap-2">
      <div v-if="customer" class="col-lg-6">
        <h1 class="fs-lg mb-0">
          <span>{{ customer.name }}</span>
        </h1>
        <a
          v-if="customer.email"
          class="fs-sm fw-medium text-muted d-block"
          :href="`mailto:${customer.email}`"
        >
          <i class="far fa-envelope"></i>
          {{ customer.email }}
        </a>
        <a
          v-if="customer.email"
          class="fs-sm fw-medium text-muted d-block"
          :href="`tel:${customer.telephone}`"
        >
          <i class="fa fa-phone"></i>
          {{ customer.telephone }}
        </a>
      </div>
      <div v-if="address" class="col-lg-6">
        <div class="block block-rounded block-bordered">
          <div class="block-header border-bottom">
            <h3 class="block-title">Delivery Address</h3>
          </div>
          <div class="block-content">
            <address class="fs-sm" v-html="address"></address>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="table-responsive">
        <table class="table w-100">
          <thead>
            <tr>
              <th>Part</th>
              <th class="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <div class="d-flex gap-2">
                  <img
                    class="img-thumbnail thumbnail-xs object-fit-cover"
                    :src="item.image ? item.image : partPlaceholderImage"
                  />
                  <div>
                    <span v-if="item.quick_view_url" class="me-1">
                      <partDrawer
                        :source-url="item.quick_view_url"
                      ></partDrawer>
                    </span>
                    <a v-if="item.url" :href="item.url" target="_blank">
                      {{ item.part }}
                    </a>
                    <span v-else>
                      {{ item.part }}
                    </span>
                    <div v-if="item.sku" class="text-muted">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">{{ item.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="courier" class="mb-4">
      <b>Courier Information</b>
      <div class="d-flex gap-2">
        <img
          class="img-thumbnail thumbnail-sm object-fit-cover"
          :src="courier.image ? courier.image : partPlaceholderImage"
        />
        <div>
          <span>{{ courier.label }}</span>
          <span v-if="trackingNumber" class="fst-italic">
            &nbsp;[{{ trackingNumber }}]
          </span>
          <a
            v-if="trackingUrl"
            class="d-block help-block text-muted"
            :href="trackingUrl"
            target="_blank"
          >
            <i class="fa fa-arrow-up-right-from-square"></i>
            {{ trackingUrl }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { addressFormat } from "@/utilities/addressFormat.js";
import partDrawer from "@/components/admin/parts/partDrawer.vue";

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});

const partPlaceholderImage = window.placeholderImage;
const address = ref(null);
const customer = ref(null);
const items = ref({});
const courier = ref(null);
const trackingNumber = ref(null);
const trackingUrl = computed(() =>
  courier.value?.tracking_url && trackingNumber.value
    ? new URL(
        courier.value.tracking_url.replace(
          "[TRACKING NUMBER]",
          trackingNumber.value
        )
      ).toString()
    : null
);

onStepsDataChange();
function onStepsDataChange() {
  if (props.stepsData.pickings.customer) {
    customer.value = props.stepsData.pickings.customer;
  }
  if (props.stepsData.pickings.address) {
    address.value = addressFormat(props.stepsData.pickings.address);
  }
  let mappedItems = {};
  if (props.stepsData.pickings.pickings) {
    for (const [_, data] of Object.entries(props.stepsData.pickings.pickings)) {
      if (Array.isArray(data.items)) {
        data.items.forEach((item) => {
          let lookupKey = item.part_id || item.part;
          let mappedItem;
          if (Object.prototype.hasOwnProperty.call(mappedItems, lookupKey)) {
            mappedItem = mappedItems[lookupKey];
          } else {
            // Clone item and drop the reference
            mappedItem = Object.assign({}, item);
            mappedItem.quantity = 0;
          }
          mappedItem.quantity += item.quantity;
          mappedItems[lookupKey] = mappedItem;
        });
      }
    }
  }

  items.value = mappedItems;
  if (props.stepsData.courier.courier) {
    courier.value = props.stepsData.courier.courier;
  }
  if (props.stepsData.courier.trackingNumber) {
    trackingNumber.value = props.stepsData.courier.trackingNumber;
  }
}
</script>
