<template>
  <div class="file-upload-container">
    <div
      class="file-upload-container d-flex position-relative"
      :class="{
        processing: progressStatus === 1,
        complete: progressStatus === 2,
      }"
    >
      <button
        class="btn bg-primary-lighter fs-sm rounded-circle action-button delete"
        @click.prevent="emit('remove')"
      >
        <span>&#x2715;</span>
      </button>
      <span
        v-if="isFirst && showPrimaryLabel"
        class="main-tag text-primary fw-bolder"
      >
        PRIMARY
      </span>
      <img
        v-if="mimeType?.includes('image')"
        class="w-100 h-100 border border-2 rounded preview"
        :class="{ 'border-primary': isFirst }"
        :src="url"
        alt="Image preview"
      />
      <div
        v-else-if="mimeType?.includes('pdf')"
        class="w-100 h-100 border border-2 rounded preview d-flex justify-content-center align-items-center"
        :class="{ 'border-primary': isFirst }"
      >
        <i class="far fa-file-pdf text-muted fa-2xl"></i>
      </div>
      <div
        v-else
        class="w-100 h-100 border border-2 rounded preview d-flex justify-content-center align-items-center"
        :class="{ 'border-primary': isFirst }"
      >
        <i class="far fa-file-alt text-muted fa-2xl"></i>
      </div>
      <template v-if="canBeMoved">
        <button
          class="btn d-flex justify-content-center align-items-center bg-primary-lighter fs-sm rounded-circle action-button left"
          :disabled="isFirst"
          @click.prevent="emit('request-move-left')"
        >
          <i class="fa fa-angle-left"></i>
        </button>
        <button
          class="btn d-flex justify-content-center align-items-center bg-primary-lighter fs-sm rounded-circle action-button right"
          :disabled="isLast"
          @click.prevent="emit('request-move-right')"
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </template>
      <a
        v-if="publicViewUrl"
        :href="publicViewUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="btn d-flex justify-content-center align-items-center bg-primary-lighter fs-sm rounded-circle action-button"
        style="bottom: 35px; left: 5px"
      >
        <i class="fa fa-eye"></i>
      </a>
      <a
        v-if="publicDownloadUrl"
        :href="publicDownloadUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="btn d-flex justify-content-center align-items-center bg-primary-lighter fs-sm rounded-circle action-button"
        style="bottom: 5px; left: 5px"
      >
        <i class="fa fa-download"></i>
      </a>
      <div class="progress bg-primary-lighter">
        <span class="upload bg-primary" :style="`width: ${progress}%;`"></span>
      </div>
    </div>
    <textarea
      v-if="allowComments"
      v-model="comments"
      class="form-control mt-2"
      placeholder="Comment"
      style="resize: none; font-size: 10px; height: 48px"
    ></textarea>
  </div>
  <input ref="signatureInput" type="hidden" :name="`${inputName}[signature]`" />
  <input
    v-if="allowComments"
    type="hidden"
    :name="`${inputName}[comments]`"
    :value="comments"
  />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const emit = defineEmits([
  "request-move-left",
  "request-move-right",
  "remove",
  "invalid-file",
  "file-validated",
]);
const props = defineProps({
  tempUploadFormUrl: {
    type: String,
    required: true,
  },
  id: {
    type: [String, Number],
    default: null,
  },
  file: {
    type: [File, String],
    default: null,
  },
  publicDownloadUrl: {
    type: String,
    default: null,
  },
  publicViewUrl: {
    type: String,
    default: null,
  },
  isDragged: {
    type: Boolean,
    default: false,
  },
  namePrefix: {
    type: String,
    default: "upload",
  },
  showPrimaryLabel: {
    type: Boolean,
    default: false,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  canBeMoved: {
    type: Boolean,
    default: false,
  },
  allowedFormat: {
    type: String,
    default: "image/png, image/jpeg",
  },
  allowComments: {
    type: Boolean,
    default: false,
  },
  source: {
    type: String,
    default: "thumbnail",
  },
  mimeType: {
    type: String,
    default: null,
  },
  comments: {
    type: String,
    default: "",
  },
});

const comments = ref(props.comments);
const signatureInput = ref(null);
let isUploadRequired = false;
let url = props.file;
if (props.file instanceof File) {
  url = URL.createObjectURL(props.file);
  isUploadRequired = true;
}
// 0 - not started; 1 - processing; 2 - complete
const progressStatus = ref(0);
// progress percentage
const progress = ref(0);

const inputName = computed(() => `${props.namePrefix}_${props.id}`);
const mimeType = computed(
  () =>
    props.mimeType ||
    (props.file instanceof File ? props.file.type : "text/plain")
);

let signatureValue = null;
onMounted(() => {
  if (isUploadRequired) {
    const regex = new RegExp(
      props.allowedFormat
        .split(",")
        .map((s) => s.trim())
        .join("|")
    );
    if (!regex.test(props.file.type)) {
      error("Invalid file format.");
      return;
    }
    signatureInput.value.uploadFile({
      allowedFormat: props.allowedFormat,
      source: props.source,
      dynamic: true,
      isDragUpload: props.isDragged,
      tempUploadFormUrl: props.tempUploadFormUrl,
      file: props.file,
      success: function ({ signature }) {
        signatureInput.value.value = signatureValue = signature;
        emit("file-validated");
      },
      error: function () {
        error();
      },
      onUploadProgress: function (progress) {
        setProgress(progress);
      },
    });
  } else {
    setProgress(100);
  }
});

function error(message) {
  if (message) {
    Swal.fire({
      target: document.getElementById("page-container"),
      icon: "error",
      title: "Could not upload file " + props.file.name,
      text: message,
    });
  }
  emit("invalid-file");
}

function setProgress(value) {
  value = Math.min(100, Math.max(0, value)); // Ensure value is [0;100]
  progress.value = value;
  progressStatus.value = value === 100 ? 2 : 1;
}

function serialize() {
  return {
    id: props.id,
    signature: signatureValue,
    mime_type: mimeType.value,
    comments: comments.value,
  };
}

defineExpose({
  serialize,
  name: inputName,
});
</script>

<style lang="scss" scoped>
.file-upload-container {
  width: 125px;
  aspect-ratio: 1/1;

  &:hover {
    .action-button:not(:disabled) {
      opacity: 1;
    }
  }

  .preview {
    object-fit: cover;
  }

  .action-button {
    position: absolute;
    width: 15px;
    aspect-ratio: 1/1;
    border-style: unset !important;
    opacity: 0.3;
  }

  .left {
    bottom: 5px;
    right: 35px;
  }

  .right {
    bottom: 5px;
    right: 5px;
  }

  .delete {
    top: 5px;
    right: 5px;

    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .main-tag {
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.file-upload-container {
  &.processing .progress {
    opacity: 1;
    transition: all 0.2s linear;
  }

  &.complete .progress {
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }

  &:not(.processing) .progress {
    animation: pulse 6s ease infinite;
  }

  .progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;

    .upload {
      background: linear-gradient(to bottom, #83cfdd, #069fba);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      transition: width 300ms ease-in-out;
    }
  }
}
</style>
