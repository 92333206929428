// import './bootstrap';
import "./utilities/ajaxForm.js";
import "./utilities/ajaxModal.js";
import "./utilities/uploadFile.js";
import "./utilities/priceFormat.js";

import Swal from "sweetalert2";
import { createApp } from "vue";
import { useMediaQuery } from "@vueuse/core";
import flatpickr from "flatpickr";
import { useFetch } from "@/utilities/useFetch.js";
import { Eventbus } from "@/utilities/eventbus.js";

import QuotesDraftItem from "@/components/admin/quotes/draft/item.vue";
import QuotesPricingItem from "@/components/admin/quotes/pricing/item.vue";
import CustomerOrdersItem from "@/components/admin/customerOrders/components/item.vue";
import SupplierOrdersItem from "@/components/admin/supplierOrders/formWizard/goodsIn/item.vue";
import SupplierOrdersDropShipItem from "@/components/admin/supplierOrders/formWizard/dropShip/item.vue";
import QuoteStockCheckItem from "@/components/admin/quotes/stockCheck/item.vue";

import AddressList from "@/components/admin/addresses/list.vue";
import CategoryList from "@/components/admin/categories/list.vue";
import CustomerList from "@/components/admin/customers/list.vue";
import MergeCustomer from "@/components/admin/customers/mergeCustomer.vue";
import CustomerCreditLimit from "@/components/admin/customers/creditLimit.vue";
import CurrencyList from "@/components/admin/currencies/list.vue";
import EditableValue from "@/components/admin/utilities/editableValue.vue";
import IndividualCustomerOrderList from "@/components/admin/customers/individualCustomerOrderList.vue";
import PickingList from "@/components/admin/picking/list.vue";
import PickingContainer from "@/components/admin/picking/pickingContainer.vue";
import CustomerOrderItemSoldBy from "@/components/admin/customerOrders/components/soldBy.vue";
import CustomerOrderList from "@/components/admin/customerOrders/list.vue";
import CustomerOrderWizard from "@/components/admin/customerOrders/formWizard/wizard.vue";
import CustomerOrderPayment from "@/components/admin/customerOrders/components/payment.vue";
import RefundList from "@/components/admin/refunds/list.vue";
import RefundForm from "@/components/admin/refunds/RefundForm.vue";
import ManufacturerList from "@/components/admin/manufacturers/list.vue";
import LocationList from "@/components/admin/locations/list.vue";
import LocationStock from "@/components/admin/locations/stock.vue";
import KitList from "@/components/admin/kits/list.vue";
import KitComponents from "@/components/admin/kits/form/kitComponents.vue";
import PartList from "@/components/admin/parts/list.vue";
import PartManualStockAdjustment from "@/components/admin/parts/manualStockAdjustment.vue";
import PartManufacturingMap from "@/components/admin/parts/manufacturing/manufacturingMap.vue";
import PartManufacturingManufacturer from "@/components/admin/parts/manufacturing/manufacturer.vue";
import PartManufacturingSeries from "@/components/admin/parts/manufacturing/series.vue";
import PartManufacturingVehicle from "@/components/admin/parts/manufacturing/vehicle.vue";
import PartNumberLookup from "@/components/admin/parts/manufacturing/partNumberLookup.vue";
import QuoteLossReasons from "@/components/admin/quoteLossReasons/reasons.vue";
import QuoteLossReason from "@/components/admin/quoteLossReasons/reason.vue";
import QuoteList from "@/components/admin/quotes/list.vue";
import IndividualCustomerQuoteList from "@/components/admin/customers/individualCustomerQuoteList.vue";
import DraftQuoteWizard from "@/components/admin/quotes/draft/wizard.vue";
import PricingQuoteWizard from "@/components/admin/quotes/pricing/wizard.vue";
import QuoteStockCheckItems from "@/components/admin/quotes/stockCheck/items.vue";
import TractorList from "@/components/admin/tractors/list.vue";
import TractorDismantle from "@/components/admin/tractors/dismantle.vue";
import TractorDismantledPartsList from "@/components/admin/tractors/dismantledPartsList.vue";
import SupplierList from "@/components/admin/suppliers/list.vue";
import GoodsReceivedList from "@/components/admin/goodsReceived/list.vue";
import DeliveryZoneList from "@/components/admin/deliveryZones/list.vue";
import DeliveryCountryList from "@/components/admin/deliveryCountries/list.vue";
import DeliveryBandList from "@/components/admin/deliveryBands/list.vue";
import CourierList from "@/components/admin/couriers/list.vue";
import ShipmentList from "@/components/admin/shipments/list.vue";
import ShipmentWizard from "@/components/admin/shipments/formWizard/wizard.vue";
import IndividualSupplierOrderList from "@/components/admin/suppliers/individualSupplierOrderList.vue";
import SupplierOrderList from "@/components/admin/supplierOrders/list.vue";
import SupplierOrderWizard from "@/components/admin/supplierOrders/formWizard/wizard.vue";
import SeriesList from "@/components/admin/series/list.vue";
import UsersList from "@/components/admin/users/list.vue";
import VehicleList from "@/components/admin/vehicles/list.vue";
import DynamicNotifications from "@/components/admin/utilities/dynamicNotifications/notifications.vue";
import DarkModeToggle from "@/components/admin/utilities/darkModeToggle.vue";
import LiveInput from "@/components/admin/utilities/liveInput.vue";
import AddressQuickView from "@/components/admin/addresses/addressQuickView.vue";
import FormAddress from "@/components/admin/addresses/address.vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import FileUploadContainer from "@/components/admin/utilities/fileUpload/fileUploadContainer.vue";
import ImageGallery from "@/components/admin/utilities/imageGallery/imageGallery.vue";
import DependantManufacturingSelect from "@/components/admin/utilities/dependantManufacturingSelect.vue";
import ImportCustomerModal from "@/components/admin/Modals/ImportCustomerModal.vue";
import LinkDealModal from "@/components/admin/Modals/LinkDealModal.vue";
import PartDrawer from "@/components/admin/parts/partDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";
import SupplierOrderDrawer from "@/components/admin/supplierOrders/supplierOrderDrawer.vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";

import SearchPane from "@/components/admin/utilities/SearchPane.vue";
import ManageAttachmentsModal from "@/components/admin/quotes/components/ManageAttachmentsModal.vue";
import AttachmentsButton from "@/components/admin/quotes/components/AttachmentsButton.vue";
import DashboardIndex from "@/components/admin/dashboard/index.vue";
import PartFinder from "@/components/admin/parts/Finder.vue";
import KitEquivalentPartsField from "@/components/admin/kits/form/EquivalentPartsField.vue";
import InlineEditList from "@/components/admin/utilities/inlineEditList.vue";

// Expose Swal & Eventbus so we can use it in blade files
window.Swal = Swal;
window.Eventbus = Eventbus;
window.Toast = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

// Set up as global variable so that breakpoint is consistent across all components
window.isLargeScreen = useMediaQuery("(min-width: 992px)");
window.isDarkMode = document
  .getElementById("page-container")
  .classList.contains("dark-mode");

const components = {
  "inline-edit-list": InlineEditList,
  "address-quick-view": AddressQuickView,
  "editable-value": EditableValue,
  "form-address": FormAddress,
  "form-select": FormSelect,
  "file-upload-container": FileUploadContainer,
  "image-gallery": ImageGallery,
  "addresses-list": AddressList,
  "categories-list": CategoryList,
  "customers-list": CustomerList,
  "merge-customer": MergeCustomer,
  "individual-customer-orders-list": IndividualCustomerOrderList,
  "currencies-list": CurrencyList,
  "picking-list": PickingList,
  "picking-container": PickingContainer,
  "customer-order-item-sold-by": CustomerOrderItemSoldBy,
  "customer-orders-list": CustomerOrderList,
  "customer-order-wizard": [
    CustomerOrderWizard,
    {
      item: CustomerOrdersItem,
    },
  ],
  "customer-order-payment": CustomerOrderPayment,
  "customer-credit-limit": CustomerCreditLimit,
  "refunds-list": RefundList,
  "refund-form": RefundForm,
  "manufacturers-list": ManufacturerList,
  "parts-list": PartList,
  "part-manual-stock-adjustment": PartManualStockAdjustment,
  "kits-list": KitList,
  "kit-components": KitComponents,
  "manufacturing-map": [
    PartManufacturingMap,
    {
      manufacturer: PartManufacturingManufacturer,
      series: PartManufacturingSeries,
      vehicle: PartManufacturingVehicle,
    },
  ],
  "part-number-lookup": PartNumberLookup,
  "quotes-list": QuoteList,
  "individual-customer-quotes-list": IndividualCustomerQuoteList,
  "quote-loss-reasons-list": [
    QuoteLossReasons,
    {
      reason: QuoteLossReason,
    },
  ],
  "draft-quote-wizard": [
    DraftQuoteWizard,
    {
      item: QuotesDraftItem,
    },
  ],
  "pricing-quote-wizard": [
    PricingQuoteWizard,
    {
      item: QuotesPricingItem,
    },
  ],
  "quote-stock-check-items": [
    QuoteStockCheckItems,
    {
      item: QuoteStockCheckItem,
    },
  ],
  "locations-list": LocationList,
  "location-stock": LocationStock,
  "tractors-list": TractorList,
  "tractor-dismantle": TractorDismantle,
  "tractor-dismantled-parts-list": TractorDismantledPartsList,
  "suppliers-list": SupplierList,
  "individual-supplier-orders-list": IndividualSupplierOrderList,
  "goods-received-list": GoodsReceivedList,
  "delivery-zones-list": DeliveryZoneList,
  "delivery-countries-list": DeliveryCountryList,
  "delivery-bands-list": DeliveryBandList,
  "couriers-list": CourierList,
  "shipments-list": ShipmentList,
  "shipment-wizard": ShipmentWizard,
  "supplier-orders-list": SupplierOrderList,
  "supplier-order-wizard": [
    SupplierOrderWizard,
    {
      item: SupplierOrdersItem,
      dropShipItem: SupplierOrdersDropShipItem,
    },
  ],
  "series-list": SeriesList,
  "users-list": UsersList,
  "vehicles-list": VehicleList,
  "dynamic-notifications": DynamicNotifications,
  "dark-mode-toggle": DarkModeToggle,
  "live-input": LiveInput,
  "dependant-manufacturing-select": DependantManufacturingSelect,
  "dashboard-index": DashboardIndex,
  "search-pane": SearchPane,
  "manage-attachments-modal": ManageAttachmentsModal,
  "attachments-button": AttachmentsButton,

  "part-drawer": PartDrawer,
  "customer-order-drawer": CustomerOrderDrawer,
  "supplier-order-drawer": SupplierOrderDrawer,
  "quotes-drawer": QuotesDrawer,
  "part-finder": PartFinder,
  "kit-equivalent-parts-field": KitEquivalentPartsField,
  "import-customer-modal": ImportCustomerModal,
  "link-deal-modal": LinkDealModal,
};

const initVueComponents = function (container) {
  let n = 0;
  for (const tag in components) {
    for (const el of container.querySelectorAll(tag)) {
      const component = Array.isArray(components[tag])
        ? components[tag][0]
        : components[tag];

      const app = createApp({
        name: `${tag} ${++n}`,
        template: el.outerHTML,
      });
      app.component(tag, component);
      app.provide(
        "csrfToken",
        document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content")
      );
      if (Array.isArray(components[tag])) {
        for (const childTag in components[tag][1]) {
          app.component(childTag, components[tag][1][childTag]);
        }
      }
      app.mount(el);
    }
  }
};
initVueComponents(document);

const attachAjaxModalListeners = function (el) {
  let ajaxModals = el.querySelectorAll(".ajax-modal");
  if (ajaxModals.length) {
    for (let modal of ajaxModals) {
      modal.ajaxModal();
    }
  }
};
attachAjaxModalListeners(document);

const attachAjaxFormListeners = function (el) {
  let ajaxForms = el.querySelectorAll(".ajax-form");
  if (ajaxForms.length) {
    for (let form of ajaxForms) {
      form.ajaxForm();
    }
  }
};
attachAjaxFormListeners(document);

let goToTabFromHash = function () {
  const hash = window.location.hash.split("#");
  const currentTab = document.querySelector(
    '.nav-link[data-bs-target="#' + hash[1] + '"]'
  );
  if (currentTab) {
    const bsTab = new bootstrap.Tab(currentTab);
    bsTab.show();
  }
};

// are we deep-linked to a tab?
if (window.location.hash) {
  goToTabFromHash();
}

Eventbus.on("datatable.updated", (el) => {
  attachAjaxModalListeners(el);
  attachAjaxFormListeners(el);
});

window.document.querySelectorAll(".slug-input")?.forEach(function (input) {
  let linksTo = input.dataset.linksTo;
  if (linksTo) {
    let form = input.closest("form");
    form.querySelector("#" + linksTo)?.addEventListener("input", (e) => {
      if (input.classList.contains("modified")) {
        return;
      }
      // Slugify
      input.value = e.target.value
        .toLowerCase() // Convert the string to lowercase
        .replace(/[^a-z0-9]/g, "-") // Replace non-alphanumeric characters with hyphens
        .replace(/-+/g, "-") // Replace multiple consecutive hyphens with a single hyphen
        .replace(/^-|-$/g, ""); // Remove hyphens from the beginning or end of the string
    });

    input.addEventListener("input", () => {
      input.classList.add("modified");
    });
  }
});

document.querySelector("#main-container")?.addEventListener("click", (e) => {
  // swal confirm delete
  let trigger = e.target.closest(".swal-confirm-delete");
  if (trigger !== null) {
    confirmHandler({
      confirmButtonText: "Delete",
      confirmDetailsHtml: trigger.getAttribute("data-delete-text"),
      confirmQuestion: "Do you want to delete this?",
      fetchAction: "delete",
      requestHref: trigger.getAttribute("data-delete-href"),
      successTitle: "Deleted",
      successEmit: trigger.getAttribute("data-delete-then"),
    });
    return;
  }
  // general swal confirm
  trigger = e.target.closest(".swal-confirm");
  if (trigger !== null) {
    confirmHandler(trigger.dataset);
    return;
  }

  // swal info
  trigger = e.target.closest(".swal-info");
  if (trigger !== null) {
    infoHandler(trigger.dataset);
  }
});

document.addEventListener("show.bs.modal", function (event) {
  initVueComponents(event.target);
  attachAjaxFormListeners(event.target);
});

let confirmHandler = async function (options) {
  if (options["requestHref"] === null) {
    return;
  }
  let inputValidator = null;
  if (options["inputValidationMatch"]) {
    options["input"] = "text";
    inputValidator = (value) => {
      if (value !== options["inputValidationMatch"]) {
        return `Enter "${options["inputValidationMatch"]}" to confirm your action`;
      }
    };
  }

  const result = await Swal.fire({
    target: document.getElementById("page-container"),
    title: options["confirmQuestion"] || "Are you sure?",
    text: options["confirmText"] || "",
    html: options["confirmDetailsHtml"] || "",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: options["confirmButtonText"] || "Confirm",
    customClass: {
      confirmButton: options["confirmButtonClass"] || "bg-danger",
    },
    reverseButtons: true,
    focusConfirm: false,
    input: options["input"] || null,
    inputOptions:
      options["inputOptions"] != null
        ? JSON.parse(options["inputOptions"])
        : {},
    inputPlaceholder: options["inputPlaceholder"] || "",
    inputValidator: inputValidator,
  });

  if (!result.isConfirmed) {
    return;
  }

  const response = await useFetch(options["requestHref"], {
    method: options["fetchAction"] || "GET",
    body: options["input"] != null ? { value: result.value } : null,
  });

  if (!response.ok) {
    Swal.fire({
      target: document.getElementById("page-container"),
      icon: "error",
      title: "Error",
      text: "System error",
      customClass: {
        confirmButton: "bg-danger",
      },
      showConfirmButton: true,
    });
    return;
  }

  let data;
  try {
    data = await response.json();
  } catch (e) {
    if (response.status !== 204) {
      throw new Error(e);
    }
  }

  if (data?.message?.type === "error") {
    Swal.fire({
      target: document.getElementById("page-container"),
      icon: "error",
      title: "Error",
      text: data.message.title,
      customClass: {
        confirmButton: "bg-danger",
      },
      showConfirmButton: true,
    });
  } else {
    Swal.fire({
      target: document.getElementById("page-container"),
      icon: "success",
      title: options["successTitle"] || "Success",
      timer: 1500,
      timerProgressBar: true,
      showConfirmButton: false,
    }).then(() => {
      if (options["successRedirect"]) {
        window.location = options["successRedirect"];
      } else if (options["shouldReloadOnSuccess"]) {
        location.reload();
      } else if (data?.redirect) {
        window.location = data.redirect;
      } else if (options["successEmit"]) {
        Eventbus.emit(options["successEmit"]);
      }
    });
  }
};

let infoHandler = function (options) {
  Swal.fire({
    target: document.getElementById("page-container"),
    title: options["infoTitle"],
    html: options["infoHtml"],
    icon: "info",
  });
};

let tabButtons = document.querySelectorAll(".nav-link[data-bs-target]");
if (tabButtons.length) {
  for (let tabButton of tabButtons) {
    tabButton.addEventListener("show.bs.tab", function (ev) {
      window.location.hash = ev.target.getAttribute("data-bs-target");
    });
  }

  window.onpopstate = goToTabFromHash;
}

let formCheckboxesWithDependencies = document.querySelectorAll(
  ".form-check-input[data-form-dependant-fields]"
);
if (formCheckboxesWithDependencies.length) {
  for (let checkbox of formCheckboxesWithDependencies) {
    checkbox.addEventListener("change", function (e) {
      let elements = document.querySelectorAll(
        JSON.parse(e.currentTarget.dataset.formDependantFields)
          .map((el) => {
            return '[name="' + el + '"]';
          })
          .join(", ")
      );

      if (elements.length) {
        let display = e.currentTarget.checked ? "block" : "none";
        for (let element of elements) {
          element.closest(".form-group").style.display = display;
        }
      }
    });
    let changeEvent = new Event("change");
    checkbox.dispatchEvent(changeEvent);
  }
}

// Listen for international invoice modal
document.addEventListener("shown.bs.modal", function (event) {
  if (event.target.classList.contains("international-invoice-modal")) {
    let init = true;
    event.target.querySelector("#modal_form").ajaxForm({
      success: function (response) {
        if (response.pdf_url) {
          window.open(response.pdf_url, "_blank");
        }
        let bsModal = bootstrap.Modal.getInstance(event.target);
        if (bsModal) {
          bsModal.hide();
        }
        window.Toast.fire({
          icon: "success",
          text: "International invoice details updated.",
        });
      },
    });
    const netWeightInput = event.target.querySelector("#total_net_weight");
    const netWeightSuggestion = event.target.querySelector(
      "#suggested_net_weight"
    );
    const recalculateTotalWeight = function () {
      let totalWeight = 0;
      event.target.querySelectorAll("input.weight").forEach(function (input) {
        totalWeight +=
          (parseFloat(input.value) || 0) * (input.dataset.quantity || 1);
      });
      netWeightSuggestion.textContent = totalWeight.toFixed(2);
      if (init && netWeightInput.value !== "") {
        return;
      }
      if (!netWeightInput.classList.contains("manually-changed")) {
        netWeightInput.value = totalWeight.toFixed(3);
      }
    };
    recalculateTotalWeight();
    event.target.querySelectorAll("input.weight").forEach(function (input) {
      input.addEventListener("input", recalculateTotalWeight);
    });

    // Remove event listeners when net weight is manually changed by user
    netWeightInput.addEventListener("input", function () {
      netWeightInput.classList.add("manually-changed");
    });

    init = false;
  }
});
flatpickr(".js-flatpickers");
