<template>
  <input ref="hiddenInput" type="hidden" :name="name" :value="inputValue" />
  <div v-if="options.length > 0" class="d-flex flex-wrap gap-4 row-gap-3">
    <div
      v-for="option in options"
      :key="option.value"
      class="image-option"
      role="button"
      @click="valueChanged(option.value)"
    >
      <img
        :src="option.image ? option.image : placeholderImage"
        :alt="option.label"
        class="img-thumbnail w-100 border border-2 rounded preview"
        :class="{
          'border-primary': option.value === inputValue,
        }"
      />
      <div
        class="text-center ellipsis-2"
        :class="{
          'text-primary': option.value === inputValue,
        }"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
  <div v-else class="text-muted">No options available</div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFetch } from "@/utilities/useFetch.js";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Object, Number, String],
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  url: {
    type: String,
    default: "",
  },
  allowDeselect: {
    type: Boolean,
    default: false,
  },
});
const isLargeScreen = window.isLargeScreen;
const placeholderImage = window.placeholderImage;
const optionWidth = isLargeScreen.value ? "125px" : "75px";

const options = ref(props.options);
watch(
  () => props.options,
  () => (options.value = props.options)
);

const inputValue = ref("");
const hiddenInput = ref(null);

function valueChanged(value) {
  if (value === inputValue.value) {
    if (!props.allowDeselect) {
      return;
    }
    value = null;
  }

  inputValue.value = value;
  emit(
    "update:modelValue",
    options.value
      ? options.value.find((option) => option.value === value)
      : value
  );
}

let fetchAbort, fetchAbortSignal;
async function ajaxOptions(searchTerm) {
  if (!props.url) {
    return;
  }

  options.value = [];

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  let urlToFetch = new URL(props.url);
  if (searchTerm) {
    urlToFetch.searchParams.set("search", searchTerm);
  }

  const res = await useFetch(urlToFetch, { signal: fetchAbortSignal });

  let response = await res.json();
  options.value = response.data;

  if (props.modelValue) {
    valueChanged(
      typeof props.modelValue === "object"
        ? props.modelValue.value
        : props.modelValue
    );
  }
}
ajaxOptions();
</script>

<style lang="scss" scoped>
.image-option {
  width: v-bind(optionWidth);

  img {
    aspect-ratio: 1/1;
  }
}

.preview {
  object-fit: cover;
}
</style>
