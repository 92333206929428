<template>
  <div v-if="tabData !== null">
    <div class="row items-push">
      <tile-detailed
        title="Total Team Sales"
        :value="tabData.tiles.team_sales_sum"
        :has-button="false"
        icon="fa fa-sterling-sign"
        width="col-sm-6 col-xxl-6"
      ></tile-detailed>
      <tile-detailed
        title="Quotes in Progress"
        :value="tabData.tiles.quotes_in_progress"
        :has-button="false"
        icon="fa fa-file"
        width="col-sm-6 col-xxl-6"
      ></tile-detailed>
    </div>
    <div class="block block-rounded p-3">
      <LineChart :data="tabData.sales_chart" :options="chartOptions" />
    </div>
    <div v-if="tabData.loss_reasons_count > 0" class="block block-rounded">
      <ul class="nav nav-tabs nav-tabs-block" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" aria-selected="true">
            Most Common Loss Reasons
          </button>
        </li>
      </ul>
      <div class="block-content block-content-full">
        <LossTable :rows="tabData.loss_reasons" />
      </div>
    </div>
  </div>
  <div v-else class="block-content text-center">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { Line as LineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  registerables,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js";
import { useFetch } from "../../../utilities/useFetch.js";
import TileDetailed from "../utilities/oneui/tileDetailed.vue";
import LossTable from "./components/lossTable.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  ...registerables
);

export default {
  components: {
    LossTable,
    TileDetailed,
    LineChart,
  },
  props: {
    apiRoute: {
      type: String,
      default: "none",
    },
  },

  data() {
    return {
      tabData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            beginAtZero: true,
            stacked: true,
            parsing: {
              yAxisKey: "GBP",
            },
          },
        },
        aspectRatio: 4,
      },
    };
  },
  mounted() {
    const component = this;
    const getTabData = debounce(async function () {
      let req = await useFetch(component.apiRoute);
      component.tabData = await req.json();

      return component.tabData;
    }, 500);
    getTabData();
  },
};
</script>
