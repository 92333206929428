<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col-md-10 ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all parts.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div class="d-flex gap-2 mt-2 justify-content-end">
        <TableFilter
          label="Stock Level"
          :options="stockLevels"
          :selected="selectedStockFilter"
          @change="(value, label) => filterChanged('stock_level', value)"
        />
        <TableFilter
          label="Landing Category"
          :options="categories"
          @change="(value, label) => filterChanged('landing_category', value)"
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="parts-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="sku"
        @set-order-by="setOrder"
      >
        SKU
      </SortableTh>
      <SortableTh :current-order-by="orderBy">Landing Category</SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="stock"
        class="text-center"
        @set-order-by="setOrder"
      >
        Stock
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_active"
        class="text-center"
        @set-order-by="setOrder"
      >
        Active
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="part in data" :key="part.id">
        <td class="text-nowrap">
          <PartDrawer :source-url="part.quick_view_url" />
          {{ part.name }}
        </td>
        <td>{{ part.sku }}</td>
        <td>{{ part.landing_category }}</td>
        <td class="text-center" v-html="part.stock"></td>
        <td class="text-center">
          <template v-if="part.is_active">
            <i class="fa fa-check text-success"></i>
          </template>
          <template v-else>
            <i class="fa fa-times"></i>
          </template>
        </td>
        <td v-html="part.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive, computed, onMounted, nextTick } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import PartDrawer from "@/components/admin/parts/partDrawer.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  stockLevels: {
    type: Array,
    default: () => [],
  },
  categories: {
    type: Array,
    default: () => [],
  },
  stockFilter: {
    type: String,
    default: "",
  },
});

const orderBy = ref("name");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});
const selectedStockFilter = computed(() => {
  return customFilter["stock_level"] || props.stockFilter;
});

onMounted(() => {
  if (props.stockFilter) {
    nextTick(() => filterChanged("stock_level", props.stockFilter));
  }
});

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (customFilter[key] == value || value == null) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}

defineExpose({
  filterChanged,
});
</script>
