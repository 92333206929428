<template>
  <i
    class="text-primary"
    :class="{
      'fa fa-magnifying-glass': !isWaitingToOpen,
      'fa fa-spin fa-spinner': isWaitingToOpen,
    }"
    role="button"
    @click="openDrawer"
  ></i>
  <drawer v-if="quote" ref="drawerRef" :title="'Quote #' + quote.id" size="lg">
    <template #default>
      <div class="text-center">
        <div class="fs-base fw-semibold">{{ quote.customer.name }}</div>
        <div v-if="quote.customer.email" class="fs-sm fw-medium text-muted">
          <i class="far fa-envelope"></i>
          {{ quote.customer.email }}
        </div>
        <div v-if="quote.customer.telephone" class="fs-sm fw-medium text-muted">
          <i class="fa fa-phone"></i>
          {{ quote.customer.telephone }}
        </div>
      </div>
      <dl class="mt-3 d-grid gap-3" style="grid-template-columns: 1fr 1fr">
        <div class="dl-tile">
          <dt>Quote Price</dt>
          <dd>{{ quote.currency_symbol }}{{ quote.total_gross }}</dd>
        </div>
        <div class="dl-tile">
          <dt>Quote Status</dt>
          <dd v-html="quote.status"></dd>
        </div>
      </dl>
      <a v-if="quote.customer_order_url" :href="quote.customer_order_url">
        <div class="dl-tile">
          <dt>Click to view Customer Order</dt>
        </div>
      </a>
      <table class="table mt-3 text-wrap">
        <thead>
          <tr>
            <th>Item</th>
            <th>SKU</th>
            <th class="text-end">Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in quote.items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.sku }}</td>
            <td class="text-end">{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </drawer>
</template>

<script setup>
import { nextTick, ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import drawer from "@/components/admin/utilities/drawer.vue";

const props = defineProps({
  quoteData: {
    type: Object,
    default: null,
  },
  sourceUrl: {
    type: String,
    default: null,
  },
});

const isWaitingToOpen = ref(false);
const isLoading = ref(false);
const hasFailed = ref(false);
const drawerRef = ref(null);
const quote = ref(null);

const fetchData = async () => {
  if (isLoading.value || hasFailed.value) {
    return;
  }
  isLoading.value = true;
  const response = await useFetch(props.sourceUrl);
  if (!response.ok) {
    hasFailed.value = true;
    isLoading.value = false;
    return;
  }
  quote.value = (await response.json()).data;
  isLoading.value = false;
  if (isWaitingToOpen.value) {
    isWaitingToOpen.value = false;
    nextTick(() => {
      // Need to be in nextTick because the drawerRef is not yet mounted as it depends on the v-if="quote"
      openDrawer();
    });
  }
};

if (props.quoteData) {
  quote.value = props.quoteData;
}

const openDrawer = () => {
  if (!quote.value) {
    isWaitingToOpen.value = true;
    fetchData();
    return;
  }

  drawerRef.value?.openDrawer();
};
</script>
