<template>
  <div class="d-flex justify-content-between wizard-header position-relative">
    <div class="border-top spacer-line"></div>
    <template v-for="(step, i) in steps" :key="i">
      <div
        :role="
          i !== currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'
        "
        :class="{
          'd-flex flex-column align-items-center progress-number-wrapper': true,
          active: currentStep == i,
        }"
        @click="attemptGoToStep(parseInt(i))"
      >
        <div class="progress-number-inner-wrapper">
          <span
            class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number"
          >
            <i
              v-if="
                isAllPreviousStepsValid(i) &&
                i !== currentStep &&
                (i < currentStep || steps[i].validate())
              "
              class="fa fa-check text-flat"
            ></i>
            <span v-else>{{ i }}</span>
          </span>
        </div>
        <span class="fs-xs text-center">{{ step.label }}</span>
      </div>
    </template>
  </div>

  <div class="mt-4 mb-3">
    <div v-show="currentStep == 1">
      <PickingsStep
        :routes="routes"
        :extras="extras"
        :steps-data="stepsData"
        @step-value-changed="(data) => setStepData('pickings', data)"
      ></PickingsStep>
    </div>
    <div v-if="currentStep == 2">
      <PackingNoteStep
        :routes="routes"
        :extras="extras"
        :steps-data="stepsData"
        @step-value-changed="(data) => setStepData('packingNote', data)"
      ></PackingNoteStep>
    </div>
    <div v-show="currentStep == 3">
      <CourierStep
        :routes="routes"
        :extras="extras"
        :steps-data="stepsData"
        @step-value-changed="(data) => setStepData('courier', data)"
      ></CourierStep>
    </div>
    <div v-if="currentStep == 4">
      <SummaryStep
        :routes="routes"
        :extras="extras"
        :steps-data="stepsData"
      ></SummaryStep>
    </div>
  </div>

  <div class="pb-3">
    <div class="d-flex justify-content-end gap-2">
      <button
        v-if="currentStep > 1"
        type="button"
        class="btn btn-secondary"
        @click="previousStep"
      >
        Back
      </button>
      <button
        v-if="currentStep === Object.keys(steps).length"
        ref="submitButtonRef"
        type="button"
        class="btn btn-primary"
        :disabled="!steps[currentStep].validate()"
        @click="requestComplete"
      >
        Complete
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        :disabled="!steps[currentStep].validate()"
        @click="nextStep"
      >
        Next
      </button>
    </div>
    <div class="text-end mt-2">
      <div ref="errorContainerRef"></div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, nextTick } from "vue";
import { debounce } from "lodash";
import PickingsStep from "@/components/admin/shipments/formWizard/pickingsStep.vue";
import PackingNoteStep from "@/components/admin/shipments/formWizard/packingNoteStep.vue";
import CourierStep from "@/components/admin/shipments/formWizard/courierStep.vue";
import SummaryStep from "@/components/admin/shipments/formWizard/summaryStep.vue";

defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
});

let steps = {
  1: {
    label: "Pickings",
    validate: function () {
      return !!(
        stepsData.pickings &&
        stepsData.pickings.customer &&
        stepsData.pickings.address &&
        typeof stepsData.pickings.pickings === "object" &&
        Object.keys(stepsData.pickings.pickings).length > 0
      );
    },
  },
  2: {
    label: "Packing Note",
    validate: function () {
      return true;
    },
  },
  3: {
    label: "Courier",
    validate: function () {
      return !!stepsData.courier?.courier;
    },
  },
  4: {
    label: "Summary",
    validate: function () {
      return true;
    },
  },
};

const currentStep = ref(1);
const stepsData = reactive({});
const submitButtonRef = ref(null);
const errorContainerRef = ref(null);
const form = document.getElementById("wizard_form");

const previousStep = function () {
  currentStep.value--;
};
const nextStep = function () {
  currentStep.value++;
};
const attemptGoToStep = function (requestedStep) {
  if (requestedStep === currentStep.value) {
    return false;
  }
  if (!isAllPreviousStepsValid(requestedStep)) {
    return false;
  }
  currentStep.value = Number(requestedStep);
};

const isAllPreviousStepsValid = function (stepNumber) {
  for (let i = 1; i < stepNumber; i++) {
    if (!steps[i].validate()) {
      return false;
    }
  }
  return true;
};

const setStepData = function (step, data) {
  stepsData[step] = data;
};

const requestComplete = function () {
  for (const [_, step] of Object.entries(steps)) {
    if (!step.validate()) {
      return false;
    }
  }

  completeWizard();
};

const completeWizard = debounce(ajaxSteps, 350);

async function ajaxSteps() {
  // Trigger submission via event
  let data = JSON.parse(JSON.stringify(stepsData));

  // Tidy up data before sending it to controller
  data.address = data.pickings.address.value;
  data.pickings = Object.keys(data.pickings.pickings);
  data.trackingNumber = data.courier.trackingNumber
    ? data.courier.trackingNumber
    : null;
  data.actualDeliveryPrice = data.courier.actualDeliveryPrice
    ? data.courier.actualDeliveryPrice
    : null;
  data.resendEmail = data.courier.resendEmail ? 1 : 0;
  data.courier = data.courier.courier.value;

  // Add submit button as a submitter for ajaxForm for UI purposes
  data.submitter = submitButtonRef.value;
  form.dispatchEvent(
    new CustomEvent("ajax-submit", {
      detail: data,
    })
  );
}

function getLastValidStepNumber() {
  for (let i = 1; i < Object.keys(steps).length; i++) {
    if (!steps[i].validate()) {
      return i;
    }
  }
  return Object.keys(steps).length;
}

nextTick(function () {
  currentStep.value = getLastValidStepNumber();
  form.ajaxForm({
    validationTarget: errorContainerRef.value,
  });
});
</script>
