<template>
  <div v-if="tabs.length > 1" class="block block-rounded block-content">
    <ul class="nav nav-pills nav-justified push">
      <li v-for="(tab, index) in tabs" :key="index" class="nav-item me-1">
        <a
          href="#"
          class="nav-link"
          :class="{
            active: index === activeTab,
          }"
          @click="activeTab = index"
          v-text="tab.name"
        ></a>
      </li>
    </ul>
  </div>
  <component
    :is="components[tabs[activeTab].component]"
    v-if="activeTab > -1"
    :api-route="tabs[activeTab].apiRoute"
  />
  <div v-else class="text-center">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { onMounted, ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import SalesDashboard from "@/components/admin/dashboard/SalesDashboard.vue";
import SalesManagerDashboard from "@/components/admin/dashboard/SalesManagerDashboard.vue";
import WarehouseDashboard from "@/components/admin/dashboard/WarehouseDashboard.vue";
import WorkshopDashboard from "@/components/admin/dashboard/WorkshopDashboard.vue";
import StockManagerDashboard from "@/components/admin/dashboard/StockManagerDashboard.vue";

const props = defineProps({
  apiRoute: {
    type: String,
    default: "",
  },
});

const components = {
  SalesDashboard,
  SalesManagerDashboard,
  WarehouseDashboard,
  WorkshopDashboard,
  StockManagerDashboard,
};
const tabs = ref([]);
const activeTab = ref(-1);

onMounted(
  debounce(async () => {
    let req = await useFetch(props.apiRoute);
    tabs.value = (await req.json())?.tabs ?? [];
    activeTab.value = 0;
  }, 500)
);
</script>
