<template>
  <div class="form-group mb-4">
    <label for="supplier_order_number" class="control-label">
      Supplier order number
    </label>
    <input
      id="supplier_order_number"
      v-model="supplierOrderNumber"
      class="form-control"
      maxlength="255"
      name="supplier_order_number"
      type="text"
    />
  </div>
  <div class="mb-4 form-group">
    <label for="total_net" class="control-label">Order total net</label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="fas fa-pound-sign"></i>
      </span>

      <input
        id="total_net"
        v-model.number="totalNet"
        class="form-control"
        type="text"
        name="total_net"
        inputmode="numeric"
        pattern="\d+(\.\d{1,2})?"
        placeholder="0"
        title="Price in pounds, e.g. 95.50"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="comments" class="control-label">Comments</label>
    <textarea
      id="comments"
      v-model="comments"
      class="form-control"
      name="comments"
      rows="5"
    ></textarea>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const emit = defineEmits(["stepValueChanged"]);
const props = defineProps({
  routes: {
    type: Object,
    default: null,
  },
  extras: {
    type: Object,
    default: null,
  },
  stepsData: {
    type: Object,
    default: null,
  },
});

const supplierOrderNumber = ref(
  props.extras.preselected.supplier_order_number
    ? props.extras.preselected.supplier_order_number
    : ""
);
const totalNet = ref(
  props.extras.preselected.total_net ? props.extras.preselected.total_net : ""
);
const comments = ref(
  props.extras.preselected.comments ? props.extras.preselected.comments : ""
);

watch(
  () => supplierOrderNumber.value,
  () => updateParentWithValues()
);
watch(
  () => totalNet.value,
  (newValue) => {
    let value = newValue ? parseFloat(newValue) : 0;
    if (isNaN(value)) {
      totalNet.value = 0;
      return;
    }
    updateParentWithValues();
  }
);
watch(
  () => comments.value,
  () => updateParentWithValues()
);

function updateParentWithValues() {
  emit("stepValueChanged", {
    supplier_order_number: supplierOrderNumber.value,
    total_net: totalNet.value,
    comments: comments.value,
  });
}
</script>
