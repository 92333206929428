<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all categories.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div class="d-flex gap-2 mt-2 justify-content-end">
        <TableFilter
          label="Categories"
          :options="[
            { value: 0, label: 'Regular' },
            { value: 1, label: 'Landing' },
          ]"
          @change="
            (value, label) => filterChanged('is-landing-category', value)
          "
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="categories-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="title"
        @set-order-by="setOrder"
      >
        Title
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_active"
        class="text-center"
        @set-order-by="setOrder"
      >
        Active
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_landing_category"
        class="text-center"
        @set-order-by="setOrder"
      >
        Landing Category
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="parts_count"
        class="text-center"
        @set-order-by="setOrder"
      >
        Parts
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="category in data" :key="category.id">
        <td>{{ category.title }}</td>
        <td class="text-center">
          <template v-if="category.is_active">
            <i class="fa fa-check text-success"></i>
          </template>
          <template v-else>
            <i class="fa fa-times"></i>
          </template>
        </td>
        <td class="text-center">
          <template v-if="category.is_landing_category">
            <i class="fa fa-check text-success"></i>
          </template>
          <template v-else>
            <i class="fa fa-times"></i>
          </template>
        </td>
        <td class="text-center">{{ category.parts_count }}</td>
        <td v-html="category.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("title");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
