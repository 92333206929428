<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <TableFilter
          label="Role"
          :options="roles"
          @change="(value, label) => filterChanged('role', value)"
        />
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all users.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="email"
        @set-order-by="setOrder"
      >
        Email
      </SortableTh>
      <th>Role</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Created at
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="user in data" :key="user.id">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td v-html="user.role"></td>
        <td>{{ user.created_at }}</td>
        <td v-html="user.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: null,
  },
  roles: {
    type: Array,
    default: () => [],
  },
});

const orderBy = ref("name");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
