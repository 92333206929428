<template>
  <label for="supplier" class="control-label required">Supplier</label>
  <formSelect
    v-model="supplier"
    name="supplier"
    :url="routes.suppliers"
    placeholder="Select supplier"
    clearable
    @update:model-value="updateParentWithValues"
  ></formSelect>
  <h5 class="center-line-text my-3">OR</h5>

  <div class="mb-4 form-group">
    <label for="name" class="control-label required">Name</label>
    <input
      id="name"
      ref="nameInput"
      class="form-control"
      required="required"
      name="name"
      type="text"
      @input="updateParentWithValues"
    />
  </div>

  <liveInput
    v-model="email"
    label="Email"
    input-name="email"
    :validation-url="routes.validate_email"
    :validation-regex="'.+@.+\\..+'"
    validation-failed-message="The email is invalid."
    :show-validation="showEmailValidation"
  ></liveInput>

  <div class="mb-4 form-group">
    <label for="telephone" class="control-label">Telephone</label>
    <input
      id="telephone"
      ref="telephoneInput"
      class="form-control"
      name="telephone"
      type="text"
      @input="updateParentWithValues"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import formSelect from "@/components/admin/utilities/formSelect.vue";
import liveInput from "@/components/admin/utilities/liveInput.vue";

const emit = defineEmits(["stepValueChanged"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});

let supplier = ref(props.extras.preselected.supplier);
let nameInput = ref(null);
let telephoneInput = ref(null);

const email = ref(null);
const showEmailValidation = ref(true);
watch(email, updateParentWithValues);

function updateParentWithValues() {
  if (supplier.value) {
    showEmailValidation.value = false;
    emit("stepValueChanged", {
      supplier: supplier.value.value,
      name: supplier.value.name,
      email: supplier.value.email,
      telephone: supplier.value.telephone,
    });
  } else {
    showEmailValidation.value = true;
    emit("stepValueChanged", {
      supplier: null,
      name: nameInput.value.value ? nameInput.value.value : null,
      email: email.value,
      telephone: telephoneInput.value.value ? telephoneInput.value.value : null,
    });
  }
}
</script>
