<template>
  <div class="form-group mb-4">
    <label for="comments" class="control-label">Customer Order Comments</label>
    <textarea
      id="comments"
      v-model="comments"
      class="form-control"
      name="comments"
      rows="5"
    ></textarea>
    <p class="help-block text-muted mb-0">
      These comments are visible to the customer.
    </p>
  </div>
  <div class="form-group mb-4">
    <label for="internal_comments" class="control-label">
      Internal Comments
    </label>
    <textarea
      id="internal_comments"
      v-model="internalComments"
      class="form-control"
      name="internal-comments"
      rows="5"
    ></textarea>
  </div>
  <div class="form-group">
    <label for="legacy_customer_order_id" class="control-label">
      Legacy Checkout ID
    </label>
    <input
      id="legacy_customer_id"
      v-model="legacyCustomerOrderId"
      class="form-control"
      type="text"
      name="legacy-customer-order-id"
      pattern="\d+"
      inputmode="numeric"
    />
    <p class="help-block text-muted mb-4">
      Can be found in legacy admin URL:
      https://www.nytractorparts.co.uk/admin/app/checkout-checkout/
      <b>legacy-checkout-id</b>
      /edit
    </p>
    <div v-if="isLegacyCustomerOrderIdInvalid" class="text-danger mt-2">
      Please enter a valid number
    </div>
    <div v-if="extras.hubspotEnabled" class="form-group">
      <label class="control-label mb-1">
        HubSpot
        <span v-if="hubspotOrderId.length > 0">Deal ID</span>
      </label>
      <br />
      <LinkDealModal
        v-if="hubspotOrderId.length === 0"
        :deals-route="routes.hubspot_deals"
        @link-deal="hubspotOrderId = $event.label"
      ></LinkDealModal>
      <input
        v-else
        v-model="hubspotOrderId"
        class="form-control"
        type="text"
        disabled
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import LinkDealModal from "@/components/admin/Modals/LinkDealModal.vue";

const emit = defineEmits(["step-value-changed"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
  isInitialLoadingFinished: Boolean,
});

let comments = ref(
  props.extras.preselected.comments ? props.extras.preselected.comments : ""
);
let internalComments = ref(
  props.extras.preselected.internalComments
    ? props.extras.preselected.internalComments
    : ""
);
let legacyCustomerOrderId = ref(
  props.extras.preselected.legacyCustomerOrderId
    ? props.extras.preselected.legacyCustomerOrderId
    : ""
);
const hubspotOrderId = ref(
  props.extras.preselected.hubspotOrderId
    ? props.extras.preselected.hubspotOrderId
    : ""
);

watch(
  () => comments.value,
  () => updateParentWithValues()
);
watch(
  () => internalComments.value,
  () => updateParentWithValues()
);
watch(
  () => legacyCustomerOrderId.value,
  () => {
    if (isLegacyCustomerOrderIdInvalid.value) {
      return;
    }
    updateParentWithValues();
  }
);

watch(
  () => hubspotOrderId.value,
  () => updateParentWithValues()
);

const isLegacyCustomerOrderIdInvalid = computed(() => {
  return (
    legacyCustomerOrderId.value &&
    (isNaN(legacyCustomerOrderId.value) ||
      isNaN(parseFloat(legacyCustomerOrderId.value)) ||
      (typeof legacyCustomerOrderId.value === "string" &&
        legacyCustomerOrderId.value.includes(".")))
  );
});

const updateParentWithValues = function () {
  let stepData = {
    comments: comments.value,
    internalComments: internalComments.value,
    legacyCustomerOrderId: legacyCustomerOrderId.value,
    hubspotOrderId: hubspotOrderId.value,
  };

  emit("step-value-changed", stepData);
};
updateParentWithValues();
</script>
