<template>
  <form ref="wizardForm" :action="routes.form_action">
    <input type="hidden" name="stage" :value="stage" />

    <div class="d-flex justify-content-between wizard-header position-relative">
      <div class="border-top spacer-line"></div>
      <template v-for="(step, i) in steps" :key="i">
        <div
          :role="
            i !== currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'
          "
          :class="{
            'd-flex flex-column align-items-center progress-number-wrapper': true,
            active: currentStep == i,
          }"
          @click="attemptGoToStep(i)"
        >
          <div class="progress-number-inner-wrapper">
            <span
              class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number"
            >
              <i
                v-if="
                  isAllPreviousStepsValid(i) &&
                  i !== currentStep &&
                  (i < currentStep || steps[i].validate())
                "
                class="fa fa-check text-flat"
              ></i>
              <span v-else>{{ i }}</span>
            </span>
          </div>
          <span class="fs-xs text-center">{{ step.label }}</span>
        </div>
      </template>
    </div>

    <div class="mt-3 mb-3">
      <div v-show="currentStep == 1">
        <CustomerStep
          :routes="routes"
          :steps-data="stepsData"
          :extras="extras"
          @step-value-changed="(data) => setStepData('customer', data)"
        />
      </div>
      <div v-show="currentStep == 2">
        <AddressStep
          :routes="routes"
          :steps-data="stepsData"
          :extras="extras"
          @step-value-changed="(data) => setStepData('addresses', data)"
        />
      </div>
      <div v-show="currentStep == 3">
        <ItemsStep
          :routes="routes"
          :steps-data="stepsData"
          :extras="extras"
          @step-value-changed="(data) => setStepData('items', data)"
        />
      </div>
      <div v-if="currentStep == 4">
        <SummaryStep
          :routes="routes"
          :extras="extras"
          :steps-data="stepsData"
        />
      </div>
    </div>

    <div class="pb-3">
      <div class="d-flex justify-content-end gap-2 position-relative">
        <AttachmentsButton
          class="position-absolute"
          style="left: 0"
          :attachments="extras.preselected.attachments"
        />
        <button
          v-if="currentStep > 1"
          type="button"
          class="btn btn-secondary"
          @click="previousStep"
        >
          Back
        </button>
        <div v-if="currentStep === Object.keys(steps).length" class="dropdown">
          <button
            id="complete_wizard"
            ref="submitButtonRef"
            type="button"
            class="btn btn-primary"
            :disabled="!steps[currentStep].validate()"
            @click="requestComplete"
          >
            Save
          </button>
        </div>
        <button
          v-else
          type="button"
          class="btn btn-primary"
          :disabled="!steps[currentStep].validate()"
          @click="nextStep"
        >
          Next
        </button>
      </div>
      <div class="text-end mt-2">
        <div ref="errorContainerRef"></div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { reactive, ref, nextTick } from "vue";
import { debounce } from "lodash";
import CustomerStep from "@/components/admin/quotes/pricing/customerStep.vue";
import ItemsStep from "@/components/admin/quotes/pricing/itemsStep.vue";
import SummaryStep from "@/components/admin/quotes/pricing/summaryStep.vue";
import AttachmentsButton from "@/components/admin/quotes/components/AttachmentsButton.vue";
import AddressStep from "@/components/admin/quotes/pricing/addressStep.vue";

defineProps({
  routes: {
    type: Object,
    required: true,
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stage: {
    type: String,
    required: true,
  },
});

let steps = {
  1: {
    label: "Customer",
    validate: function () {
      return stepsData.customer;
    },
  },
  2: {
    label: "Addresses",
    validate: function () {
      // Ensure billing & delivery addresses are present with at least 1 field set.
      if (!stepsData.addresses) {
        return false;
      }

      if (
        !stepsData.addresses.delivery ||
        Object.keys(stepsData.addresses.delivery).length <= 1
      ) {
        return false;
      }

      return true;
    },
  },
  3: {
    label: "Items",
    validate: function () {
      if (
        !stepsData.items ||
        !stepsData.items.items ||
        stepsData.items.items.length === 0
      ) {
        return false;
      }
      for (const item of stepsData.items.items) {
        if (!item.kit_quote_item && (!item.tax || item.tax.value == null)) {
          return false;
        }
      }
      return true;
    },
  },
  4: {
    label: "Summary",
    validate: function () {
      return true;
    },
  },
};
const currentStep = ref(1);
const stepsData = reactive({});
const submitButtonRef = ref(null);
const errorContainerRef = ref(null);
const wizardForm = ref(null);

const previousStep = function () {
  currentStep.value--;
};
const nextStep = function () {
  if (currentStep.value == 3) {
    if (
      stepsData.items.items.filter((item) => item.is_delivery).length == 0 ||
      stepsData.items.items.filter((item) => item.is_delivery)[0].unit_net == 0
    ) {
      confirmFreeDelivery();
      return;
    }
  }

  currentStep.value++;
};
const attemptGoToStep = function (requestedStep) {
  if (requestedStep === currentStep.value) {
    return false;
  }
  if (!isAllPreviousStepsValid(requestedStep)) {
    return false;
  }
  currentStep.value = Number(requestedStep);
};

const isAllPreviousStepsValid = function (stepNumber) {
  for (let i = 1; i < stepNumber; i++) {
    if (!steps[i].validate()) {
      return false;
    }
  }
  return true;
};

const setStepData = function (step, data) {
  stepsData[step] = data;
};

const requestComplete = function () {
  for (const [_, step] of Object.entries(steps)) {
    if (!step.validate()) {
      return false;
    }
  }

  completeWizard();
};

const completeWizard = debounce(ajaxSteps, 350);

async function ajaxSteps() {
  let data = JSON.parse(JSON.stringify(stepsData));
  data.items.items = data.items.items.filter((item) => !item.kit_quote_item);
  data.submitter = submitButtonRef.value;

  wizardForm.value.dispatchEvent(
    new CustomEvent("ajax-submit", {
      detail: data,
    })
  );
}

function getLastValidStepNumber() {
  for (let i = 1; i < Object.keys(steps).length; i++) {
    if (!steps[i].validate()) {
      return i;
    }
  }
  return Object.keys(steps).length;
}

function confirmFreeDelivery() {
  Swal.fire({
    title: "Free Delivery",
    text: "Would you like to offer free delivery on this order?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      currentStep.value++;
    }
  });
}

nextTick(function () {
  let lastValidStep = getLastValidStepNumber();
  // Pricing stage has everything autofilled with default values, so it would always go to last "Summary" step,
  // but it's very likely that salesman will want to change a price (it's a quote at the end of the day)
  // so on page load, attempt to load "Items" step.
  currentStep.value = lastValidStep > 3 ? 3 : lastValidStep;
  wizardForm.value.ajaxForm({
    validationTarget: errorContainerRef.value,
  });
});
</script>
