<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all pickings.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="picking-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <th>Items</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="order_id"
        @set-order-by="setOrder"
      >
        Order
      </SortableTh>
      <th>Customer</th>
      <th>Address</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="picker"
        @set-order-by="setOrder"
      >
        Picked By
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="picked_at"
        @set-order-by="setOrder"
      >
        Picked At
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="picking in data" :key="picking.id">
        <td v-html="picking.id"></td>
        <td>
          <ul
            v-if="Array.isArray(picking.items) && picking.items.length > 0"
            class="mb-0 ps-3"
          >
            <li
              v-for="(item, index) in picking.items.slice(0, itemsPreviewLimit)"
              :key="index"
              :class="{
                'mb-2':
                  index !==
                  picking.items.slice(0, itemsPreviewLimit).length - 1,
              }"
            >
              <span
                class="me-2 d-inline-flex flex-wrap align-items-center column-gap-1"
              >
                <PartDrawer
                  v-if="item.quick_view_url"
                  :source-url="item.quick_view_url"
                />
                <span>{{ item.part }}</span>
                <span v-if="item.sku" class="fst-italic">({{ item.sku }})</span>
              </span>
              <br />
              <span class="d-inline-block text-muted">
                Qty: {{ item.quantity }}
              </span>
            </li>
          </ul>
          <div
            v-if="
              Array.isArray(picking.items) &&
              picking.items.length > itemsPreviewLimit
            "
            role="button"
            class="ps-3 mt-1"
            @click="
              (event) =>
                event.target
                  .closest('tr')
                  .querySelector('.view-picking')
                  .click()
            "
          >
            and {{ picking.items.length - itemsPreviewLimit }} more...
          </div>
        </td>
        <td>
          <span v-if="picking.quick_view_url" class="me-1">
            <CustomerOrderDrawer
              :source-url="picking.quick_view_url"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="picking.order_id"></span>
        </td>
        <td>{{ picking.customer }}</td>
        <td v-html="picking.address"></td>
        <td>{{ picking.picker }}</td>
        <td>{{ picking.picked_at }}</td>
        <td v-html="picking.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div v-for="picking in data" :key="picking.id" class="list-group-item">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            Picking {{ picking.id }}
            <div class="fs-sm text-muted">
              Order
              <span v-if="picking.quick_view_url" class="me-1">
                <CustomerOrderDrawer
                  :source-url="picking.quick_view_url"
                ></CustomerOrderDrawer>
              </span>
              <span v-html="picking.order_id"></span>
            </div>
          </h5>
          <div>
            <small class="d-block text-end">{{ picking.picked_at }}</small>
            <small class="d-block text-end">{{ picking.picker }}</small>
          </div>
        </div>
        <div v-if="Array.isArray(picking.items) && picking.items.length > 0">
          <ul class="list-unstyled mt-2 mb-0">
            <li
              v-for="(item, index) in picking.items.slice(0, itemsPreviewLimit)"
              :key="index"
              :class="{
                'mb-2':
                  index !==
                  picking.items.slice(0, itemsPreviewLimit).length - 1,
              }"
            >
              <span class="d-inline-block me-2">
                {{ item.part }}
              </span>
              <div class="d-flex justify-content-between">
                <div v-if="item.sku" class="fst-italic">({{ item.sku }})</div>
                <div class="text-muted">Qty: {{ item.quantity }}</div>
              </div>
            </li>
          </ul>
          <div
            v-if="picking.items.length > itemsPreviewLimit"
            role="button"
            class="text-center mt-1"
            @click="
              (event) =>
                event.target
                  .closest('.list-group-item')
                  .querySelector('.view-picking')
                  .click()
            "
          >
            and {{ picking.items.length - itemsPreviewLimit }} more...
          </div>
        </div>
        <div class="mt-2" v-html="picking.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, watch, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import PartDrawer from "@/components/admin/parts/partDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

const emit = defineEmits(["addPick"]);

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: null,
  },
  mustMatchPickings: {
    type: Array,
    default: () => [],
  },
});

const itemsPreviewLimit = 3;
const orderBy = ref("id");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

watch(
  () => props.mustMatchPickings,
  () => filterChanged("must-match-pickings", props.mustMatchPickings),
  { immediate: true, deep: true }
);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (
    (typeof value === "string" && value.length === 0) ||
    customFilter[key] == value
  ) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}

document.addEventListener("click", function (e) {
  if (e.target.matches(".add-to-pack, .add-to-pack *")) {
    emit("addPick", e.target.closest(".add-to-pack").dataset.pickId);
  }
});
</script>
