<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all shipments.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="shipments-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="courier"
        @set-order-by="setOrder"
      >
        Courier
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="tracking_number"
        @set-order-by="setOrder"
      >
        Tracking number
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer"
        @set-order-by="setOrder"
      >
        Customer
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="address"
        @set-order-by="setOrder"
      >
        Address
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="shipped_at"
        @set-order-by="setOrder"
      >
        Shipped
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="shipment in data" :key="shipment.id">
        <td>{{ shipment.id }}</td>
        <td>{{ shipment.courier }}</td>
        <td v-html="shipment.tracking_number"></td>
        <td>{{ shipment.customer }}</td>
        <td>{{ shipment.address }}</td>
        <td>{{ shipment.shipped_at }}</td>
        <td v-html="shipment.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div v-for="shipment in data" :key="shipment.id" class="list-group-item">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Shipment {{ shipment.id }}</h5>
          <small>{{ shipment.shipped_at }}</small>
        </div>
        <p class="mb-1">{{ shipment.address }}</p>
        <small>{{ shipment.courier }}</small>
        <small class="d-block" v-html="shipment.tracking_number"></small>
        <div class="mt-2" v-html="shipment.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("id");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
