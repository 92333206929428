<template>
  <table
    v-if="lines.length > 0"
    class="table table-bordered table-striped table-vcenter"
  >
    <thead>
      <tr>
        <th>Location</th>
        <th class="text-center" width="250px">Qty</th>
        <th style="width: 2%"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(line, index) in lines" :key="line.id">
        <td>
          <formSelect
            v-model="line.location"
            :options="locations"
            label="title"
            placeholder="Select stock location"
          >
            <template #no-options>No locations available</template>
          </formSelect>
        </td>
        <td class="text-center">
          <input
            v-model="line.quantity"
            class="form-control text-center"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            placeholder="1"
          />
        </td>
        <td>
          <i
            class="fas fa-times text-danger"
            role="button"
            @click="removeLine(index)"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="w-100">
    <button
      class="d-block btn btn-secondary btn-sm mx-auto"
      type="button"
      @click="addLine"
    >
      <i class="fa fa-plus"></i>
      Add Stock
    </button>
  </div>

  <!-- Modal footer -->
  <Teleport v-if="lines.length > 0" :to="`#${footerId}`">
    <div class="block-content block-content-full text-end bg-body">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="save"
      >
        <i
          :class="{
            'fas fa-save': !isLoading,
            'fa fa-spin fa-spinner': isLoading,
          }"
        ></i>
        Save Stock
      </button>
    </div>
  </Teleport>
</template>

<script setup>
import { reactive, ref } from "vue";
import { debounce } from "lodash";
import formSelect from "@/components/admin/utilities/formSelect.vue";
import { useFetch } from "@/utilities/useFetch.js";
import { Eventbus } from "@/utilities/eventbus.js";

const props = defineProps({
  locations: {
    type: Array,
    default: () => [],
  },
  part: {
    type: [Number, String],
    default: null,
  },
  footerId: {
    type: String,
    required: true,
  },
  submitUrl: {
    type: String,
    required: true,
  },
});

const lines = reactive([]);
const isLoading = ref(false);

function addLine() {
  lines.push({
    id: Math.floor(Math.random() * Date.now()).toString(36),
    quantity: 1,
    location: null,
  });
}

function removeLine(removeAtIndex) {
  lines.splice(removeAtIndex, 1);
}

const save = debounce(submitStock, 350);

let fetchAbort, fetchAbortSignal;
async function submitStock() {
  isLoading.value = true;
  window.dispatchEvent(new CustomEvent("clearNotifications"));

  const success = function () {
    window.dispatchEvent(
      new CustomEvent("pushNotification", {
        detail: {
          icon: "success",
          text: "Stock saved successfully.",
        },
      })
    );
    Eventbus.emit("parts-list:refresh");
    after();
  };
  const error = function () {
    window.dispatchEvent(
      new CustomEvent("pushNotification", {
        detail: {
          icon: "danger",
          text: "Failed to save stock. We`ve been notified about the issue.",
        },
      })
    );
    after();
  };

  const after = function () {
    isLoading.value = false;
    let modal = bootstrap.Modal.getInstance(".stock-breakdown-modal");
    if (modal !== null) {
      modal.hide();
    }
  };

  const allocations = lines
    .map((line) => {
      return line.location && line.quantity != 0
        ? {
            location: line.location.id,
            quantity: line.quantity,
          }
        : null;
    })
    .filter((x) => x);

  // If there are no allocations to send to database (say all quantities are 0), then just return success
  if (allocations.length === 0) {
    success();
    return;
  }

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  try {
    const res = await useFetch(props.submitUrl, {
      method: "POST",
      body: {
        allocations: allocations,
      },
      signal: fetchAbortSignal,
    });
    if (res.ok) {
      success();
    } else {
      error();
    }
  } catch (_) {
    error();
  }
}
</script>
