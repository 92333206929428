<template>
  <div class="mx-0 mx-md-3 mx-lg-6">
    <div class="d-flex gap-1 mb-3">
      <b>Customer:</b>
      <div v-html="customer"></div>
    </div>
    <div class="d-flex flex-column flex-sm-row column-gap-3">
      <div
        class="block block-rounded block-bordered address-block d-flex flex-column"
      >
        <div class="block-header border-bottom flex-grow-1">
          <h3 class="block-title">Delivery Address</h3>
        </div>
        <div class="block-content d-flex flex-column flex-nowrap">
          <address v-html="deliveryAddress"></address>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="table-responsive-xl">
        <table class="table" style="width: 100%">
          <thead>
            <tr>
              <th width="35%">Description</th>
              <th class="text-center" width="10%">Qty</th>
              <th class="text-end" width="15%">Unit Price</th>
              <th class="text-end" width="20%">VAT</th>
              <th class="text-end" width="20%">Line Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <template v-if="item.kit_quote_item">
                <td colspan="999">
                  <div class="d-flex gap-3 ps-4 ms-1">
                    <img
                      class="img-thumbnail object-fit-cover thumbnail-xs"
                      :src="item.image ? item.image : partPlaceholderImage"
                    />
                    <div class="d-flex flex-column gap-1">
                      {{ item.name }}
                      <span v-if="item.sku">SKU: {{ item.sku }}</span>
                      <span>Qty Per Kit: {{ item.quantity }}</span>
                      <!-- <span class="text-muted">{{ item.parent }} component</span> -->
                    </div>
                  </div>
                </td>
              </template>
              <template v-else>
                <td>
                  <div class="d-flex gap-3">
                    <img
                      class="img-thumbnail object-fit-cover thumbnail-sm"
                      :src="item.image ? item.image : partPlaceholderImage"
                    />
                    <div class="d-flex flex-column gap-1">
                      {{ item.name }}
                      <span v-if="item.sku">SKU: {{ item.sku }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="text-end">
                  {{ currencySymbol }}{{ item.unit_net }}
                </td>
                <td class="text-end">{{ item.tax.label }}</td>
                <td class="text-end">
                  {{ currencySymbol }}{{ item.line_net }}
                </td>
              </template>
            </tr>
            <tr v-if="currency && currency.code != 'GBP'">
              <td class="border-0" colspan="2"></td>
              <td class="text-end border-0" colspan="2">Currency Rate</td>
              <td class="text-end border-0">{{ currency.label }}</td>
            </tr>
            <tr>
              <td class="border-0" colspan="2"></td>
              <td class="text-end border-0" colspan="2">Subtotal</td>
              <td class="text-end border-0">
                {{ currencySymbol }}{{ itemsTotal }}
              </td>
            </tr>
            <tr v-if="deliveryTotal !== null">
              <td class="border-0" colspan="2"></td>
              <td class="text-end border-0" colspan="2">Delivery Charge</td>
              <td class="text-end border-0">
                {{ currencySymbol }}{{ deliveryTotal }}
              </td>
            </tr>
            <tr>
              <td class="border-0" colspan="2"></td>
              <td class="text-end" style="border-bottom-width: 2px" colspan="2">
                Total VAT
              </td>
              <td class="text-end" style="border-bottom-width: 2px">
                {{ currencySymbol }}{{ VATTotal }}
              </td>
            </tr>
            <tr>
              <td class="border-0" colspan="2"></td>
              <td class="text-end border-0" colspan="2"><b>Total</b></td>
              <td class="text-end border-0">
                {{ currencySymbol }}{{ orderTotal }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="comments" class="mb-4">
      <b>Quote Comments</b>
      <p class="text-prewrap">{{ comments }}</p>
    </div>
    <div v-if="internalComments" class="mb-4">
      <b>Internal Comments</b>
      <p class="text-prewrap">{{ internalComments }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { priceFormat } from "@/utilities/priceFormat.js";
import { addressFormat } from "@/utilities/addressFormat.js";

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});
const partPlaceholderImage = window.placeholderImage;

const items = ref([]);
const comments = ref("");
const internalComments = ref("");
const customer = ref("");
const itemsTotal = ref(0);
const VATTotal = ref(0);
const orderTotal = ref(0);
const deliveryTotal = ref(null);
const currency = ref(null);
const deliveryAddress = ref("");

const currencySymbol = computed(() => {
  if (currency.value) {
    return currency.value.symbol;
  }
  return "£";
});

onStepsDataChange();
function onStepsDataChange() {
  if (props.stepsData.addresses) {
    if (props.stepsData.addresses.delivery) {
      deliveryAddress.value = addressFormat(props.stepsData.addresses.delivery);
    }
  }

  if (props.stepsData.items) {
    currency.value = props.stepsData.items.currency;

    let mappedItems = [];
    let total = 0;
    let taxTotal = 0;
    let delivery = 0;
    deliveryTotal.value = null;
    props.stepsData.items.items.every(function (item) {
      item = JSON.parse(JSON.stringify(item));
      if (typeof item.unit_net === "string") {
        item.unit_net = parseFloat(item.unit_net);
      }
      if (item.is_delivery) {
        delivery = item.unit_net;
        deliveryTotal.value = priceFormat(item.unit_net.toFixed(2));
        taxTotal += item.unit_net * (parseFloat(item.tax.rate) * 0.01);
        return true;
      }
      if (typeof item.part === "string") {
        item.name = item.part;
        item.image = window.placeholderImage;
        item.sku = null;
      } else {
        item.name = item.part.name;
        item.sku = item.part.sku;
        item.image = item.part.main_image;
      }
      if (!item.kit_quote_item) {
        let lineNet = 0;
        if (!item.is_used) {
          item.unit_net *= currency.value.rate;
          lineNet = item.unit_net * item.quantity * (1 - item.discount * 0.01);
        } else {
          lineNet = item.unit_net * item.quantity;
        }
        let taxRate = parseFloat(item.tax.rate) * 0.01;
        total += lineNet;
        taxTotal += lineNet * taxRate;

        item.line_net = priceFormat(lineNet.toFixed(2));
        item.unit_net = priceFormat(item.unit_net.toFixed(2));
      }
      mappedItems.push(item);
      return true;
    });

    itemsTotal.value = priceFormat(total.toFixed(2));
    VATTotal.value = priceFormat(taxTotal.toFixed(2));
    orderTotal.value = priceFormat((total + taxTotal + delivery).toFixed(2));
    items.value = mappedItems;
  }
  if (props.stepsData.items) {
    comments.value = props.stepsData.items.comments;
    internalComments.value = props.stepsData.items.internalComments;
  }
  if (props.stepsData.customer) {
    let customerPreview = props.stepsData.customer.name;
    if (props.stepsData.customer.email) {
      customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="mailto:${props.stepsData.customer.email}"><i class="far fa-envelope"></i> ${props.stepsData.customer.email}</a>`;
    }
    if (props.stepsData.customer.telephone) {
      customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="tel:${props.stepsData.customer.telephone}"><i class="fa fa-phone"></i> ${props.stepsData.customer.telephone}</a>`;
    }
    customer.value = customerPreview;
  }
}
</script>

<style lang="scss" scoped>
.totals {
  display: grid;
  justify-items: end;
  grid-template-columns: auto 175px;
  margin-left: 60%;
  gap: 1rem;

  .divider {
    grid-column: 1/3;
    height: 1px;
    width: 100%;
  }

  .total-value {
    padding-right: 0.75rem;
  }
}
</style>
