<template>
  <span>
    <span v-if="quickViewUrl" class="me-1">
      <partDrawer :source-url="quickViewUrl"></partDrawer>
    </span>
    <a v-if="item.url" :href="item.url" target="_blank">
      {{ partName }}
    </a>
    <template v-else>
      {{ partName }}
    </template>
    <template v-if="partSku">
      <br />
      <span class="text-muted">SKU: {{ partSku }}</span>
    </template>
    <slot />
  </span>
</template>

<script setup>
import { computed } from "vue";
import { watch } from "vue";
import partDrawer from "@/components/admin/parts/partDrawer.vue";

const props = defineProps({
  item: {
    type: Object,
    default: null,
  },
});

watch(
  () => props.item,
  () => {
    partName.value = null;
    partSku.value = null;
    quickViewUrl.value = null;
  }
);

const partSku = computed(() => {
  if (typeof props.item.part === "object") {
    return props.item.part.sku;
  }
  return null;
});

const quickViewUrl = computed(() => {
  if (typeof props.item.part === "object") {
    return props.item.part.quick_view_url;
  }
  return null;
});

const partName = computed(() => {
  if (typeof props.item.part === "object") {
    return props.item.part.name;
  }
  return props.item.part || props.item.name;
});
</script>
