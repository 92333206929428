<template>
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 class="block-title">Dismantled Parts</h3>
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="() => showModal()"
      >
        <i class="fa-solid fa-plus"></i>
        Add Part
      </button>
    </div>
    <div class="block-content pb-4">
      <dismantledPartsList :ajax-url="routes.parts"></dismantledPartsList>

      <!-- Would be nice to use <dialog> but hard to style it the same way bootstrap modal is styled and we use bootstrap modals in few places already... -->
      <div ref="modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-popout" role="document">
          <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
              <form ref="partForm" :action="formUrl">
                <input
                  v-if="formMethod === 'PUT'"
                  type="hidden"
                  name="_method"
                  value="PUT"
                />
                <div class="block-header block-header-default">
                  <h3 class="block-title">Add Part</h3>
                  <div class="block-options">
                    <button
                      type="button"
                      class="btn-block-option"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="fa fa-fw fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="block-content fs-sm">
                  <div class="mb-4 form-group">
                    <label class="control-label required">Part</label>
                    <partFormSelect
                      v-model="modalPart"
                      name="part"
                      only-existing
                    ></partFormSelect>
                    <p
                      v-if="modalPart && !modalPart.is_used"
                      class="help-block text-danger"
                    >
                      Selected part is marked as "New" in a system. Are you sure
                      this is the correct part?
                    </p>
                  </div>
                  <div class="mb-4 form-group">
                    <label class="control-label">For Order</label>
                    <customerOrderItemFormSelect
                      v-model="modalOrderItem"
                      name="customer_order_item"
                      :url="customerOrderItemUrl"
                    ></customerOrderItemFormSelect>
                  </div>
                  <div class="mb-4 form-group">
                    <label class="control-label required">Quantity</label>
                    <input
                      v-model="modalQuantity"
                      type="text"
                      name="quantity"
                      inputmode="numeric"
                      pattern="\d+"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-4 form-group">
                    <label class="control-label required">Location</label>
                    <formSelect
                      v-model="modalLocation"
                      :options="locations"
                      name="location"
                      placeholder="Select stock location"
                    >
                      <template #no-options>No locations available</template>
                    </formSelect>
                  </div>
                  <div class="mb-4 form-group">
                    <label class="control-label required">
                      Hours spent dismantling
                    </label>
                    <input
                      v-model="modalTimeSpent"
                      type="text"
                      name="time_spent"
                      inputmode="numeric"
                      pattern="\d+(\.\d{1,2})?"
                      title="Time spent in hours, e.g. 1.5"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-4 form-group">
                    <label class="control-label">Images</label>
                    <fileUploadContainer
                      ref="imageContainer"
                      :existing-files="modalImages"
                      :temp-upload-form-url="routes.image_temp_upload"
                      name-prefix="images"
                      multiple
                    ></fileUploadContainer>
                  </div>
                </div>
              </form>
              <div class="block-content block-content-full text-end bg-body">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="trySave"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, computed } from "vue";
import dismantledPartsList from "@/components/admin/tractors/dismantledPartsList.vue";
import partFormSelect from "@/components/admin/utilities/partFormSelect.vue";
import customerOrderItemFormSelect from "@/components/admin/customerOrders/components/customerOrderItemFormSelect.vue";
import formSelect from "@/components/admin/utilities/formSelect.vue";
import fileUploadContainer from "@/components/admin/utilities/fileUpload/fileUploadContainer.vue";
import { Eventbus } from "@/utilities/eventbus.js";

const props = defineProps({
  tractorParts: {
    type: Array,
    default: () => [],
  },
  locations: {
    type: Array,
    default: () => [],
  },
  routes: {
    type: Object,
    default: () => ({}),
  },
});

const modal = ref(null);
const imageContainer = ref(null);
const partForm = ref(null);

const modalPart = ref(null);
const modalOrderItem = ref(null);
const modalLocation = ref(null);
const modalTimeSpent = ref(null);
const modalQuantity = ref(1);
const modalImages = ref([]);

const tractorPartID = ref(null);

const formUrl = computed(() =>
  tractorPartID.value
    ? props.routes.update.replace("%tractorPart%", tractorPartID.value)
    : props.routes.store
);
const formMethod = computed(() => (tractorPartID.value ? "PUT" : "POST"));
const customerOrderItemUrl = computed(() => {
  const url = new URL(props.routes.customer_order_items);
  if (modalPart.value) {
    url.searchParams.append("part", modalPart.value.value);
  }
  if (tractorPartID.value) {
    url.searchParams.append("tractor-part", tractorPartID.value);
  }
  return url.toString();
});

Eventbus.on("edit-tractor-part", (data) => {
  showModal(data);
});

// Create bootstrap modal instance
let bsModal = null;
nextTick(() => {
  bsModal = new bootstrap.Modal(modal.value, { backdrop: "static" });
  modal.value.addEventListener("hidden.bs.modal", function () {
    resetData();
  });
  partForm.value.ajaxForm({
    success: (response) => {
      if (response.message) {
        window.Toast.fire({
          icon: "success",
          text: response.message,
        });
      }
      Eventbus.emit("dismantled-tractor-parts-list:refresh");
      bsModal.hide();
    },
  });
});
function showModal(data = null) {
  if (data) {
    tractorPartID.value = data.id;
    modalPart.value = data.part;
    modalOrderItem.value = data.customer_order_item;
    modalLocation.value = data.location;
    modalTimeSpent.value = data.time_spent;
    modalQuantity.value = data.quantity;
    modalImages.value = data.images;
  } else {
    resetData();
  }
  bsModal.show();
}

function resetData() {
  tractorPartID.value = null;
  modalPart.value = null;
  modalOrderItem.value = null;
  modalLocation.value = null;
  modalTimeSpent.value = null;
  modalQuantity.value = 1;
  modalImages.value = [];
}

function trySave(e) {
  partForm.value.dispatchEvent(
    new CustomEvent("ajax-submit", {
      detail: {
        submitter: e.target,
      },
    })
  );
}
</script>
