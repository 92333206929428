import { useFetch } from "@/utilities/useFetch.js";

Element.prototype.uploadFile = function (customOptions = {}) {
  let options = {
    ...{
      before: () => {},
      after: () => {},
      success: (responseData) => {
        if (responseData.redirect) {
          window.location = responseData.redirect;
        }
      },
      error: () => {},
      onUploadProgress: () => {},
      allowedFormat: null,
      tempUploadFormUrl: null,
      dynamic: false, // Dynamic does not require actual inputs and leaves handling through callbacks back to a caller
      file: null, // Only used with dynamic: true
      isDragUpload: false,
    },
    ...customOptions,
  };

  let wrapper = this;
  let fileInput = options.dynamic
    ? null
    : wrapper.querySelector('input[type="file"]');
  let signatureInput = options.dynamic
    ? null
    : wrapper.querySelector("input.signature");

  if (!options.dynamic) {
    if (fileInput === null) {
      throw new Error("File input not found.");
    }
    if (signatureInput === null) {
      throw new Error("Signature input not found.");
    }
  }

  if (
    options.tempUploadFormUrl === null ||
    options.tempUploadFormUrl === undefined
  ) {
    options.tempUploadFormUrl = wrapper.dataset.tempUploadFormUrl;
  }

  if (
    !options.dynamic &&
    (options.tempUploadFormUrl === null ||
      options.tempUploadFormUrl === undefined)
  ) {
    options.tempUploadFormUrl = fileInput.dataset.tempUploadFormUrl;
  }

  if (
    options.tempUploadFormUrl === null ||
    options.tempUploadFormUrl === undefined
  ) {
    throw new Error(
      `Temporary upload form url for ${options.dynamic ? "dynamic upload" : fileInput.id} is not found.`
    );
  }

  if (fileInput) {
    fileInput.addEventListener("change", function () {
      uploadFile();
    });
  }

  const _before = function () {
    wrapper.classList.add("upload-in-progress");
    options.before();
    options.onUploadProgress(0);
  };
  const _after = function () {
    wrapper.classList.remove("upload-in-progress");
    options.onUploadProgress(100);
    options.after();
  };

  if (options.dynamic) {
    uploadFile();
  }

  async function uploadFile() {
    let uploadDetails = false;
    let file = options.file;
    if (
      file === null &&
      fileInput &&
      fileInput.files &&
      fileInput.files.length > 0
    ) {
      file = fileInput.files[0];
    }
    if (file === null) {
      throw new Error("No file to upload found.");
    }
    const filename =
      Math.random().toString(36).substr(2) + "." + file.name.split(".").pop();
    _before();

    // Would be cool to have progress from fetchAPI but couldn't find a way to do that.
    const getDirectUpload = async () => {
      try {
        const response = await useFetch(options.tempUploadFormUrl, {
          method: "POST",
          body: {
            name: filename,
            original_name: file.name,
            size: file.size,
          },
        });

        const responseData = await response.json();

        const { status } = response;

        if (status === 200) {
          return responseData;
        }

        throw { status: status, response: responseData };
      } catch (error) {
        let status = error.status ? error.status : "Unknown";
        if (error.response && error.response.errors) {
          status =
            error.response.errors[Object.keys(error.response.errors)[0]].join(
              ", "
            );
        }
        Swal.fire({
          target: document.getElementById("page-container"),
          icon: "error",
          title: "Could not upload file " + file.name,
          text: "System error: " + status,
        });
        return false;
      }
    };

    uploadDetails = await getDirectUpload();
    if (uploadDetails === false) {
      options.error();
      _after();
      return;
    }
    options.onUploadProgress(options.allowedFormat ? 33 : 50);

    const runDirectUpload = async () => {
      try {
        let data = new FormData();
        for (let key in uploadDetails.inputs) {
          data.append(key, uploadDetails.inputs[key]);
        }
        data.append("key", uploadDetails.destination);
        data.append("file", file);

        const response = await useFetch(uploadDetails.action, {
          method: "POST",
          body: data,
        });

        const { status } = response;
        if (status >= 200 && status < 300) {
          return true;
        }

        throw { status: status };
      } catch (error) {
        let status = error.status ? error.status : "Unknown";
        Swal.fire({
          target: document.getElementById("page-container"),
          icon: "error",
          title: "Could not upload file " + file.name,
          text:
            "System error: " +
            status +
            (options.isDragUpload ? ". Try click upload instead." : ""),
        });
        return false;
      }
    };
    let success = await runDirectUpload();

    if (!success) {
      options.error();
      _after();
      return;
    }

    if (options.allowedFormat) {
      options.onUploadProgress(66);
      const validateUpload = async () => {
        try {
          let data = new FormData();
          data.append("signature", uploadDetails.internalSignature);
          data.append("source", options.source);
          data.append("allowed_format", options.allowedFormat);

          const response = await useFetch(window.fileValidationURL, {
            method: "POST",
            body: data,
          });

          const { status } = response;
          if (status >= 200 && status < 300) {
            return true;
          }

          throw { status: status };
        } catch (error) {
          let status =
            error.status && error.status === 422
              ? "Invalid file format"
              : "Unknown";
          Swal.fire({
            target: document.getElementById("page-container"),
            icon: "error",
            title: "Could not upload file " + file.name,
            text: "System error: " + status,
          });
          return false;
        }
      };

      success = await validateUpload();
    }

    if (success) {
      if (signatureInput) {
        signatureInput.value = uploadDetails.internalSignature;
      }
      options.success({ signature: uploadDetails.internalSignature });
    } else {
      options.error();
    }

    _after();
  }
};
