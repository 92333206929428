<template>
  <tr>
    <td>
      <input
        class="form-control"
        type="text"
        :name="`reasons[${id}]`"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </td>
    <td class="text-center">
      {{ count }}
    </td>
    <td>
      <i
        v-if="count == null || count == 0"
        class="fas fa-times text-danger"
        role="button"
        @click="emit('removeLine')"
      ></i>
      <i v-else class="fas fa-times text-muted"></i>
    </td>
  </tr>
</template>

<script setup>
const emit = defineEmits(["removeLine", "update:modelValue"]);

defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  count: {
    type: Number,
    default: 0,
  },
  id: {
    type: [String, Number],
    default: "",
  },
});
</script>
