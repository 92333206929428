<template>
  <div class="content-heading p-0 mb-2">Drop Shipping</div>
  <FormSelect
    v-model="_dropShippingOrder"
    name="drop_shipping_order"
    :url="dropShippingOrderLookupUrl"
    placeholder="Select customer order"
    helper-text="Select customer order if you are adding a drop ship supplier order. Search for <kbd>#</kbd> to look for best matches."
    clearable
  >
    <template #option="{ option }">
      {{ option.label }}
      <template
        v-if="option.items.filter((i) => i.is_part_drop_shipped).length > 0"
      >
        ({{
          option.items.filter((i) => i.is_part_drop_shipped).length +
          (option.items.filter((i) => i.is_part_drop_shipped).length === 1
            ? " item"
            : " items")
        }}
        drop shipped)
      </template>
    </template>
  </FormSelect>
  <div v-if="_dropShippingOrder" class="table-responsive-xl mt-3">
    <table class="table table-bordered table-striped table-vcenter mb-0">
      <thead>
        <tr>
          <th>Part</th>
          <th class="text-center" width="150">Qty</th>
          <th style="width: 2%"></th>
        </tr>
      </thead>
      <tbody v-if="availableDropShipLines.length > 0">
        <tr>
          <td class="text-uppercase text-center fw-bold" colspan="3">
            Available Items
          </td>
        </tr>
        <DropShipItem
          v-for="item in availableDropShipLines"
          :key="item.id"
          :item="item"
          @add-to-drop-ship="() => addToDropShip(item)"
        />
      </tbody>
      <tbody>
        <tr>
          <td class="text-uppercase text-center fw-bold" colspan="3">
            Drop Shipped Items
          </td>
        </tr>
        <tr v-if="selectedDropShipLines.length === 0">
          <td class="text-center" colspan="3">No items selected</td>
        </tr>
        <DropShipItem
          v-for="item in selectedDropShipLines"
          v-else
          :key="item.id"
          v-model:quantity="item.quantity"
          :item="item"
          is-included-in-drop-ship
          @remove-from-drop-ship="() => removeFromDropShip(item)"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import DropShipItem from "@/components/admin/supplierOrders/formWizard/dropShip/item.vue";

const props = defineProps({
  dropShippingOrder: {
    type: Object,
    default: null,
  },
  dropShippingOrderLookupUrl: {
    type: String,
    required: true,
  },
});

const model = defineModel({ type: Object, default: {} });

const _dropShippingOrder = ref(props.dropShippingOrder);
const selectedDropShipLines = ref([]);
const availableDropShipLines = ref([]);

watch(
  () => _dropShippingOrder.value,
  (newValue) => dropShippingOrderChange(newValue)
);

watch([selectedDropShipLines, availableDropShipLines], () => {
  model.value.items = selectedDropShipLines.value;
});

function addToDropShip(item) {
  // It feels more natural to show the selected items in order it was added by user
  selectedDropShipLines.value.push(item);
  // Remove from available items
  availableDropShipLines.value = availableDropShipLines.value.filter(
    (line) => line.id !== item.id
  );
}

function removeFromDropShip(item) {
  // It feels more natural to keep the selected items in order it was loaded in
  availableDropShipLines.value.push(item);
  availableDropShipLines.value.sort((a, b) => a.id - b.id);
  // Remove from selected items
  selectedDropShipLines.value = selectedDropShipLines.value.filter(
    (line) => line.id !== item.id
  );
}

function dropShippingOrderChange(newDropShippingOrder) {
  model.value.dropShipCustomerOrder = newDropShippingOrder;

  selectedDropShipLines.value = [];
  availableDropShipLines.value = [];
  if (newDropShippingOrder && newDropShippingOrder.items.length > 0) {
    availableDropShipLines.value = newDropShippingOrder.items
      .map((item) => {
        return {
          ...item,
          original_quantity: item.quantity,
          quantity: item.quantity - (item.fulfilled_quantity ?? 0),
        };
      })
      .filter((item) => {
        return item.quantity > 0 && !item.is_kit;
      });
  }
}
</script>
