<template>
  <div v-if="tabData !== null">
    <div class="row items-push">
      <TileDetailed
        title="Outstanding Picking Orders"
        :value="tabData.picking.outstanding_count"
        button-text="View Picking"
        :button-link="tabData.picking.route"
        icon="fa fa-box"
        width="col-sm-6 col-xxl-4"
      />
      <TileDetailed
        title="Outstanding Packing Orders"
        :value="tabData.packing.outstanding_count"
        button-text="View Packing"
        :button-link="tabData.packing.route"
        icon="fa fa-truck-fast"
        width="col-sm-6 col-xxl-4"
      />
      <TileDetailed
        title="Outstanding Goods Orders"
        :value="tabData.goods_orders.outstanding_count"
        button-text="View Goods Orders"
        :button-link="tabData.goods_orders.route"
        icon="fa fa-boxes-stacked"
        width="col-sm-6 col-xxl-4"
      />
    </div>
    <div class="block block-rounded">
      <ul class="nav nav-tabs nav-tabs-block" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'picking',
            }"
            aria-selected="true"
            @click="currentTab = 'picking'"
          >
            Requires Picking
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{
              active: currentTab === 'packing',
            }"
            aria-selected="true"
            @click="currentTab = 'packing'"
          >
            Requires Packing
          </button>
        </li>
      </ul>
      <div class="block-content block-content-full">
        <PickingList
          v-if="currentTab === 'picking'"
          :ajax-url="tabData.picking.list_route"
        ></PickingList>
        <PackingTable
          v-if="currentTab === 'packing'"
          :ajax-url="tabData.packing.list_route"
        ></PackingTable>
      </div>
    </div>
  </div>
  <div v-else class="block-content text-center">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { onMounted, ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import TileDetailed from "@/components/admin/utilities/oneui/tileDetailed.vue";
import PickingList from "@/components/admin/dashboard/components/pickingTable.vue";
import PackingTable from "@/components/admin/dashboard/components/packingTable.vue";

const props = defineProps({
  apiRoute: {
    type: String,
    default: "",
  },
});

const tabData = ref(null);
const currentTab = ref("picking");

onMounted(
  debounce(async () => {
    let req = await useFetch(props.apiRoute);
    tabData.value = await req.json();
  }, 500)
);
</script>
