<template>
  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="part_name"
        @set-order-by="setOrder"
      >
        Part
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="quantity"
        class="text-center"
        @set-order-by="setOrder"
      >
        Qty
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer_order_id"
        @set-order-by="setOrder"
      >
        Order
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="placed_at"
        class="text-center"
        @set-order-by="setOrder"
      >
        Placed
      </SortableTh>
    </template>
    <template #rows="{ data }">
      <tr v-for="row in data" :key="row.id">
        <td>
          <div>
            <partDrawer
              v-if="row.part.quick_view_url"
              :source-url="row.part.quick_view_url"
            ></partDrawer>
            {{ row.part.name }}
          </div>
          <div v-if="row.part.sku" class="text-muted">
            SKU: {{ row.part.sku }}
          </div>
        </td>
        <td class="text-center">{{ row.quantity }}</td>
        <td>
          <span v-if="row.customer_order_quick_link" class="me-1">
            <CustomerOrderDrawer
              :source-url="row.customer_order_quick_link"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="row.customer_order_link"></span>
        </td>
        <td>{{ row.placed_at }}</td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import partDrawer from "@/components/admin/parts/partDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("placed_at");
const orderAsc = ref(false);
const limit = ref(50);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
