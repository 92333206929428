<template>
  <div
    class="address-block d-flex flex-column large-address m-0"
    :class="{
      'no-container': noContainer,
      'block block-rounded block-bordered': !noContainer,
    }"
  >
    <div v-if="label" class="block-header border-bottom">
      <h3 class="block-title">{{ label }}</h3>
    </div>
    <div
      v-if="showAddButton"
      class="flex-fill d-flex justify-content-center align-items-center m-0 fs-1 text-default-lighter"
      role="button"
      @click="addClick"
    >
      <i class="fa fa-plus pe-2"></i>
      Add
    </div>
    <div
      v-else
      class="d-flex flex-grow-1"
      :class="{
        'block-content': !noContainer,
      }"
    >
      <div class="d-flex flex-column flex-nowrap flex-grow-1">
        <div v-show="showPreview">
          <div
            v-if="addressModel.reference"
            class="text-muted fst-italic mb-2"
            v-html="addressModel.reference"
          ></div>
          <address v-html="addressPreview"></address>
        </div>
        <div v-show="!showPreview">
          <div v-if="!manualAddressEntry" class="mb-3">
            <postcodeSelect
              @change="postcodeApiAddressRetrieved"
            ></postcodeSelect>
            <a href="javascript:;" @click="manualAddressEntry = true">
              Enter manually...
            </a>
          </div>
          <div v-show="manualAddressEntry">
            <div v-if="searchUrl" class="mb-3">
              <formSelect
                v-model="searchResultAddress"
                :url="searchUrl"
                placeholder="Search all addresses.."
              ></formSelect>
            </div>
            <div class="mb-3 vstack gap-2">
              <input
                v-model="addressModel.line_1"
                class="form-control"
                :name="`${namePrefix}[line_1]`"
                placeholder="Line 1"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.line_2"
                class="form-control"
                :name="`${namePrefix}[line_2]`"
                placeholder="Line 2"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.line_3"
                class="form-control"
                :name="`${namePrefix}[line_3]`"
                placeholder="Line 3"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.line_4"
                class="form-control"
                :name="`${namePrefix}[line_4]`"
                placeholder="Line 4"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.town"
                class="form-control"
                :name="`${namePrefix}[town]`"
                placeholder="Town"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.county"
                class="form-control"
                :name="`${namePrefix}[county]`"
                placeholder="County"
                data-error-target=".address-block"
              />
              <input
                v-model="addressModel.postcode"
                class="form-control"
                :name="`${namePrefix}[postcode]`"
                placeholder="Postcode"
                data-error-target=".address-block"
              />
              <formSelect
                v-model="addressModel.country"
                :name="`${namePrefix}[country_code]`"
                :url="countryListURL"
                error-target=".address-block"
                placeholder="Search country.."
              ></formSelect>
              <a
                href="javascript:;"
                style="margin-top: -0.25rem"
                @click="manualAddressEntry = false"
              >
                Search address...
              </a>

              <div
                v-if="showReference || showDefaultToggle"
                class="center-line-text text-muted fs-xs"
              >
                Additional info
              </div>

              <input
                v-if="showReference"
                v-model="addressModel.reference"
                class="form-control mt-2"
                :name="`${namePrefix}[reference]`"
                placeholder="Reference"
              />
              <input
                v-else
                v-model="addressModel.reference"
                type="hidden"
                :name="`${namePrefix}[reference]`"
              />

              <div
                v-if="showDefaultToggle"
                class="d-flex align-items-center gap-2"
              >
                <label for="is_default" class="text-muted">
                  Default Delivery Address
                </label>
                <div class="form-check form-switch mb-0">
                  <input type="hidden" value="0" name="is_default" />
                  <input
                    id="is_default"
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    name="is_default"
                    :checked="addressModel.is_default"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="allowEdit" class="mb-3 d-flex flex-wrap gap-2 mt-auto">
          <button
            v-if="showPreview"
            class="btn btn-sm btn-outline-primary"
            type="button"
            @click="toggleView(false)"
          >
            Edit
          </button>
          <template v-else>
            <button
              class="btn btn-sm btn-outline-primary"
              type="button"
              @click="toggleView(true)"
            >
              Preview
            </button>
            <button
              class="btn btn-sm btn-outline-secondary"
              type="button"
              @click="cancelEdit"
            >
              Cancel
            </button>
          </template>
          <template v-if="allowDelete">
            <button
              class="btn btn-sm btn-outline-danger"
              type="button"
              @click="deleteElement"
            >
              Delete
            </button>
          </template>
          <button
            v-if="showCopyButton"
            class="btn btn-sm btn-outline-secondary"
            type="button"
            @click="copyAddress"
          >
            {{ copyButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import { debounce, throttle } from "lodash";
import { useFetch } from "@/utilities/useFetch.js";
import formSelect from "@/components/admin/utilities/formSelect.vue";
import postcodeSelect from "@/components/admin/addresses/postcodeSelect.vue";
import { addressFormat } from "@/utilities/addressFormat.js";
import { Eventbus } from "@/utilities/eventbus.js";

const emit = defineEmits([
  "elementDeleted",
  "addressChanged",
  "defaultClicked",
]);
const props = defineProps({
  address: {
    type: Object,
    default: () => ({}),
  },
  label: {
    type: String,
    default: "",
  },
  ajaxUrl: {
    type: String,
    default: "",
  },
  searchUrl: {
    type: String,
    default: "",
  },
  namePrefix: {
    type: String,
    default: "address",
  },
  noContainer: Boolean,
  loadInEditView: Boolean,
  allowEdit: Boolean,
  allowDelete: Boolean,
  showDefaultToggle: Boolean,
  showReference: Boolean,
  emitOnCopy: {
    type: String,
    default: "",
  },
  listenOnCopy: {
    type: String,
    default: "",
  },
  copyButtonText: {
    type: String,
    default: "Copy",
  },
  forceManualEntry: Boolean,
});

let originalAddress = { ...props.address };
const countryListURL = window.countryListURL;

const searchResultAddress = ref(null);
const addressModel = reactive({
  ...{
    reference: null,
    is_default: null,
    line_1: null,
    line_2: null,
    line_3: null,
    line_4: null,
    town: null,
    county: null,
    postcode: null,
    country: null,
  },
  ...props.address,
});

const showCopyButton = computed(() => props.emitOnCopy !== undefined);
const manualAddressEntry = ref(props.forceManualEntry);
const showAddButton = ref(!props.loadInEditView);

watch(
  () => props.address,
  (newValue) => {
    setBaseAddress(newValue);
    cancelEdit();
  }
);
watch(
  () => props.forceManualEntry,
  (newValue) => {
    manualAddressEntry.value = newValue;
  }
);
watch(
  () => ({ ...addressModel }),
  (newValue) => {
    if (newValue.line_1) {
      manualAddressEntry.value = true;
      showAddButton.value = false;
    }
  },
  {
    immediate: true,
  }
);
watch(
  () => ({ ...addressModel }),
  throttle((newValue, oldValue) => {
    if (newValue?.country?.value !== oldValue.country_code) {
      addressModel.country_code = newValue?.country?.value;
    }
    emit("addressChanged", addressModel);
  }, 500)
);
watch(
  () => props.address.is_default,
  () => (addressModel.is_default = props.address.is_default)
);
watch(
  () => searchResultAddress.value,
  () => {
    setBaseAddress({ ...searchResultAddress.value });
    cancelEdit(false);
  }
);

const addressPreview = computed(() => addressFormat(addressModel));
const showPreview = ref(!props.loadInEditView);

if (props.listenOnCopy) {
  Eventbus.on(props.listenOnCopy, function (data) {
    delete data.reference;
    delete data.is_default;
    setBaseAddress(data);
    cancelEdit(true);
  });
}

const addClick = function () {
  showAddButton.value = false;
  showPreview.value = false;
};
const toggleView = function (preview = false) {
  showPreview.value = preview;
};

const cancelEdit = function (toggleToPreview = true) {
  // Reset old fields
  for (const [key] of Object.entries(addressModel)) {
    addressModel[key] = null;
  }
  // Fill with original values
  for (const [key, value] of Object.entries(originalAddress)) {
    addressModel[key] = value;
  }
  toggleView(toggleToPreview);
  emit("addressChanged", addressModel);
};

const deleteElement = function () {
  showAddButton.value = true;
  emit("elementDeleted");
  emit("addressChanged", addressModel);
};

const setBaseAddress = function (addressData) {
  originalAddress = {};
  if (!addressData) {
    return;
  }
  for (const [key, value] of Object.entries(addressData)) {
    originalAddress[key] = value;
  }
};

const copyAddress = function () {
  Eventbus.emit(props.emitOnCopy, { ...addressModel });
};

const fetchAddress = debounce(ajaxAddress, 350);

let fetchAbort, fetchAbortSignal;
async function ajaxAddress(loading) {
  if (!props.ajaxUrl) {
    return;
  }
  loading = loading || function () {};

  loading(true);

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  try {
    const res = await useFetch(props.ajaxUrl, { signal: fetchAbortSignal });
    // There could be 204 status code if address is not found (say customer does not have default delivery address).
    if (res.status === 200) {
      let response = await res.json();
      setBaseAddress(response.data);
    }
    cancelEdit();
  } catch (_) {
    _;
  } finally {
    loading(false);
  }
}

const postcodeApiAddressRetrieved = function (addressData) {
  for (const key in addressData) {
    if (Object.prototype.hasOwnProperty.call(addressModel, key)) {
      addressModel[key] = addressData[key];
    }
    if (
      key === "country" &&
      addressData[key].value &&
      Object.prototype.hasOwnProperty.call(addressModel, "country_code")
    ) {
      addressModel.country_code = addressData[key].value;
    }
  }

  manualAddressEntry.value = true;
};

defineExpose({
  toggleView,
  fetchAddress,
});
</script>
