<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all addresses.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="addresses-list:refresh"
  >
    <template #headings>
      <th :currentOrderBy="orderBy">Address</th>
      <th v-if="showActions">
        <a
          class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-primary ajax-modal"
          :href="editUrl"
          data-bs-class="address-modal"
          style="letter-spacing: normal; text-transform: none"
        >
          <i class="fa fa-fw fa-plus"></i>
          Add
        </a>
      </th>
      <th v-if="slots.customActions"></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="address in data" :key="address.id">
        <td v-html="addressFormat(address, true, ', ')"></td>
        <td v-if="showActions" v-html="address.actions"></td>
        <td v-if="slots.customActions">
          <slot name="customActions" :address="address"></slot>
        </td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, useSlots } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import { addressFormat } from "@/utilities/addressFormat.js";

const slots = useSlots();

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  editUrl: {
    type: String,
    default: "",
  },
  showActions: {
    type: Boolean,
    default: true,
  },
});

const orderBy = ref(null);
const orderAsc = ref(true);
const limit = ref(10);
const searchTerm = ref(null);
</script>
