<template>
  <form ref="wizardForm" :action="routes.form_action">
    <input type="hidden" name="stage" :value="stage" />

    <div class="d-flex justify-content-between wizard-header position-relative">
      <div class="border-top spacer-line"></div>
      <template v-for="(step, i) in steps" :key="i">
        <div
          :role="
            i !== currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'
          "
          :class="{
            'd-flex flex-column align-items-center progress-number-wrapper': true,
            active: currentStep == i,
          }"
          @click="attemptGoToStep(i)"
        >
          <div class="progress-number-inner-wrapper">
            <span
              class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number"
            >
              <i
                v-if="
                  isAllPreviousStepsValid(i) &&
                  i !== currentStep &&
                  (i < currentStep || steps[i].validate())
                "
                class="fa fa-check text-flat"
              ></i>
              <span v-else>{{ i }}</span>
            </span>
          </div>
          <span class="fs-xs text-center">{{ step.label }}</span>
        </div>
      </template>
    </div>

    <div class="mt-4 mb-3">
      <div v-show="currentStep == 1">
        <CustomerStep
          :routes="routes"
          :steps-data="stepsData"
          :extras="extras"
          @step-value-changed="(data) => setStepData('customer', data)"
        ></CustomerStep>
      </div>
      <div v-show="currentStep == 2">
        <ItemsStep
          :routes="routes"
          :steps-data="stepsData"
          :extras="extras"
          @step-value-changed="(data) => setStepData('items', data)"
        ></ItemsStep>
      </div>
      <div v-if="currentStep == 3">
        <SummaryStep
          :routes="routes"
          :extras="extras"
          :steps-data="stepsData"
        ></SummaryStep>
      </div>
    </div>

    <div class="pb-3">
      <div class="d-flex justify-content-end gap-2 position-relative">
        <AttachmentsButton
          class="position-absolute"
          style="left: 0"
          :attachments="extras.preselected.attachments"
        />
        <button
          v-if="currentStep > 1"
          type="button"
          class="btn btn-secondary"
          @click="previousStep"
        >
          Back
        </button>
        <button
          v-if="currentStep === Object.keys(steps).length"
          ref="submitButtonRef"
          type="button"
          class="btn btn-primary"
          :disabled="!steps[currentStep].validate()"
          @click="requestComplete"
        >
          Complete
        </button>
        <button
          v-else
          type="button"
          class="btn btn-primary"
          :disabled="!steps[currentStep].validate()"
          @click="nextStep"
        >
          Next
        </button>
      </div>
      <div class="text-end mt-2">
        <div ref="errorContainerRef"></div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { reactive, ref, nextTick } from "vue";
import { debounce } from "lodash";
import CustomerStep from "@/components/admin/quotes/draft/customerStep.vue";
import ItemsStep from "@/components/admin/quotes/draft/itemsStep.vue";
import SummaryStep from "@/components/admin/quotes/draft/summaryStep.vue";
import AttachmentsButton from "@/components/admin/quotes/components/AttachmentsButton.vue";

defineProps({
  stage: {
    type: String,
    required: true,
  },
  routes: {
    type: Object,
    required: true,
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
});

let steps = {
  1: {
    label: "Customer",
    validate: function () {
      if (!stepsData.customer) {
        return false;
      }
      if (stepsData.customer.customer) {
        return true;
      }

      return !!(stepsData.customer.name && stepsData.customer.email);
    },
  },
  2: {
    label: "Items",
    validate: function () {
      const invalidItems = stepsData.items.items.filter(
        (i) => i.newPartSKU === ""
      );
      if (
        !stepsData.items ||
        !stepsData.items.items ||
        stepsData.items.items.length == 0 ||
        invalidItems.length > 0
      ) {
        return false;
      }
      return true;
    },
  },
  3: {
    label: "Summary",
    validate: function () {
      return true;
    },
  },
};
const currentStep = ref(1);
const stepsData = reactive({});
const submitButtonRef = ref(null);
const errorContainerRef = ref(null);
const wizardForm = ref(null);

function previousStep() {
  currentStep.value--;
}

function nextStep() {
  currentStep.value++;
}

function attemptGoToStep(requestedStep) {
  if (requestedStep === currentStep.value) {
    return false;
  }
  if (!isAllPreviousStepsValid(requestedStep)) {
    return false;
  }
  currentStep.value = Number(requestedStep);
}

function isAllPreviousStepsValid(stepNumber) {
  for (let i = 1; i < stepNumber; i++) {
    if (!steps[i].validate()) {
      return false;
    }
  }
  return true;
}

function setStepData(step, data) {
  stepsData[step] = data;
}

function requestComplete() {
  for (const [, step] of Object.entries(steps)) {
    if (!step.validate()) {
      return false;
    }
  }

  completeWizard();
}

const completeWizard = debounce(ajaxSteps, 350);

async function ajaxSteps() {
  let data = JSON.parse(JSON.stringify(stepsData));
  data.items.items = data.items.items.filter((item) => !item.parent);
  data.submitter = submitButtonRef.value;
  wizardForm.value.dispatchEvent(
    new CustomEvent("ajax-submit", {
      detail: data,
    })
  );
}

function getLastValidStepNumber() {
  for (let i = 1; i < Object.keys(steps).length; i++) {
    if (!steps[i].validate()) {
      return i;
    }
  }
  return Object.keys(steps).length;
}

nextTick(function () {
  let lastValidStep = getLastValidStepNumber();
  currentStep.value = lastValidStep > 2 ? 2 : lastValidStep;
  wizardForm.value.ajaxForm({
    validationTarget: errorContainerRef.value,
  });
});
</script>
