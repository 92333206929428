<!-- eslint-disable vue/no-mutating-props -->
<template>
  <tr>
    <td>
      <PartFormSelect
        v-model="kitComponent.part"
        :name="`components[${index}][part]`"
        :parts-url="partsUrl"
        only-existing
      />
    </td>
    <td class="text-center quantity">
      <input
        v-model="kitComponent.quantity"
        class="form-control text-center m-auto qty-input"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        placeholder="1"
        :name="`components[${index}][quantity]`"
      />
    </td>
    <td v-html="`£${unitNet}`"></td>
    <td>
      <i
        class="fas fa-times text-danger"
        role="button"
        @click="removeItem(index)"
      ></i>
    </td>
  </tr>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import PartFormSelect from "@/components/admin/utilities/partFormSelect.vue";
import { priceFormat } from "@/utilities/priceFormat.js";
import { Eventbus } from "@/utilities/eventbus.js";

const emit = defineEmits(["remove-item"]);

const props = defineProps({
  kitComponent: {
    type: Object,
    default: () => ({}),
  },
  partsUrl: {
    type: String,
    default: "",
  },
  index: {
    type: [String, Number],
    default: 0,
  },
});

const removalInProgress = ref(false);
const unitNet = computed(() =>
  props.kitComponent.part && props.kitComponent.part.retail_price
    ? priceFormat(props.kitComponent.part.retail_price.toFixed(2))
    : "0.00"
);

let unwatch = null;

function setupWatcher() {
  if (unwatch) unwatch(); // Ensure any previous watcher is removed.
  unwatch = watch(
    () => props.kitComponent.part,
    (newValue, oldValue) => {
      if (removalInProgress.value) {
        return;
      }

      if (newValue !== oldValue) {
        Eventbus.emit("kit-component-part-selected", {
          index: props.index,
          oldPart: oldValue,
          part: newValue,
        });
      }
    }
  );
}

onMounted(() => {
  Eventbus.on("disable-kit-component-part-watcher", () => {
    if (unwatch) unwatch();
  });

  Eventbus.on("enable-kit-component-part-watcher", () => {
    setupWatcher();
  });

  setupWatcher();
});

function removeItem(index) {
  removalInProgress.value = true;
  emit("remove-item", index);
  removalInProgress.value = false;
}
</script>
