<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all countries.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="countries-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="code"
        @set-order-by="setOrder"
      >
        Code
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="tax"
        @set-order-by="setOrder"
      >
        Tax
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="country in data" :key="country.id">
        <td>{{ country.name }}</td>
        <td>{{ country.code }}</td>
        <td>{{ country.tax }}</td>
        <td v-html="country.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("name");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
