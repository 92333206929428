<template>
  <div v-if="images.length > 0">
    <!-- Main image -->
    <div class="w-100 d-flex mb-3 img-thumbnail main-image">
      <img ref="mainImage" class="w-100 my-auto" />
    </div>
    <!-- Thumbnails -->
    <div class="d-flex justify-content-center">
      <div
        class="d-flex justify-content-center align-items-center px-3"
        role="button"
        @click="scrollLeft"
      >
        <i class="fa fa-angle-left"></i>
      </div>
      <div ref="thumbnails" class="thumbnails d-flex gap-2 overflow-x-hidden">
        <thumbnail
          v-for="image in images"
          :key="image"
          :image="image"
          @click="setActive(image)"
        ></thumbnail>
      </div>
      <div
        class="d-flex justify-content-center align-items-center px-3"
        role="button"
        @click="scrollRight"
      >
        <i class="fa fa-angle-right"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import thumbnail from "@/components/admin/utilities/imageGallery/thumbnail.vue";

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  autoAdjustThumbnails: {
    type: Boolean,
    default: true,
  },
});

const images = reactive(props.images);
const thumbnails = ref(null);
const mainImage = ref(null);

nextTick(() => {
  adjustThumbnailsPosition();
  // Select first image
  if (images.length) {
    setActive(images[0]);
  }
});

function scrollLeft() {
  // Move last thumbnail to the start
  images.unshift(images.pop());
}

function scrollRight() {
  // Move first thumbnail to the end
  images.push(images.splice(0, 1)[0]);
}

function setActive(image) {
  mainImage.value.src = image;
}

function adjustThumbnailsPosition() {
  // Goal is to figure out how many thumbnails fit into view without being cropped
  if (props.autoAdjustThumbnails && images.length > 0) {
    const viewWidth = thumbnails.value.clientWidth;
    const thumbnailsDOM = thumbnails.value.querySelectorAll(".img-thumbnail");
    const thumbnailsCount = thumbnailsDOM.length;
    // Single thumbnail width
    const thumbnailWidth = thumbnailsDOM[0].offsetWidth;
    // Calculate how big is a single gap between thumbnails (this would take into account any flex gaps or margins)
    const gapBetweenThumbnails =
      (thumbnails.value.scrollWidth - thumbnailWidth * thumbnailsCount) /
      (thumbnailsCount - 1);
    // Space required for single thumbnail
    const fullThumbnailWidth = thumbnailWidth + gapBetweenThumbnails;

    // Calculate how many thumbnails fit into viewWidth
    // We add gapBetweenThumbnails because last thumbnail doesn't add actual gap
    const thumbnailsInView = Math.floor(
      (viewWidth + gapBetweenThumbnails) / fullThumbnailWidth
    );

    // Calculate how much space those thumbnails would actually take (including gaps between them)
    // Subtract gapBetweenThumbnails because last thumbnail doesn't add actual gap
    const requiredWidth =
      thumbnailsInView * fullThumbnailWidth - gapBetweenThumbnails;

    // Calculate how much of a whitespace we have left. Half will be left margin, other half will be right clipping mask
    const singleWhitespace = (viewWidth - requiredWidth) / 2;
    thumbnails.value.style.marginLeft = `${singleWhitespace}px`;
    // Math.ceil it just to cater for decimal pixels
    thumbnails.value.style.clipPath = `inset(0px ${Math.ceil(singleWhitespace)}px 0px 0px)`;
  }
}
</script>

<style lang="scss" scoped>
.main-image {
  aspect-ratio: 1/1;

  img {
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
