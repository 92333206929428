<template>
  <div class="mb-4">
    <label for="courier" class="control-label required">Courier</label>
    <imageSelect
      v-model="courier"
      name="courier"
      :url="routes.couriers"
      @update:model-value="updateParentWithValues"
    ></imageSelect>
  </div>
  <div class="mb-4">
    <label class="control-label" for="trackingNumber">Tracking number</label>
    <input
      id="trackingNumber"
      v-model="trackingNumber"
      type="text"
      class="form-control"
      name="trackingNumber"
      @change="updateParentWithValues"
    />
    <p v-if="trackingUrl" class="help-block text-muted">{{ trackingUrl }}</p>
  </div>
  <div class="mb-4">
    <label class="control-label" for="actualDeliveryPrice">
      Actual delivery price
    </label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="fas fa-pound-sign"></i>
      </span>
      <input
        id="actualDeliveryPrice"
        v-model="actualDeliveryPrice"
        type="text"
        class="form-control"
        name="actualDeliveryPrice"
        placeholder="Delivery price in pounds, e.g. 95.50"
        @change="updateParentWithValues"
      />
    </div>
  </div>

  <div class="mb-4">
    <label class="control-label" for="total_charged_delivery">
      Total Charged Delivery
      <small class="text-muted">(inc. VAT)</small>
    </label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="fas fa-pound-sign"></i>
      </span>
      <input
        id="total_charged_delivery"
        type="text"
        class="form-control"
        name="total_charged_delivery"
        :value="totalDeliveryPrice"
        disabled
      />
    </div>
  </div>
  <div v-if="extras.preselected.courier" class="form-check form-switch mb-4">
    <label class="form-check-label" for="resend_email">
      Resend customer email
    </label>
    <input
      id="resend_email"
      v-model="resendEmail"
      class="form-check-input"
      type="checkbox"
      @change="updateParentWithValues"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import imageSelect from "@/components/admin/utilities/imageSelect.vue";

const emit = defineEmits(["stepValueChanged"]);

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});

const courier = ref(props.extras.preselected.courier);
const trackingNumber = ref(props.extras.preselected.trackingNumber);
const actualDeliveryPrice = ref(props.extras.preselected.actualDeliveryPrice);
const resendEmail = ref(false);

const trackingUrl = computed(() =>
  courier.value?.tracking_url
    ? trackingNumber.value
      ? new URL(
          courier.value.tracking_url.replace(
            "[TRACKING NUMBER]",
            trackingNumber.value
          )
        ).toString()
      : courier.value.tracking_url
    : null
);
const totalDeliveryPrice = computed(() => {
  let total = 0;

  if (!props.stepsData.pickings?.pickings) {
    return total.toFixed(2);
  }

  // Unique list of orders. Same order could be in multiple picks - we don't want to add it multiple times
  const orderList = {};
  for (const picking of Object.values(props.stepsData.pickings?.pickings)) {
    orderList[picking.pickable] = parseFloat(picking.delivery_total);
  }
  for (const orderDeliveryTotal of Object.values(orderList)) {
    if (!isNaN(orderDeliveryTotal)) {
      total += orderDeliveryTotal;
    }
  }

  return total.toFixed(2);
});

function updateParentWithValues() {
  emit("stepValueChanged", {
    courier: courier.value,
    trackingNumber: trackingNumber.value,
    actualDeliveryPrice: actualDeliveryPrice.value,
    resendEmail: resendEmail.value,
  });
}
</script>
