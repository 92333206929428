<template>
  <input
    v-model="searchValue"
    type="text"
    class="form-control mb-2"
    placeholder="Search.."
  />
  <dl class="row mb-0">
    <dt class="col-sm-3">Manufacturing</dt>
    <dd class="col-sm-9">
      <template v-if="manufacturingMapLookup.length > 0">
        <div
          v-for="(manufacturer, index) in manufacturingMapLookup"
          :key="index"
        >
          <div v-if="manufacturer.series.length > 0" class="row">
            <div
              v-for="(series, index2) in manufacturer.series"
              :key="index2"
              class="col-sm-6 col-md-4"
            >
              <div
                v-if="
                  forceShowManufacturerLabel ||
                  manufacturingMapLookup.length > 1
                "
                class="text-muted"
              >
                {{ manufacturer.title }} - {{ series.title }}
              </div>
              <div v-else class="text-muted">
                {{ series.title }}
              </div>
              <ul v-if="series.vehicles.length > 0" class="ps-3">
                <li v-for="(vehicle, index3) in series.vehicles" :key="index3">
                  {{ vehicle.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <template v-else>-</template>
    </dd>
  </dl>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  manufacturingMap: {
    type: Array,
    default: () => [],
    validator: (value) => {
      return value.every((manufacturer) => {
        if (typeof manufacturer !== "object") {
          return false;
        }
        if (
          !Object.prototype.hasOwnProperty.call(manufacturer, "series") ||
          !Object.prototype.hasOwnProperty.call(manufacturer, "title")
        ) {
          return false;
        }
        if (
          !Array.isArray(manufacturer.series) ||
          !manufacturer.series.every((series) => {
            return (
              Object.prototype.hasOwnProperty.call(series, "title") &&
              Object.prototype.hasOwnProperty.call(series, "vehicles") &&
              Array.isArray(series.vehicles) &&
              series.vehicles.every((vehicle) => {
                return Object.prototype.hasOwnProperty.call(vehicle, "title");
              })
            );
          })
        ) {
          return false;
        }
        return true;
      });
    },
  },
  forceShowManufacturerLabel: Boolean,
});

const searchValue = ref("");

// Filter the manufacturing map based on the search value
const manufacturingMapLookup = computed(() => {
  if (props.manufacturingMap) {
    if (searchValue.value.length > 0) {
      return props.manufacturingMap.reduce((acc, manufacturer) => {
        const series = manufacturer.series.reduce((acc, series) => {
          const vehicles = series.vehicles.filter((vehicle) => {
            return vehicle.title
              .toLowerCase()
              .includes(searchValue.value.toLowerCase());
          });
          if (vehicles.length > 0) {
            acc.push({
              title: series.title,
              vehicles: vehicles,
            });
          }
          return acc;
        }, []);
        if (series.length > 0) {
          acc.push({
            title: manufacturer.title,
            series: series,
          });
        }
        return acc;
      }, []);
    }

    return props.manufacturingMap;
  }
  return [];
});
</script>
