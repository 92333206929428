<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all customer orders.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div class="d-flex gap-2 mt-2 justify-content-end">
        <TableFilter
          label="Status"
          :options="[
            { value: 'Processing', label: 'Picking Required' },
            { value: 'Completed', label: 'Completed' },
          ]"
          selected="Processing"
          :clearable="false"
          @change="(value, label) => filterChanged('status', value)"
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="picking-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        Order
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        width="15%"
        order-by="status"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer"
        @set-order-by="setOrder"
      >
        Customer
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="unallocated_items_count"
        class="text-center"
        @set-order-by="setOrder"
      >
        Items To Pick
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="placed_at"
        class="text-center"
        @set-order-by="setOrder"
      >
        Placed
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="order in data" :key="order.id">
        <td class="text-nowrap">
          <span class="me-1">
            <CustomerOrderDrawer
              :source-url="order.quick_view_url"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="order.id"></span>
        </td>
        <td class="text-center" v-html="order.status"></td>
        <td v-html="order.customer"></td>
        <td class="text-center">{{ order.unallocated_items_count }}</td>
        <td class="text-center">{{ order.placed_at }}</td>
        <td v-html="order.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div
        v-for="order in data"
        :key="order.id"
        class="list-group-item d-flex flex-column gap-3"
      >
        <div class="d-flex justify-content-between">
          <h5 class="mb-0" v-html="`Order ${order.id}`"></h5>
          <small>{{ order.placed_at }}</small>
        </div>
        <div class="d-flex flex-column gap-1">
          <div class="d-flex align-items-center justify-content-between">
            <div>Customer</div>
            <div class="text-end" v-html="order.customer"></div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>Status</div>
            <div class="text-end" v-html="order.status"></div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>Items To Pick</div>
            <div class="text-end">
              {{ order.unallocated_items_count }}
            </div>
          </div>
        </div>
        <div v-html="order.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("placed_at");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}
</script>
