<template>
  <div class="mx-0 mx-md-3 mx-lg-6">
    <div class="d-flex mb-3 gap-1">
      <b>Customer:</b>
      <div v-html="customer"></div>
    </div>
    <div v-if="manufacturing" class="d-flex mb-3 gap-1">
      <b>Manufacturing:</b>
      <span v-html="manufacturing"></span>
    </div>
    <div class="mb-3">
      <div class="table-responsive-xl">
        <table class="table" style="width: 100%">
          <thead>
            <tr>
              <th>Description</th>
              <th class="text-center">Qty</th>
              <th class="text-end">RRP</th>
              <th class="text-end">Discount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <template v-if="item.parent">
                <td colspan="999">
                  <div class="d-flex gap-3 ps-4 ms-1">
                    <img
                      class="img-thumbnail object-fit-cover thumbnail-xs"
                      :src="item.image ? item.image : partPlaceholderImage"
                    />
                    <div class="d-flex flex-column gap-1">
                      {{ item.name }}
                      <span v-if="item.sku">SKU: {{ item.sku }}</span>
                      <span>Qty Per Kit: {{ item.quantity }}</span>
                    </div>
                  </div>
                </td>
              </template>
              <template v-else>
                <td>
                  <div class="d-flex gap-3">
                    <img
                      class="img-thumbnail object-fit-cover thumbnail-sm"
                      :src="item.image ? item.image : partPlaceholderImage"
                      alt="Part Image"
                    />
                    <div class="d-flex flex-column gap-1">
                      {{ item.name }}
                      <span v-if="item.sku || item.newPartSKU">
                        SKU: {{ item.sku ? item.sku : item.newPartSKU }}
                      </span>
                      <span class="text-muted">
                        Used: {{ item.is_used ? "Yes" : "No" }}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="text-end">
                  <template v-if="item.is_used">N/A</template>
                  <template v-else>
                    {{ currencySymbol
                    }}{{ priceFormat((item.rrp ?? 0) * (currency.rate ?? 1)) }}
                  </template>
                </td>
                <td class="text-end">
                  <template v-if="item.is_used">N/A</template>
                  <template v-else>
                    <template v-if="item.discount > 0">
                      {{ item.discount }}%
                    </template>
                    <template v-else>-</template>
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="comments" class="mb-4">
      <b>Quote Comments</b>
      <p class="text-prewrap">{{ comments }}</p>
    </div>
    <div v-if="internalComments" class="mb-4">
      <b>Internal Comments</b>
      <p class="text-prewrap">{{ internalComments }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { priceFormat } from "@/utilities/priceFormat.js";

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});
const partPlaceholderImage = window.placeholderImage;

const items = ref([]);
const comments = ref("");
const internalComments = ref("");
const customer = ref("");
const manufacturing = ref("");
const currency = ref(null);

watch(props.stepsData, () => onStepsDataChange());

const currencySymbol = computed(() => {
  if (currency.value) {
    return currency.value.symbol;
  }
  return "£";
});

onStepsDataChange();
function onStepsDataChange() {
  if (props.stepsData.items) {
    currency.value = props.stepsData.items.currency;
    let mappedItems = [];
    props.stepsData.items.items.forEach(function (item) {
      item = JSON.parse(JSON.stringify(item));
      if (typeof item.part === "string") {
        item.name = item.part;
        item.image = window.placeholderImage;
        item.sku = null;
      } else {
        item.name = item.part.name;
        item.sku = item.part.sku;
        item.image = item.part.main_image
          ? item.part.main_image
          : window.placeholderImage;
      }
      mappedItems.push(item);
    });

    items.value = mappedItems;
    comments.value = props.stepsData.items.comments;
    internalComments.value = props.stepsData.items.internalComments;
  }
  if (props.stepsData.customer) {
    let customerPreview = props.stepsData.customer.name;
    let email =
      props.stepsData.customer.email_override || props.stepsData.customer.email;
    if (email) {
      customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="mailto:${email}"><i class="far fa-envelope"></i> ${email}</a>`;
    }
    if (props.stepsData.customer.telephone) {
      customerPreview += `<a class="fs-sm fw-medium text-muted d-block" href="tel:${props.stepsData.customer.telephone}"><i class="fa fa-phone"></i> ${props.stepsData.customer.telephone}</a>`;
    }
    customer.value = customerPreview;
  }
  if (props.stepsData.items.manufacturer) {
    manufacturing.value = props.stepsData.items.manufacturer.label;
  }
  if (props.stepsData.items.series) {
    manufacturing.value += " > " + props.stepsData.items.series.label;
  }
  if (props.stepsData.items.vehicle) {
    manufacturing.value += " > " + props.stepsData.items.vehicle.label;
  }
}
</script>

<style lang="scss" scoped>
.totals {
  display: grid;
  justify-items: end;
  grid-template-columns: auto 175px;
  margin-left: 60%;
  gap: 1rem;

  .divider {
    grid-column: 1/3;
    height: 1px;
    width: 100%;
  }

  .total-value {
    padding-right: 0.75rem;
  }
}
</style>
