<template>
  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="picking-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        Order
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        width="15%"
        order-by="status"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer"
        @set-order-by="setOrder"
      >
        Customer
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="unallocated_items_count"
        class="text-center"
        @set-order-by="setOrder"
      >
        Items To Pick
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="placed_at"
        class="text-center"
        @set-order-by="setOrder"
      >
        Placed
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="order in data" :key="order.id">
        <td>
          <span v-if="order.quick_view_url !== null" class="me-1">
            <CustomerOrderDrawer
              :source-url="order.quick_view_url"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="order.id"></span>
        </td>
        <td class="text-center" v-html="order.status"></td>
        <td v-html="order.customer"></td>
        <td class="text-center">{{ order.unallocated_items_count }}</td>
        <td class="text-center">{{ order.placed_at }}</td>
        <td v-html="order.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("placed_at");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
