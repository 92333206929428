<template>
  <div class="mb-4">
    <DropShipContainer
      v-if="!isGoodsInOrder"
      v-model="dropShipData"
      :drop-shipping-order-lookup-url="dropShippingOrdersUrl"
    />
    <h5
      v-if="!isGoodsInOrder && !isDropShippingOrder"
      class="center-line-text my-5"
    >
      OR
    </h5>
    <GoodsInContainer
      v-if="!isDropShippingOrder"
      v-model="goodsInData"
      :order-lookup-url="goodsInUrl"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import DropShipContainer from "@/components/admin/supplierOrders/formWizard/dropShip/container.vue";
import GoodsInContainer from "@/components/admin/supplierOrders/formWizard/goodsIn/container.vue";

const emit = defineEmits(["stepValueChanged"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => {},
  },
  extras: {
    type: Object,
    default: () => {},
  },
  stepsData: {
    type: Object,
    default: () => {},
  },
});

const dropShipData = ref({
  dropShipCustomerOrder: null,
  items: [],
});
const goodsInData = ref({
  customerOrders: [],
  items: [],
});

if (props.extras.preselected.items?.length) {
  goodsInData.value.items = props.extras.preselected.items.map((item) => {
    return {
      ...item,
      fromOrder: item.customer_order_id,
    };
  });
}

const isGoodsInOrder = computed(() => {
  return (
    goodsInData.value.items.length > 0 ||
    goodsInData.value.customerOrders.length > 0
  );
});

const isDropShippingOrder = computed(() => {
  return Boolean(dropShipData.value?.dropShipCustomerOrder);
});

const dropShippingOrdersUrl = computed(() => {
  let url = new URL(props.routes.customer_orders);
  url.searchParams.append("drop-shipping", 1);
  return url.href;
});

const goodsInUrl = computed(() => {
  let url = new URL(props.routes.customer_orders);
  url.searchParams.append("goods-in", 1);
  return url.href;
});

watch([dropShipData, goodsInData], () => updateParentWithValues(), {
  deep: true,
});

const updateParentWithValues = function () {
  let stepData = {};
  if (isDropShippingOrder.value) {
    stepData = {
      is_drop_ship: true,
      drop_ship_customer_order: dropShipData.value.dropShipCustomerOrder,
      items: dropShipData.value.items
        .map((item) => {
          return {
            ...item,
            customer_order_item_id: item.id,
          };
        })
        .filter((item) => parseInt(item.quantity) > 0),
    };
  } else {
    stepData = {
      customer_orders: goodsInData.value.customerOrders,
      items: goodsInData.value.items.filter(
        (item) => parseInt(item.quantity) > 0 && item.part
      ),
    };
  }

  emit("stepValueChanged", stepData);
};

// If we have preselected items, we need to update the parent component. Only goods in items can be preselected.
if (isGoodsInOrder.value) {
  updateParentWithValues();
}
</script>
