<template>
  <div v-if="!strippedVersion" class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all customer orders.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div class="d-flex gap-2 mt-2 justify-content-end">
        <TableFilter
          label="Team"
          :options="salesTeam"
          :selected="selectedTeam"
          @change="(value, label) => filterChanged('sales-team', value)"
        />
        <TableFilter
          label="Status"
          :options="statuses"
          :selected="selectedStatus"
          @change="(value, label) => filterChanged('status', value)"
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="customer-orders-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="legacy_customer_order_id"
        @set-order-by="setOrder"
      >
        Legacy ID
      </SortableTh>
      <SortableTh :current-order-by="orderBy">Invoice</SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer"
        @set-order-by="setOrder"
      >
        Customer
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="total"
        @set-order-by="setOrder"
      >
        Total
      </SortableTh>
      <th class="text-center">Payment</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        width="15%"
        order-by="status"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="placed_at"
        class="text-center"
        @set-order-by="setOrder"
      >
        Placed
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="order in data" :key="order.id">
        <td class="text-nowrap">
          <CustomerOrderDrawer
            :source-url="order.quick_view_url"
          ></CustomerOrderDrawer>
          {{ order.id }}
        </td>
        <td>
          <a
            v-if="
              order.legacy_customer_order_id && order.legacy_customer_order_link
            "
            :href="order.legacy_customer_order_link"
            target="_blank"
          >
            {{ order.legacy_customer_order_id }}
          </a>
        </td>
        <td v-html="order.invoice"></td>
        <td v-html="order.customer"></td>
        <td>{{ order.total }}</td>
        <td class="text-center">
          <template v-if="order.is_paid">
            <i
              v-if="order.paid_at"
              class="fa fa-check text-success"
              role="button"
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-trigger="hover"
              data-bs-original-title="Payment Date"
              :data-bs-content="order.paid_at"
            ></i>
            <i v-else class="fa fa-check text-success"></i>
          </template>
          <template v-else>
            <i
              v-if="order.payment_due_at"
              class="far fa-calendar-days"
              role="button"
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-trigger="hover"
              data-bs-original-title="Payment Due"
              :data-bs-content="order.payment_due_at"
            ></i>
            <i v-else class="fa fa-times"></i>
          </template>
        </td>
        <td class="text-center" v-html="order.status"></td>
        <td class="text-center">{{ order.placed_at }}</td>
        <td v-html="order.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  salesTeam: {
    type: Array,
    default: () => [],
  },
  statuses: {
    type: Array,
    default: () => [],
  },
  selectedTeam: {
    type: [String, Number],
    default: null,
  },
  selectedStatus: {
    type: [String, Number],
    default: null,
  },
  strippedVersion: {
    type: Boolean,
    default: false,
  },
});

const orderBy = ref("placed_at");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});
filterChanged("sales-team", props.selectedTeam);
filterChanged("status", props.selectedStatus);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}
</script>
