<template>
  <label for="customer" class="control-label required">Customer</label>
  <formSelect
    v-model="customer"
    name="customer"
    :url="routes.customers"
    placeholder="Select customer"
    clearable
    @update:model-value="updateParentWithValues"
  ></formSelect>
  <div v-if="customer" class="mt-2">
    <formSelect
      v-model="emailOverride"
      name="email_override"
      :options="additionalEmails"
      taggable
      placeholder="Select customer"
      @update:model-value="updateParentWithValues"
    />
    <div class="text-muted">
      Please select the appropriate email or enter a new one.
    </div>
  </div>
  <h5 class="center-line-text my-3">OR</h5>

  <liveInput
    v-model="name"
    label="Name"
    input-name="name"
    :validation-url="routes.validate_name"
    :show-validation="showNewValidation"
    required
  ></liveInput>

  <liveInput
    v-model="email"
    label="Email"
    input-name="email"
    :validation-url="routes.validate_email"
    :validation-regex="'.+@.+\\..+'"
    validation-failed-message="The email is invalid."
    :show-validation="showNewValidation"
    required
  ></liveInput>

  <div class="mb-4 form-group">
    <label for="telephone" class="control-label">Telephone</label>
    <input
      id="telephone"
      ref="telephoneInput"
      v-model="telephone"
      class="form-control"
      type="text"
      @input="updateParentWithValues"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { debounce } from "lodash";
import { useFetch } from "@/utilities/useFetch.js";
import formSelect from "@/components/admin/utilities/formSelect.vue";
import liveInput from "@/components/admin/utilities/liveInput.vue";

const emit = defineEmits(["stepValueChanged"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});

const customer = ref(props.extras.preselected.customer);
const emailOverride = ref(null);

const name = ref(null);
const email = ref(null);
const telephone = ref(null);
const showNewValidation = ref(true);
const additionalEmails = computed(() => {
  let additionalEmails =
    customer.value && customer.value.additional_emails
      ? customer.value.additional_emails
      : [];
  if (customer.value?.email) {
    additionalEmails.unshift(customer.value.email);
  }
  return additionalEmails;
});

watch(additionalEmails, () => {
  emailOverride.value = additionalEmails.value?.[0] ?? null;
});

watch([name, email, emailOverride], updateParentWithValues);

function updateParentWithValues() {
  window.dispatchEvent(new CustomEvent("clearNotifications"));
  if (customer.value) {
    showNewValidation.value = false;
    emit("stepValueChanged", {
      customer: customer.value.value,
      name: customer.value.name,
      email: customer.value.email,
      telephone: customer.value.telephone,
      email_override: emailOverride.value,
    });

    if (customer.value.value) {
      fetchComment(
        props.routes.customer_comments.replace(
          "%customer%",
          customer.value.value
        )
      );
    }
  } else {
    showNewValidation.value = true;
    emit("stepValueChanged", {
      customer: null,
      name: name.value,
      email: email.value,
      telephone: telephone.value,
      email_override: null,
    });
  }
}

const fetchComment = debounce(ajaxComment, 350);

let fetchAbort, fetchAbortSignal;
async function ajaxComment(url) {
  if (!url) {
    return;
  }

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  const res = await useFetch(url, { signal: fetchAbortSignal });
  let response = await res.json();
  if (response.comments) {
    window.dispatchEvent(
      new CustomEvent("pushNotification", {
        detail: {
          icon: "warning",
          text: "<b>Customer Notes: </b>" + response.comments,
        },
      })
    );
  }
}
</script>
