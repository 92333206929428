<template>
  <div class="mb-4">
    <DependantManufacturingSelect
      ref="manufacturingSelectGroupRef"
      :routes="routes"
      :preselected-manufacturer="extras.preselected.manufacturer"
      :preselected-series="extras.preselected.series"
      :preselected-vehicle="extras.preselected.vehicle"
      @value-changed="updateParentWithValues"
    />

    <div class="mb-4 form-group">
      <label class="control-label required">Currency</label>
      <FormSelect
        v-model="currency"
        :options="extras.currencies"
        :url="routes.currencyUrl"
      />
      <span
        v-if="
          defaultCustomerCurrency && currency.code !== defaultCustomerCurrency
        "
        class="text-muted"
      >
        Customer's default currency is {{ defaultCustomerCurrency }}.
      </span>
    </div>

    <div class="table-responsive-xl">
      <table class="table table-bordered table-striped table-vcenter mb-0">
        <thead>
          <tr>
            <th>Part</th>
            <th class="text-center" style="width: 10%">Qty</th>
            <th style="width: 15%">RRP</th>
            <th style="width: 15%">Discount</th>
            <th class="text-center" style="width: 10%">Item Used?</th>
            <th style="width: 2%"></th>
          </tr>
        </thead>
        <tbody>
          <Item
            v-for="(item, index) in lines"
            :key="item.id"
            :index="index"
            :item="item"
            :currency="currency"
            :parts-url="routes.parts"
            :item-skus="itemSkus"
            :sku-check-url="routes.sku_check"
            @remove-item="removeLine"
          ></Item>
        </tbody>
      </table>
    </div>
    <button
      class="btn btn-sm btn-secondary mt-3"
      type="button"
      @click="addLine()"
    >
      Add Item
    </button>
  </div>
  <div class="form-group mt-3">
    <label for="comments" class="control-label">Quote Comments</label>
    <textarea
      id="comments"
      v-model="comments"
      class="form-control"
      rows="5"
    ></textarea>
    <p class="help-block text-muted mb-0">
      These comments are visible to the customer.
    </p>
  </div>
  <div class="form-group mt-3">
    <label for="internal_comments" class="control-label">
      Internal Comments
    </label>
    <textarea
      id="internal_comments"
      v-model="internalComments"
      class="form-control"
      rows="5"
    ></textarea>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from "vue";
import DependantManufacturingSelect from "@/components/admin/utilities/dependantManufacturingSelect.vue";
import Item from "@/components/admin/quotes/draft/item.vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import { useFetch } from "@/utilities/useFetch.js";
import { Eventbus } from "@/utilities/eventbus.js";

const emit = defineEmits(["stepValueChanged"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});

const lines = reactive(
  props.extras.preselected.items ? props.extras.preselected.items : []
);
const comments = ref(
  props.extras.preselected.comments ? props.extras.preselected.comments : ""
);
const internalComments = ref(props.extras.preselected.internalComments ?? "");
const manufacturingSelectGroupRef = ref(null);
const itemSkus = ref([]);

const defaultCustomerCurrency = ref(
  props.stepsData.customer?.defaultCurrencyCode
);
const currency = ref({
  label: "GBP (1.0000)",
  code: "GBP",
  rate: 1,
  symbol: "£",
});
if (props.extras.preselected.currency) {
  currency.value = props.extras.currencies.find(
    (currency) => currency.code === props.extras.preselected.currency
  );
} else {
  if (defaultCustomerCurrency.value) {
    currency.value = props.extras.currencies.find(
      (currency) => currency.code === defaultCustomerCurrency.value
    );
  }
}

Eventbus.on("skuValid", (sku) => {
  itemSkus.value.push(sku);
});
Eventbus.on("skuInvalid", (sku) => {
  itemSkus.value = itemSkus.value.filter((itemSku) => itemSku !== sku);
});

const kitComponents = {};

watch(currency, () => {
  updateParentWithValues();
});

watch(
  () => ({ ...lines }),
  () => {
    loadMissingKitComponents();
    updateParentWithValues();
  },
  { deep: true }
);

watch(
  () => [comments.value, internalComments.value],
  () => updateParentWithValues()
);

function addLine(lineObj = null, index = null) {
  if (lineObj === null) {
    lineObj = {
      id: Math.floor(Math.random() * Date.now()).toString(36),
      quantity: 1,
    };
  }
  if (index === null || index === -1) {
    lines.push(lineObj);
  } else {
    lines.splice(index, 0, lineObj);
  }

  return lineObj;
}

function removeLine(removeAtIndex) {
  let lineToRemove = lines[removeAtIndex];
  lines.splice(removeAtIndex, 1);
  if (Object.prototype.hasOwnProperty.call(kitComponents, lineToRemove.id)) {
    // Loop in reverse and remove components
    for (var i = lines.length - 1; i >= 0; i--) {
      if (lines[i].parent === lineToRemove.id) {
        lines.splice(i, 1);
      }
    }
    delete kitComponents[lineToRemove.id];
  }
}

const updateParentWithValues = function () {
  let stepData = [];
  for (const [_, line] of Object.entries(lines)) {
    let lineData = JSON.parse(JSON.stringify(line));
    if (!lineData.part) {
      continue;
    }
    if (typeof lineData.part === "object") {
      delete lineData.newPartSKU;
    }
    stepData.push(lineData);
  }

  emit("stepValueChanged", {
    items: stepData,
    comments: comments.value,
    internalComments: internalComments.value,
    manufacturer:
      manufacturingSelectGroupRef.value &&
      manufacturingSelectGroupRef.value.manufacturer
        ? { ...manufacturingSelectGroupRef.value.manufacturer }
        : null,
    series:
      manufacturingSelectGroupRef.value &&
      manufacturingSelectGroupRef.value.series
        ? { ...manufacturingSelectGroupRef.value.series }
        : null,
    vehicle:
      manufacturingSelectGroupRef.value &&
      manufacturingSelectGroupRef.value.vehicle
        ? { ...manufacturingSelectGroupRef.value.vehicle }
        : null,
    currency: currency.value,
  });
};

const loadMissingKitComponents = async function () {
  for (const [_, line] of Object.entries(lines)) {
    if (
      line.part &&
      line.part.kit &&
      !Object.prototype.hasOwnProperty.call(kitComponents, line.id)
    ) {
      kitComponents[line.id] = [];
      const res = await useFetch(
        props.routes.kit_components.replace("%kit%", line.part.kit)
      );
      let components = await res.json();
      if (Array.isArray(components)) {
        components
          .reverse()
          .forEach((component) => addKitComponentLine(line, component));
      }
    }
  }
};

const addKitComponentLine = function (kitLine, componentData) {
  let index = lines.map((line) => line.id).indexOf(kitLine.id);
  let lineObj = {
    id: Math.floor(Math.random() * Date.now()).toString(36),
    quantity: componentData.quantity,
    is_used: componentData.is_used,
    part: componentData,
    parent: kitLine.id,
  };

  addLine(lineObj, index + 1);
  kitComponents[kitLine.id].push(lineObj.id);
};

if (lines.length > 0) {
  lines.forEach((line) => {
    if (line.parent) {
      if (Object.prototype.hasOwnProperty.call(kitComponents, line.parent)) {
        kitComponents[line.parent].push(line.id);
      } else {
        kitComponents[line.parent] = [line.id];
      }
    }
  });
  updateParentWithValues();
} else {
  // Add blank line to save user clicking "Add Item" initially.
  addLine();
}
</script>
