<template>
  <div class="d-flex flex-column flex-sm-row justify-content-around gap-3">
    <div
      class="d-flex align-items-baseline justify-content-center flex-basis-100"
    >
      <FormAddress
        ref="billingAddressRef"
        :address="billingAddress"
        label="Billing Address"
        :ajax-url="billingAddressRoute"
        allow-edit
        emit-on-copy="billingAddressCopied"
        listen-on-copy="deliveryAddressCopied"
        copy-button-text="Copy into Delivery"
        @address-changed="(data) => addressChanged('billing', data)"
      />
    </div>
    <div
      class="d-flex align-items-baseline justify-content-center flex-basis-100"
    >
      <FormAddress
        ref="deliveryAddressRef"
        :address="deliveryAddress"
        label="Delivery Address"
        :ajax-url="deliveryAddressRoute"
        show-reference
        allow-edit
        emit-on-copy="deliveryAddressCopied"
        listen-on-copy="billingAddressCopied"
        copy-button-text="Copy into Billing"
        @address-changed="(data) => addressChanged('delivery', data)"
      />
    </div>
  </div>
  <div v-if="customer" class="mt-4">
    <AddressesList :ajax-url="deliveryAddressesRoute" :show-actions="false">
      <template #customActions="{ address }">
        <div class="text-center">
          <button
            class="btn btn-sm btn-primary"
            type="button"
            @click="selectDeliveryAddressFromList(address)"
          >
            <i class="fa fa-fw fa-truck"></i>
            Deliver Here
          </button>
        </div>
      </template>
    </AddressesList>
  </div>
</template>

<script setup>
import { ref, watch, reactive, nextTick, onMounted } from "vue";
import FormAddress from "@/components/admin/addresses/address.vue";
import AddressesList from "@/components/admin/addresses/list.vue";

const emit = defineEmits(["step-value-changed"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
  isFromQuote: Boolean,
  isInitialLoadingFinished: Boolean,
});
watch(props.stepsData, onStepsDataChange);

let customer = props.extras.preselected.customer?.value;
const billingAddress = ref({});
const billingAddressRef = ref(null);
const billingAddressRoute = ref(
  props.routes.billing_address.replace("%customer%", customer)
);
const deliveryAddress = ref({});
const deliveryAddressRef = ref(null);
const deliveryAddressRoute = ref(
  props.routes.delivery_address.replace("%customer%", customer)
);
const deliveryAddressesRoute = ref(
  props.routes.delivery_addresses.replace("%customer%", customer)
);
let isMounted = false;

let stepData = reactive({ billing: {}, delivery: {} });
function addressChanged(key, data) {
  let addressData = JSON.parse(
    JSON.stringify(
      Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null && v != "")
      )
    )
  );
  delete addressData.value;
  delete addressData.actions;

  if (Object.keys(addressData).length === 0) {
    stepData[key] = null;
  } else {
    stepData[key] = addressData;
  }
  updateParentWithValues();
}

const selectDeliveryAddressFromList = function (address) {
  deliveryAddress.value = address;
  addressChanged("delivery", address);
};

const updateParentWithValues = function () {
  emit("step-value-changed", stepData);
};

function onStepsDataChange() {
  if (customer === props.stepsData?.customer?.customer) {
    return;
  }

  customer = props.stepsData.customer.customer;
  deliveryAddressRoute.value = props.routes.delivery_address.replace(
    "%customer%",
    customer
  );
  deliveryAddressesRoute.value = props.routes.delivery_addresses.replace(
    "%customer%",
    customer
  );
  billingAddressRoute.value = props.routes.billing_address.replace(
    "%customer%",
    customer
  );

  if (isMounted) {
    fetchAddresses();
  } else {
    nextTick(fetchAddresses);
  }
}

function fetchAddresses() {
  billingAddressRef.value.fetchAddress();
  deliveryAddressRef.value.fetchAddress();
}

onMounted(function () {
  isMounted = true;

  if (props.extras.preselected.billingAddress) {
    billingAddress.value = props.extras.preselected.billingAddress;
    addressChanged("billing", billingAddress.value);
  }

  if (props.extras.preselected.deliveryAddress) {
    deliveryAddress.value = props.extras.preselected.deliveryAddress;
    addressChanged("delivery", deliveryAddress.value);
  }
});
</script>
