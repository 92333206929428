<template>
  <div v-if="manufacturingMap.length">
    <span v-if="previewMode || manufacturingMap.length > 1">Models</span>
    <div v-else class="form-check form-switch mb-2 ms-3">
      <input
        :id="'manufacturing_map_' + uuid"
        class="form-check-input"
        type="checkbox"
        :middle="middle"
        :checked="checked"
        @change="(event) => toggleAllManufacturers(event.currentTarget.checked)"
      />
      <label class="form-check-label" :for="'manufacturing_map_' + uuid">
        Models
      </label>
    </div>
    <Manufacturer
      v-for="(manufacturer, index) in manufacturingMap"
      :key="index"
      ref="manufacturerRef"
      :manufacturer="manufacturer"
      :preselected-vehicles="selectedVehicles"
      :preview-mode="previewMode"
      :with-manufacturer-label="
        forceShowManufacturerLabel || manufacturingMap.length > 1
      "
      @three-state-toggle-changed="
        (threeStateToggleState) =>
          threeStateToggleChanged(i, threeStateToggleState)
      "
    ></Manufacturer>
  </div>
  <div v-else>No model assigned.</div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Manufacturer from "@/components/admin/parts/manufacturing/manufacturer.vue";
import { Eventbus } from "@/utilities/eventbus.js";

const props = defineProps({
  manufacturingMap: {
    type: Array,
    required: true,
  },
  preselectedVehicles: {
    type: Array,
    default: () => [],
  },
  previewMode: {
    type: Boolean,
    default: false,
  },
  forceShowManufacturerLabel: {
    type: Boolean,
    default: false,
  },
});

const uuid = Math.floor(Math.random() * Date.now()).toString(36);

const selectedVehicles = ref(props.preselectedVehicles);
const checked = ref(false);
const middle = ref(false);
const manufacturerRef = ref(null);
const selectedParts = ref([]); // Keep track of all selected parts.

onMounted(() => {
  Eventbus.on("kit-component-part-selected", ({ part }) => {
    if (part.delete) {
      // When a part is deleted, remove it from selectedParts.
      selectedParts.value = selectedParts.value.filter((p) => p.id !== part.id);
    } else {
      // Add the part to the selectedParts array.
      selectedParts.value.push(part);
    }

    // Recalculate the intersection of vehicles.
    if (selectedParts.value.length > 0) {
      // Get the vehicles of the first selected part.
      let intersectedVehicles = selectedParts.value[0].vehicles;

      // Find intersection with each subsequent part's vehicles.
      selectedParts.value.forEach((selectedPart) => {
        intersectedVehicles = intersectedVehicles.filter((vehicleId) =>
          selectedPart.vehicles.includes(vehicleId)
        );
      });

      selectedVehicles.value = [...intersectedVehicles]; // Update the reactive selected vehicles.
    } else {
      // If no parts are selected, clear the selected vehicles.
      selectedVehicles.value = [];
    }
  });
});

let manufacturerStates = {};
const threeStateToggleChanged = function (index, threeStateToggleState) {
  manufacturerStates[index] = threeStateToggleState;
  checked.value =
    Object.values(manufacturerStates).filter((x) => x > -1).length > 0;
  middle.value =
    checked.value &&
    Object.values(manufacturerStates).filter((x) => x < 1).length > 0
      ? 1
      : null;
};

const toggleAllManufacturers = function (checked) {
  manufacturerRef.value.forEach((item) => {
    item.toggleState(checked);
  });
};
</script>
