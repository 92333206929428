<template>
  <Modal
    ref="modal"
    size="xl"
    centered
    @hidden="Eventbus.emit('manage-attachments-modal:close')"
  >
    <template #title>Manage Attachments</template>

    <div class="mb-4">
      <FileUploadContainer
        ref="fileUploads"
        :temp-upload-form-url="tempUploadFormUrl"
        source="quote_attachments"
        allowed-format="image/png, image/jpeg, application/pdf"
        multiple
        allow-comments
        :show-primary-label="false"
        :existing-files="existingFiles"
        placeholder-text="Drag and drop files here or click to upload"
      />
    </div>

    <template #footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="Eventbus.emit('manage-attachments-modal:close')"
      >
        Close
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Modal from "@/components/admin/utilities/Modal.vue";
import { Eventbus } from "@/utilities/eventbus.js";
import FileUploadContainer from "@/components/admin/utilities/fileUpload/fileUploadContainer.vue";

defineProps({
  tempUploadFormUrl: {
    type: String,
    required: true,
  },
});

const modal = ref(null);
const fileUploads = ref(null);
const existingFiles = ref([]);

onMounted(() => {
  Eventbus.on("manage-attachments-modal:open", (attachments) => {
    if (typeof attachments === "object") {
      attachments = Object.values(attachments);
    }

    existingFiles.value = attachments.map((attachment) => {
      return {
        id: attachment.id,
        file: attachment.url,
        mime_type: attachment.mime_type,
        comments: attachment.comments,
        public_download_url: attachment.public_download_url,
        public_view_url: attachment.public_view_url,
      };
    });
    modal.value.bsModal.show();
  });

  Eventbus.on("manage-attachments-modal:close", () => {
    modal.value.bsModal.hide();
    Eventbus.emit(
      "manage-attachments-modal:attachments",
      Object.values(fileUploads.value.serialize())
    );
  });
});
</script>
