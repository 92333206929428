<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all parts.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="dismantled-tractor-parts-list:refresh"
  >
    <template #headings>
      <th>Part</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="quantity"
        class="text-center"
        @set-order-by="setOrder"
      >
        Qty
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="time_spent"
        class="text-center"
        @set-order-by="setOrder"
      >
        Time Spent
      </SortableTh>
      <th>Dismantled By</th>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Dismantled
      </SortableTh>
      <th v-if="!noActions"></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="tractorPart in data" :key="tractorPart.id">
        <td>
          <div class="d-flex align-items-center gap-1">
            <img
              class="img-thumbnail object-fit-cover thumbnail-xs"
              :src="
                tractorPart.main_image
                  ? tractorPart.main_image
                  : partPlaceholderImage
              "
            />
            <partDrawer
              :source-url="tractorPart.part_quick_view_url"
            ></partDrawer>
            <span>{{ tractorPart.part_name }}</span>
          </div>
        </td>
        <td class="text-center">{{ tractorPart.quantity }}</td>
        <td class="text-center">{{ tractorPart.time_spent }}</td>
        <td v-html="tractorPart.dismantled_by_user"></td>
        <td>{{ tractorPart.created_at }}</td>
        <td v-if="!noActions" v-html="tractorPart.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div
        v-for="tractorPart in data"
        :key="tractorPart.id"
        class="list-group-item d-flex flex-column gap-3"
      >
        <div class="d-flex gap-2">
          <img
            class="img-thumbnail object-fit-cover thumbnail-sm"
            :src="
              tractorPart.main_image
                ? tractorPart.main_image
                : partPlaceholderImage
            "
          />
          <h5 class="mb-0">{{ tractorPart.part_name }}</h5>
        </div>
        <div class="d-flex flex-column gap-1">
          <small class="text-muted">
            Dismantled by {{ tractorPart.dismantled_by_user }}
            <br />
            ({{ tractorPart.created_at }})
          </small>
          <div class="d-flex align-items-center justify-content-between">
            <div>Time Spent</div>
            <div class="text-end">{{ tractorPart.time_spent }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>Quantity</div>
            <div class="text-end">{{ tractorPart.quantity }}</div>
          </div>
        </div>
        <div v-if="!noActions" v-html="tractorPart.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import partDrawer from "@/components/admin/parts/partDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  statuses: {
    type: Object,
    default: () => ({}),
  },
  selectedStatus: {
    type: [String, Number],
    default: null,
  },
  noActions: {
    type: Boolean,
    default: false,
  },
});

const orderBy = ref("created_at");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);

const partPlaceholderImage = window.placeholderImage;

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
