<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          :placeholder="`Search ${customer} quotes..`"
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="quotes-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="xero_quote_number"
        @set-order-by="setOrder"
      >
        Quote Number
      </SortableTh>
      <SortableTh :current-order-by="orderBy">Order</SortableTh>
      <SortableTh :current-order-by="orderBy">Customer</SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="expires_at"
        @set-order-by="setOrder"
      >
        Expires
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        width="15%"
        order-by="status"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh :current-order-by="orderBy">Reason</SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="quote in data" :key="quote.id">
        <td class="text-nowrap">
          <QuotesDrawer :source-url="quote.quick_view_url"></QuotesDrawer>
          {{ quote.id }}
        </td>
        <td>{{ quote.xero_quote_number }}</td>
        <td>
          <span
            v-if="quote.customer_order_quick_view_url !== null"
            class="me-1"
          >
            <CustomerOrderDrawer
              :source-url="quote.customer_order_quick_view_url"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="quote.order"></span>
        </td>
        <td v-html="quote.customer"></td>
        <td>{{ quote.expires_at }}</td>
        <td class="text-center" v-html="quote.status"></td>
        <td>{{ quote.loss_reason }}</td>
        <td v-html="quote.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  statuses: {
    type: Object,
    default: () => ({}),
  },
  columns: {
    type: Array,
    default: () => [],
  },
  customer: {
    type: String,
    default: "",
  },
});

const orderBy = ref("id");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
