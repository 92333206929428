<template>
  <div class="d-flex gap-1 align-items-center">
    <formSelect
      v-model="model"
      :name="name"
      :url="url"
      :min-dropdown-width="400"
      with-image
      clearable
      placeholder="Type to search for a customer order item"
      @update:model-value="(e) => emit('update:modelValue', e)"
    >
      <template #no-options>Type to search for items..</template>

      <template #option="slotData">
        <div class="d-flex gap-2">
          <img
            class="img-thumbnail object-fit-cover"
            :src="
              slotData.option.main_image
                ? slotData.option.main_image
                : partPlaceholderImage
            "
            style="width: 64px"
          />
          <div class="d-flex flex-column">
            {{ slotData.option.label }}
            <div class="text-muted">
              Order #{{ slotData.option.customer_order_id }}
            </div>
            <div class="text-muted">Qty: {{ slotData.option.quantity }}</div>
          </div>
        </div>
      </template>

      <template #selected-option="slotData">
        <img
          class="img-thumbnail object-fit-cover"
          :src="
            slotData.option.main_image
              ? slotData.option.main_image
              : partPlaceholderImage
          "
        />
        [#{{ slotData.option.customer_order_id }}]
        {{ slotData.option.label }}
      </template>
    </formSelect>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import formSelect from "@/components/admin/utilities/formSelect.vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Object, String],
    default: "",
  },
  url: {
    type: String,
    required: true,
  },
});

const model = ref(props.modelValue);
const partPlaceholderImage = window.placeholderImage;

watch(
  () => props.modelValue,
  (newValue) => {
    model.value = newValue;
  }
);
</script>
