<template>
  <AisInstantSearch
    v-if="searchClient"
    ref="searchComponent"
    :search-client="searchClient"
    index-name="parts"
    :stalled-search-delay="1000"
    class="position-relative"
    :future="{ preserveSharedStateOnUnmount: true }"
  >
    <div class="container-fluid px-0 px-md-2">
      <div class="row row-cols-1 row-cols-xl-2">
        <div class="col col-xl-3 d-flex flex-column">
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary mb-3 d-xl-none align-self-end"
            data-bs-toggle="collapse"
            data-bs-target="#filters"
          >
            <i class="fa fa-filter"></i>
            {{ areFiltersCollapsed ? "Show" : "Hide" }} Filters
          </button>
          <div id="filters" ref="filtersDiv" class="mb-3 collapse d-xl-block">
            <ClearRefinementsButton />
            <RefinementList
              attribute="manufacturers"
              title="Manufacturers"
              :limit="5"
              show-more
              :show-more-limit="9999"
            />
            <RefinementList
              attribute="series"
              title="Series"
              :limit="5"
              show-more
              :show-more-limit="9999"
            />
            <RefinementList
              attribute="models"
              title="Models"
              :limit="5"
              show-more
              :show-more-limit="9999"
            />
            <RefinementList
              attribute="landing_category"
              title="Landing Categories"
              :limit="5"
              show-more
              :show-more-limit="9999"
            />
          </div>
        </div>
        <div class="col col-xl-9">
          <SearchInput
            ref="searchInput"
            v-model="search"
            :dark-mode="darkMode"
            placeholder="Search parts..."
          />
          <AisIndex index-name="parts">
            <AisStateResults>
              <template #default="{ results: { nbHits, nbPages } }">
                <p class="mt-3 mb-0 text-end small">
                  Showing {{ nbHits }} results
                </p>
                <div class="list-group">
                  <AisHits>
                    <template #default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.objectID"
                        class="list-group-item border d-flex flex-column flex-md-row gap-3 rounded my-4"
                      >
                        <div>
                          <img
                            v-if="item.main_image"
                            :src="item.main_image"
                            :alt="`Image of ${item.name}`"
                            class="img-thumbnail part-thumbnail rounded-4"
                          />
                          <img
                            v-else
                            src="/img/favicon-180.png"
                            alt="NYTP Logo"
                            class="img-thumbnail part-thumbnail"
                          />
                        </div>
                        <div class="w-100">
                          <div
                            class="d-flex align-items-center flex-column flex-md-row gap-3 gap-md-0"
                          >
                            <div class="w-100">
                              <h5>
                                <AisHighlight attribute="name" :hit="item" />
                              </h5>
                              <div class="container-fluid">
                                <div class="row row-cols-1 row-cols-md-2">
                                  <div class="col d-flex flex-column">
                                    <b>SKU</b>
                                    <small>
                                      <AisHighlight
                                        attribute="sku"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div class="col d-flex flex-column">
                                    <b>Landing Category</b>
                                    <small>
                                      <AisHighlight
                                        attribute="landing_category"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="item.equivalent_parts"
                                    class="col d-flex flex-column"
                                  >
                                    <b>Equivalent Parts</b>
                                    <small>
                                      <AisHighlight
                                        attribute="equivalent_parts"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="item.private_equivalent_parts"
                                    class="col d-flex flex-column"
                                  >
                                    <b>Private Equivalent Parts</b>
                                    <small>
                                      <AisHighlight
                                        attribute="private_equivalent_parts"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="
                                      item.secondary_private_equivalent_parts
                                    "
                                    class="col d-flex flex-column"
                                  >
                                    <b>Secondary Private Equivalent Parts</b>
                                    <small>
                                      <AisHighlight
                                        attribute="secondary_private_equivalent_parts"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="item.manufacturers_string"
                                    class="col d-flex flex-column"
                                  >
                                    <b>Manufacturers</b>
                                    <small>
                                      <AisHighlight
                                        attribute="manufacturers_string"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="item.series_string"
                                    class="col d-flex flex-column"
                                  >
                                    <b>Series</b>
                                    <small>
                                      <AisHighlight
                                        attribute="series_string"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                  <div
                                    v-if="item.models_string"
                                    class="col d-flex flex-column"
                                  >
                                    <b>Model</b>
                                    <small>
                                      <AisHighlight
                                        attribute="models_string"
                                        :hit="item"
                                      />
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0 btn-group" role="group">
                              <a
                                v-if="item.view_url"
                                :href="item.view_url"
                                class="btn btn-outline-secondary btn-sm"
                              >
                                <i class="fa fa-eye"></i>
                                View
                              </a>
                              <a
                                v-if="item.edit_url"
                                :href="item.edit_url"
                                class="btn btn-outline-secondary btn-sm"
                              >
                                <i class="fa fa-edit"></i>
                                Edit
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Pagination
                        v-if="nbPages > 1"
                        :navigation-fn="navigateToPage"
                      />
                    </template>
                  </AisHits>
                </div>
              </template>
            </AisStateResults>
          </AisIndex>
        </div>
      </div>
    </div>
  </AisInstantSearch>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import {
  AisHighlight,
  AisIndex,
  AisHits,
  AisInstantSearch,
  AisStateResults,
} from "vue-instantsearch/vue3/es";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Eventbus } from "@/utilities/eventbus.js";
import RefinementList from "@/components/admin/utilities/Search/RefinementList.vue";
import ClearRefinementsButton from "@/components/admin/utilities/Search/ClearRefinementsButton.vue";
import Pagination from "@/components/admin/utilities/Search/Pagination.vue";
import SearchInput from "@/components/admin/utilities/Search/SearchInput.vue";

const props = defineProps({
  categories: {
    type: Object,
    default: () => ({}),
  },
  searchApiKey: {
    type: String,
    required: true,
  },
  typesenseConfig: {
    type: Object,
    required: true,
  },
  queryBy: {
    type: String,
    required: true,
  },
});

const filtersDiv = ref(null);
const searchInput = ref(null);
const searchComponent = ref(null);
const search = ref("");
const searchClient = ref(null);
const darkMode = ref(document.cookie.includes("wants_dark_mode"));
const areFiltersCollapsed = ref(true);

const searchFieldBackground = computed(() =>
  darkMode.value ? "var(--bs-darker)" : "var(--bs-white)"
);

onMounted(() => {
  Eventbus.on("toggle:mode", (m) => (darkMode.value = m === "dark"));

  nextTick(() => {
    filtersDiv.value.addEventListener("hide.bs.collapse", () => {
      areFiltersCollapsed.value = true;
    });

    filtersDiv.value.addEventListener("show.bs.collapse", () => {
      areFiltersCollapsed.value = false;
    });
  });

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: props.searchApiKey,
      nodes: [
        {
          host: props.typesenseConfig.host,
          path: props.typesenseConfig.path,
          port: props.typesenseConfig.port,
          protocol: props.typesenseConfig.protocol,
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      //
    },
    collectionSpecificSearchParameters: {
      parts: {
        query_by: props.queryBy,
        // Add more collection specific search parameters here.
      },
    },
  });

  searchClient.value = typesenseInstantsearchAdapter.searchClient;
});

function navigateToPage(refine, page) {
  refine(page);
  searchInput.value.inputEl.scrollIntoView({ behavior: "smooth" });
}
</script>

<style scoped lang="scss">
.clear-button {
  right: 0.12rem;
  top: 50%;
  transform: translateY(-50%);
}

.search-field {
  outline: none;
  background-color: v-bind(searchFieldBackground);
}

.part-thumbnail {
  width: 150px;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
}
</style>
