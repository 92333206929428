<template>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th colspan="5" class="text-center">Lines</th>
      </tr>
      <tr>
        <th>Part</th>
        <th>Price</th>
        <th>Quantity</th>
        <th>Total Refund</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item of items" :key="item.id">
        <td
          class="align-middle"
          :class="{ 'text-muted': !isItemSelected(item) }"
        >
          <div class="form-check">
            <input
              :id="`item_${item.id}`"
              class="form-check-input"
              type="checkbox"
              :checked="isItemSelected(item)"
              @change="selectItem(item)"
            />
            <label class="form-check-label" :for="`item_${item.id}`">
              {{ item.part }}
            </label>
          </div>
        </td>
        <td
          class="align-middle"
          style="width: 10%"
          :class="{ 'text-muted': !isItemSelected(item) }"
        >
          <span v-if="item.type === 'item'">&pound;{{ item.price }}</span>
        </td>
        <td style="width: 10%">
          <input
            v-if="item.type === 'item'"
            type="number"
            class="form-control"
            :disabled="!isItemSelected(item)"
            :value="getSelectedItemValue(item, 'quantity') ?? item.quantity"
            min="1"
            :max="item.total_quantity"
            @input="updateQuantity($event, item)"
          />
        </td>
        <td style="width: 14%">
          <div class="input-group">
            <span
              class="input-group-text"
              :class="{ 'text-muted': !item.selected }"
            >
              <i class="fa fa-gbp"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :value="getSelectedItemValue(item, 'total') ?? item.total"
              :disabled="!isItemSelected(item)"
              @input="updateTotal($event, item)"
            />
          </div>
        </td>
        <td style="width: 24%">
          <input
            type="text"
            class="form-control"
            :disabled="!isItemSelected(item)"
            :value="getSelectedItemValue(item, 'comment') ?? ''"
            placeholder="Item has been refunded - credit to follow"
            @input="setSelectedItemValue(item, 'comment', $event.target.value)"
          />
        </td>
      </tr>
      <tr>
        <td colspan="3"></td>
        <td>
          <div class="input-group">
            <span class="input-group-text"><i class="fa fa-gbp"></i></span>
            <input
              type="text"
              :value="totalRefund"
              class="form-control"
              :disabled="!selectedItems.length"
              name="total"
            />
          </div>
        </td>
        <td>
          <input
            name="internal_comment"
            type="text"
            class="form-control"
            :disabled="!selectedItems.length"
          />
          <input
            type="hidden"
            name="selected_items"
            :value="JSON.stringify(selectedItems)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { debouncedWatch } from "@vueuse/core";
import { computed, ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";

const props = defineProps({
  customerOrder: {
    type: Number,
    default: null,
  },
  refundItemsUrl: {
    type: String,
    required: true,
  },
  currentTotalRefund: {
    type: Number,
    default: 0,
  },
});

const items = ref([]);
const selectedItems = ref([]);
const totalRefund = computed(() => {
  if (props.currentTotalRefund) {
    return props.currentTotalRefund;
  }

  const total = selectedItems.value.reduce((total, line) => {
    return total + line.total;
  }, 0);

  return Number(total).toFixed(2);
});

debouncedWatch(() => props.customerOrder, getRefundItems, {
  debounce: 250,
});

async function getRefundItems(customerOrder) {
  const response = await useFetch(
    props.refundItemsUrl.replace("%customerOrder%", customerOrder)
  );
  setTimeout(async () => {
    items.value = await response.json();
    selectedItems.value = items.value.filter((item) => item.selected);
  }, 0);
}

function selectItem(item) {
  if (isItemSelected(item)) {
    selectedItems.value = selectedItems.value.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    return;
  }

  selectedItems.value.push({ ...item });
}

function isItemSelected(item) {
  return selectedItems.value.some(
    (selectedItem) => selectedItem.id === item.id
  );
}

function getSelectedItemValue(item, field) {
  if (!isItemSelected(item)) {
    return null;
  }

  return selectedItems.value.find(
    (selectedItem) => selectedItem.id === item.id
  )[field];
}

function setSelectedItemValue(item, field, value) {
  if (!isItemSelected(item)) {
    return;
  }

  const selectedItem = selectedItems.value.find(
    (selectedItem) => selectedItem.id === item.id
  );

  if (!selectedItem) {
    return;
  }

  selectedItem[field] = value;
}

function updateQuantity(e, item) {
  if (!isItemSelected(item)) {
    return;
  }

  let updatedQuantity = parseInt(e.target.value);

  if (updatedQuantity < 1) {
    updatedQuantity = 1;
  }

  if (updatedQuantity > item.total_quantity) {
    updatedQuantity = item.total_quantity;
  }

  const total = item.price * updatedQuantity;

  setSelectedItemValue(item, "quantity", updatedQuantity);

  if (!isNaN(total)) {
    setSelectedItemValue(item, "total", total);
  }
}

function updateTotal(e, item) {
  if (!isItemSelected(item)) {
    return;
  }

  let total = parseFloat(e.target.value);

  if (isNaN(total)) {
    return;
  }

  if (total < 0) {
    total = 0;
  }

  if (total > item.price * item.quantity) {
    total = item.price * item.quantity;
  }

  setSelectedItemValue(item, "total", total);
}

defineExpose({
  selectedItems,
});
</script>
