<template>
  <div class="content-heading p-0 mb-2">Goods In</div>
  <FormSelect
    v-model="model.customerOrders"
    :url="orderLookupUrl"
    placeholder="Select customer orders"
    helper-text="Select customer orders you are purchasing items for. Search for <kbd>#</kbd> to look for best matches."
    clearable
    multiple
  >
    <template #option="{ option }">
      {{ option.label }}
      <template v-if="option.on_hold_items_count > 0">
        ({{
          option.on_hold_items_count +
          (option.on_hold_items_count === 1 ? " item" : " items")
        }}
        on hold)
      </template>
    </template>
  </FormSelect>
  <div v-if="availableItems.length > 0" class="table-responsive-xl mt-3">
    <table class="table table-bordered table-striped table-vcenter mb-0">
      <thead>
        <tr>
          <th>Part</th>
          <th class="text-center">Qty</th>
          <th>Customer Order</th>
          <th style="width: 43px"></th>
        </tr>
      </thead>
      <tbody>
        <PreviewItem
          v-for="item in availableItems"
          :key="item.id"
          :item="item"
          @add-item="addLine(item)"
        />
      </tbody>
    </table>
  </div>
  <div v-if="model.items.length > 0" class="table-responsive-xl mt-3">
    <table class="table table-bordered table-striped table-vcenter mb-0">
      <thead>
        <tr>
          <th>Part</th>
          <th style="width: 25%">Reference</th>
          <th style="width: 10%" class="text-center">Qty</th>
          <th style="width: 14%">RRP</th>
          <th style="width: 10%">Discount</th>
          <th style="width: 43px"></th>
        </tr>
      </thead>
      <tbody>
        <Item
          v-for="item in model.items"
          :key="item.id"
          v-model:quantity="item.quantity"
          v-model:reference="item.reference"
          v-model:rrp="item.rrp"
          v-model:discount="item.discount"
          v-model:part="item.part"
          :from-order="item.fromOrder"
          :is-normally-drop-shipped="item.is_part_drop_shipped"
          @remove-item="removeLine(item)"
        />
      </tbody>
    </table>
  </div>
  <button
    class="btn btn-sm btn-secondary mt-3"
    type="button"
    @click="addLine()"
  >
    Add Item
  </button>
</template>

<script setup>
import { ref, watch } from "vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import Item from "@/components/admin/supplierOrders/formWizard/goodsIn/item.vue";
import PreviewItem from "@/components/admin/supplierOrders/formWizard/goodsIn/previewItem.vue";

defineProps({
  orderLookupUrl: {
    type: String,
    required: true,
  },
});

const model = defineModel({
  type: Object,
  default: { customerOrders: [], items: [] },
});

const availableItems = ref([]);

// Used to restore items when you click "X" against an item
let _availableItemsCache = {};

watch(
  () => model.value.customerOrders,
  (newValue, oldValue) => {
    if (newValue.length > oldValue.length) {
      const newOrder = newValue.find((item) => !oldValue.includes(item));
      if (newOrder) {
        const orderLabel = `#${newOrder.id} for ${newOrder.customer_name}`;
        newOrder.items.forEach((item) => {
          if (model.value.items.find((addedItem) => addedItem.id === item.id)) {
            return;
          }
          if (
            item.quantity - (item.fulfilled_quantity ?? 0) === 0 ||
            item.is_kit
          ) {
            return;
          }
          item.orderLabel = orderLabel;
          item.customer_order_id = newOrder.id;
          availableItems.value.push(item);
          _availableItemsCache[item.id] = item;
        });
      }
    } else {
      // Remove items
      const removedOrder = oldValue.find((item) => !newValue.includes(item));
      if (removedOrder) {
        availableItems.value = availableItems.value.filter(
          (item) => item.customer_order_id !== removedOrder.id
        );
        _availableItemsCache = Object.keys(_availableItemsCache).reduce(
          (acc, key) => {
            if (
              _availableItemsCache[key].customer_order_id !== removedOrder.id
            ) {
              acc[key] = _availableItemsCache[key];
            }
            return acc;
          },
          {}
        );
      }
    }
  }
);

function addLine(item = null) {
  const itemData = {
    id: Math.floor(Math.random() * Date.now()).toString(36),
    quantity: 1,
    fromOrder: null,
    reference: null,
    rrp: null,
    discount: null,
    part: null,
    customer_order_item_id: null,
    is_on_hold: false,
    is_part_drop_shipped: false,
  };
  if (item !== null) {
    itemData.customer_order_item_id = item.id;
    itemData.fromOrder = item.customer_order_id;
    itemData.part = item.part;
    itemData.quantity = Math.max(item.quantity - item.fulfilled_quantity, 0);
    itemData.rrp = item.part?.retail_price;
    itemData.is_on_hold = item.is_on_hold;
    itemData.is_part_drop_shipped = item.is_part_drop_shipped;

    // Remove item from available items
    const removeAtIndex = availableItems.value.findIndex(
      (availableItem) => availableItem.id === item.id
    );
    if (removeAtIndex > -1) {
      availableItems.value.splice(removeAtIndex, 1);
    }
  }
  model.value.items.push(itemData);
}

function removeLine(item) {
  const removeAtIndex = model.value.items.findIndex(
    (addedItem) => addedItem.id === item.id
  );
  if (removeAtIndex > -1) {
    const removedItem = model.value.items.splice(removeAtIndex, 1)[0];
    if (_availableItemsCache[removedItem.customer_order_item_id]) {
      availableItems.value.push(
        _availableItemsCache[removedItem.customer_order_item_id]
      );
    }
  }
}
</script>
