<template>
  <Modal ref="modal" centered @hidden="emit('hidden')">
    <template #title>
      {{ mode === "picking" ? "Pick" : "Allocate" }} Part
    </template>

    <div class="d-flex flex-column gap-4 mb-4">
      <p v-if="mode === 'picking'" class="fs-base mb-0 text-center">
        Select a quantity to pick and a location where you are picking it from:
      </p>
      <p v-else class="fs-base mb-0 text-center">
        Select a quantity to allocate and a location where you are allocating it
        to:
      </p>
      <div>
        <label
          for="pick_quantity"
          class="control-label required flex-shrink-0 mb-2"
        >
          Quantity
        </label>
        <input
          id="pick_quantity"
          v-model="pickQuantity"
          type="number"
          min="1"
          class="form-control"
          inputmode="numeric"
        />
        <div class="text-danger form-error fs-6 text-end">
          <template v-if="isNaN(pickQuantity) || !/^\d+$/.test(pickQuantity)">
            Quantity must be a whole number.
          </template>
          <template v-else-if="pickQuantity < 1">
            Quantity must be greater than 1.
          </template>
          <template v-else-if="canPick && isOverallocating">
            There isn't sufficient stock in selected location.
            <br />
            Are you sure this is correct?
          </template>
          <template
            v-else-if="
              pickingItem &&
              pickQuantity >
                pickingItem.total_quantity - pickingItem.picked_quantity
            "
          >
            You have allocated more stock than the quantity
            {{ mode === "picking" ? "required" : "available" }}.
          </template>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-between align-items-center">
          <label
            for="pick_location"
            class="control-label required flex-shrink-0"
          >
            Location
          </label>
          <button
            v-if="partLocations.length"
            type="button"
            class="btn btn-link"
            @click="toggleLocations"
          >
            {{ locationsText }}
          </button>
        </div>
        <select id="pick_location" v-model="pickLocation" class="form-select">
          <option value="">Select a location</option>
          <option
            v-for="location in allLocations"
            :key="location.value"
            :value="location.value"
          >
            {{ location.label }}
          </option>
        </select>
        <p
          v-if="mode === 'allocation' && partLocations.length"
          class="text-muted"
        >
          <b>{{ partLocations[0].label }}</b>
          is last known location part was allocated to / from.
        </p>
      </div>
    </div>

    <template #footer>
      <div class="d-flex gap-3 justify-content-end">
        <button
          type="button"
          class="btn btn-secondary"
          @click="modal.bsModal.hide()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!canPick"
          @click="pick"
        >
          {{ mode === "picking" ? "Pick" : "Allocate" }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import Modal from "@/components/admin/utilities/Modal.vue";
import { useFetch } from "@/utilities/useFetch.js";

const emit = defineEmits(["picked", "hidden"]);
const props = defineProps({
  locationsRoute: {
    type: String,
    default: "",
  },
  pickingItem: {
    type: Object,
    default: null,
  },
  locations: {
    type: Array,
    default: () => [],
  },
  mode: {
    type: String,
    default: "picking",
    validator: (propValue) => {
      return ["picking", "allocation"].includes(propValue);
    },
  },
});

const modal = ref(null);
const pickQuantity = ref(1);
const pickLocation = ref("");
const locationMode = ref("in_stock_locations");
const partLocations = ref([]);
const isOverallocating = ref(false);
const allLocations = computed(() => {
  return locationMode.value === "in_stock_locations" &&
    partLocations.value.length
    ? partLocations.value
    : props.locations;
});
const locationsText = computed(() => {
  return locationMode.value === "in_stock_locations"
    ? "View All Locations"
    : props.mode === "allocation"
      ? "View Known Locations"
      : "View In Stock Locations";
});
const canPick = computed(() => {
  if (!props.pickingItem) {
    return false;
  }

  const validQuantity =
    isNaN(pickQuantity.value) || !/^\d+$/.test(pickQuantity.value);

  if (
    pickQuantity.value >
    props.pickingItem.total_quantity - props.pickingItem.picked_quantity
  ) {
    return false;
  }

  return !(validQuantity || pickLocation.value === "");
});

watch(
  () => props.pickingItem,
  (newValue) => {
    if (!newValue) {
      return;
    }

    pickQuantity.value =
      newValue.total_quantity - (newValue.picked_quantity ?? 1);
    pickLocation.value = "";

    if (typeof newValue.part === "object" && props.locationsRoute) {
      useFetch(props.locationsRoute.replace("%part%", newValue.part.value))
        .then((r) => r.json())
        .then((d) => {
          if (d.data.length) {
            locationMode.value = "in_stock_locations";
          }

          partLocations.value = d.data;
          if (partLocations.value.length > 0) {
            if (newValue.tractor_part && newValue.tractor_part.location) {
              const matchedLocation = partLocations.value.find(
                (l) => l.value === newValue.tractor_part.location.id
              );
              if (matchedLocation) {
                pickLocation.value = matchedLocation.value;
              }
            } else {
              pickLocation.value = partLocations.value[0].value;
            }
          }
        });
    } else {
      partLocations.value = [];
      locationMode.value = "all_locations";
    }

    modal.value.bsModal.show();
  },
  {
    deep: true,
  }
);
watch([pickQuantity, pickLocation], checkIfOverallocating);

function pick() {
  emit("picked", props.pickingItem, pickQuantity.value, pickLocation.value);
  modal.value.bsModal.hide();
}

function toggleLocations() {
  locationMode.value =
    locationMode.value === "in_stock_locations"
      ? "all_locations"
      : "in_stock_locations";
  pickLocation.value = "";
}

function checkIfOverallocating(newValue) {
  isOverallocating.value = false;

  if (!newValue) {
    return;
  }

  const partLocation = partLocations.value.find(
    (l) => l.value === pickLocation.value
  );

  if (!partLocation) {
    return;
  }

  if (partLocation.stock_amount < pickQuantity.value) {
    isOverallocating.value = true;
  }
}
</script>
