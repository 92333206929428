<template>
  <AisInstantSearch
    v-if="searchClient"
    ref="searchComponent"
    :search-client="searchClient"
    index-name="parts"
    :stalled-search-delay="1000"
    class="position-relative"
    :future="{ preserveSharedStateOnUnmount: true }"
  >
    <SearchInput
      ref="searchInput"
      v-model="search"
      :dark-mode="darkMode"
      :is-search-pane-visible="showSearchPane"
      :class="{ 'search-field-div rounded': showSearchPane }"
      placeholder="Search system wide..."
      enable-shortcut
      @click="showSearchPane === false ? (showSearchPane = true) : null"
      @focus="showSearchPane = true"
      @shortcut-pressed="showSearchPane = true"
    />
    <Transition
      enter-active-class="transition-active"
      enter-from-class="transition-enter"
      enter-to-class="transition-enter-to"
      leave-active-class="transition-active"
      leave-from-class="transition-leave"
      leave-to-class="transition-leave-to"
    >
      <div
        v-show="showSearchPane"
        class="position-absolute w-100 shadow rounded border search-pane overflow-y-auto"
        :class="darkMode ? 'bg-dark' : 'bg-white'"
      >
        <div class="accordion accordion-flush">
          <AisIndex
            v-for="model of typesenseConfig.models"
            :key="model.name"
            :index-name="model.name"
          >
            <AisStateResults>
              <template #default="{ results: { nbHits } }">
                <div class="accordion-item" :class="{ 'bg-dark': darkMode }">
                  <AisInfiniteHits>
                    <template #default="{ items, isLastPage, refineNext }">
                      <h5 class="accordion-header border-bottom">
                        <button
                          type="button"
                          class="accordion-button"
                          :class="{
                            collapsed: !items.length,
                            'bg-secondary text-light': darkMode,
                          }"
                          data-bs-toggle="collapse"
                          :data-bs-target="`#accordion_${model.name}`"
                        >
                          <span class="badge bg-primary me-2">
                            {{ nbHits }}
                          </span>
                          {{ model.title }}
                        </button>
                      </h5>

                      <ResultList
                        v-if="model.name === 'parts'"
                        id="accordion_parts"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="name"
                            :data="[
                              { attribute: 'sku', icon: 'barcode' },
                              { attribute: 'commodity_code', icon: 'globe' },
                              { attribute: 'equivalent_parts', icon: 'link' },
                              {
                                attribute: 'private_equivalent_parts',
                                icon: 'lock',
                              },
                              {
                                attribute: 'secondary_private_equivalent_parts',
                                icon: 'lock',
                              },
                            ]"
                          />
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'tractors'"
                        id="accordion_tractors"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="title"
                            :data="[
                              { attribute: 'vin_number', icon: 'barcode' },
                              { attribute: 'internal_reference', icon: 'tag' },
                            ]"
                          />
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'customers'"
                        id="accordion_customers"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="name"
                            :data="[
                              { attribute: 'email', icon: 'envelope' },
                              { attribute: 'telephone', icon: 'phone' },
                              {
                                attribute: 'billing_address',
                                icon: 'address-card',
                              },
                              { attribute: 'delivery_address', icon: 'truck' },
                              {
                                attribute: 'vat_number',
                                icon: 'sterling-sign',
                              },
                              { attribute: 'eori_number', icon: 'euro-sign' },
                            ]"
                          />
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'customer_orders'"
                        id="accordion_customer_orders"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="customer_order_id"
                            header-icon="hashtag"
                            :data="[
                              { attribute: 'customer_name', icon: 'user' },
                              { attribute: 'customer_email', icon: 'envelope' },
                              {
                                attribute: 'customer_telephone',
                                icon: 'phone',
                              },
                              {
                                attribute: 'billing_address',
                                icon: 'address-card',
                              },
                              { attribute: 'delivery_address', icon: 'truck' },
                            ]"
                          />
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'quotes'"
                        id="accordion_quotes"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            :data="[
                              { attribute: 'customer_name', icon: 'user' },
                              { attribute: 'customer_email', icon: 'envelope' },
                              {
                                attribute: 'customer_telephone',
                                icon: 'phone',
                              },
                            ]"
                          >
                            <i class="fa-solid fa-fw fa-hashtag"></i>
                            <AisHighlight attribute="id" :hit="item" />
                            <span v-if="item.xero_quote_number">
                              (
                              <AisHighlight
                                attribute="xero_quote_number"
                                :hit="item"
                              />
                              )
                            </span>
                          </ResultEntry>
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'shipments'"
                        id="accordion_shipments"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="courier_name"
                            :data="[
                              { attribute: 'tracking_number', icon: 'barcode' },
                            ]"
                          />
                        </template>

                        <template #extraButtons="{ item }">
                          <a
                            v-if="item.tracking_url"
                            :href="item.tracking_url"
                            class="btn btn-outline-secondary btn-sm d-flex flex-row gap-1 align-items-center"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa-solid fa-map-location-dot"></i>
                            <span class="d-none d-lg-block">Track</span>
                          </a>
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'suppliers'"
                        id="accordion_suppliers"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            header-attribute="name"
                            :data="[
                              { attribute: 'telephone', icon: 'phone' },
                              { attribute: 'email', icon: 'envelope' },
                            ]"
                          />
                        </template>
                      </ResultList>

                      <ResultList
                        v-if="model.name === 'supplier_orders'"
                        id="accordion_supplier_orders"
                        :refine-next="refineNext"
                        :is-last-page="isLastPage"
                        :items="items"
                      >
                        <template #default="{ item }">
                          <ResultEntry
                            :item="item"
                            :data="[
                              { attribute: 'supplier_name', icon: 'user' },
                              { attribute: 'supplier_email', icon: 'envelope' },
                            ]"
                          >
                            <i class="fa-solid fa-fw fa-hashtag"></i>
                            <AisHighlight attribute="id" :hit="item" />
                            <span v-if="item.supplier_order_number">
                              (
                              <AisHighlight
                                attribute="supplier_order_number"
                                :hit="item"
                              />
                              )
                            </span>
                          </ResultEntry>
                        </template>
                      </ResultList>
                    </template>
                  </AisInfiniteHits>
                </div>
              </template>
            </AisStateResults>
          </AisIndex>
        </div>
      </div>
    </Transition>
  </AisInstantSearch>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {
  AisInstantSearch,
  AisInfiniteHits,
  AisStateResults,
  AisIndex,
  AisHighlight,
} from "vue-instantsearch/vue3/es";
import { onClickOutside } from "@vueuse/core";
import ResultList from "@/components/admin/utilities/Search/ResultList.vue";
import ResultEntry from "@/components/admin/utilities/Search/ResultEntry.vue";
import { Eventbus } from "@/utilities/eventbus.js";
import SearchInput from "@/components/admin/utilities/Search/SearchInput.vue";

const props = defineProps({
  searchApiKey: {
    type: String,
    required: true,
  },
  typesenseConfig: {
    type: Object,
    required: true,
  },
});

const searchInput = ref(null);
const searchComponent = ref(null);
const search = ref("");
const showSearchPane = ref(false);
const searchClient = ref(null);
const darkMode = ref(document.cookie.includes("wants_dark_mode"));

const searchDivOutline = computed(() =>
  darkMode.value ? "var(--bs-dark)" : "var(--bs-light)"
);

onMounted(() => {
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      showSearchPane.value = false;
    }
  });

  Eventbus.on("toggle:mode", (m) => (darkMode.value = m === "dark"));

  onClickOutside(searchComponent, () => (showSearchPane.value = false));

  const collectionSpecificSearchParameters = props.typesenseConfig.models
    .map((model) => ({
      [model.name]: {
        query_by: model.query_by,
      },
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: props.searchApiKey,
      nodes: [
        {
          host: props.typesenseConfig.host,
          path: props.typesenseConfig.path,
          port: props.typesenseConfig.port,
          protocol: props.typesenseConfig.protocol,
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      //
    },
    collectionSpecificSearchParameters,
  });

  searchClient.value = typesenseInstantsearchAdapter.searchClient;
});
</script>

<style scoped lang="scss">
.search-pane {
  top: 114%;
  z-index: 1000;
  max-height: 600px;
}

.transition-active {
  transition: all 0.2s ease-out;
}

.transition-enter {
  transform: scale(0.95);
  opacity: 0;
}

.transition-enter-to {
  transform: scale(1);
  opacity: 1;
}

.transition-leave {
  transform: scale(1);
  opacity: 1;
}

.transition-leave-to {
  transform: scale(0.95);
  opacity: 0;
}

.results-section:not(:first-child) {
  margin-top: 120px;
}

.search-field-div {
  outline: v-bind(searchDivOutline) 4px solid;
}
</style>
