<template>
  <i
    class="text-primary"
    :class="{
      'fa fa-magnifying-glass': !isWaitingToOpen,
      'fa fa-spin fa-spinner': isWaitingToOpen,
    }"
    role="button"
    @click="openDrawer"
  ></i>
  <drawer
    v-if="supplierOrder"
    ref="drawerRef"
    :title="'Order #' + supplierOrder.id"
    size="lg"
  >
    <template #default>
      <div class="text-center">
        <div class="fs-base fw-semibold">{{ supplierOrder.supplier.name }}</div>
        <div
          v-if="supplierOrder.supplier.email"
          class="fs-sm fw-medium text-muted"
        >
          <i class="far fa-envelope"></i>
          {{ supplierOrder.supplier.email }}
        </div>
        <div
          v-if="supplierOrder.supplier.telephone"
          class="fs-sm fw-medium text-muted"
        >
          <i class="fa fa-phone"></i>
          {{ supplierOrder.supplier.telephone }}
        </div>
      </div>
      <dl class="mt-3 d-grid gap-3" style="grid-template-columns: 1fr 1fr">
        <div class="dl-tile">
          <dt>Order Total</dt>
          <dd>£{{ supplierOrder.total_net }}</dd>
        </div>
        <div class="dl-tile">
          <dt>Order Status</dt>
          <dd v-html="supplierOrder.status"></dd>
        </div>
        <div v-if="supplierOrder.supplier_order_number" class="dl-tile">
          <dt>Supplier Order Number</dt>
          <dd v-html="supplierOrder.supplier_order_number"></dd>
        </div>
      </dl>
      <table class="table mt-3 text-wrap">
        <thead>
          <tr>
            <th>Item</th>
            <th>SKU</th>
            <th class="text-end">Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in supplierOrder.items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.sku }}</td>
            <td class="text-end">{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="supplierOrder.customer_orders.length > 0" class="block-title">
        Customer Orders
      </div>
      <dl class="mt-3 d-grid gap-3" style="grid-template-columns: 1fr 1fr">
        <a
          v-for="order in supplierOrder.customer_orders"
          :key="order.id"
          :href="order.order_url"
        >
          <div class="dl-tile">
            <dt>Customer Order: #{{ order.id }}</dt>
            <dd>
              <div class="mt-1">{{ order.customer.name }}</div>
              <div>{{ order.customer.email }}</div>
              <div class="mt-2" v-html="order.status"></div>
            </dd>
          </div>
        </a>
      </dl>
    </template>
  </drawer>
</template>

<script setup>
import { nextTick, ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import drawer from "@/components/admin/utilities/drawer.vue";

const props = defineProps({
  supplierOrderData: {
    type: Object,
    default: null,
  },
  sourceUrl: {
    type: String,
    default: "",
  },
});

const isWaitingToOpen = ref(false);
const isLoading = ref(false);
const hasFailed = ref(false);
const drawerRef = ref(null);
const supplierOrder = ref(null);

const fetchData = async () => {
  if (isLoading.value || hasFailed.value) {
    return;
  }
  isLoading.value = true;
  const response = await useFetch(props.sourceUrl);
  if (!response.ok) {
    hasFailed.value = true;
    isLoading.value = false;
    return;
  }
  supplierOrder.value = (await response.json()).data;
  isLoading.value = false;
  if (isWaitingToOpen.value) {
    isWaitingToOpen.value = false;
    nextTick(() => {
      // Need to be in nextTick because the drawerRef is not yet mounted as it depends on the v-if="supplierOrder"
      openDrawer();
    });
  }
};

if (props.supplierOrderData) {
  supplierOrder.value = props.supplierOrderData;
}

const openDrawer = () => {
  if (!supplierOrder.value) {
    isWaitingToOpen.value = true;
    fetchData();
    return;
  }

  drawerRef.value?.openDrawer();
};
</script>
