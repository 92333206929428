<template>
  <dialog
    ref="dialog"
    class="drawer p-0 m-0 ms-auto border-0 fs-sm fw-normal lh-base text-start text-normal tracking-normal"
    :class="{
      'drawer-sm': size === 'sm',
      'drawer-md': size === 'md',
      'drawer-lg': size === 'lg',
      'drawer-xl': size === 'xl',
    }"
  >
    <div class="drawer-container h-100 d-flex flex-column">
      <div
        class="drawer-header bg-body-light d-flex align-items-center justify-content-between px-3"
      >
        <h5 class="my-3 mx-0 ellipsis-1">{{ title }}</h5>
        <button type="button" class="btn-close" @click="closeDrawer"></button>
      </div>
      <div
        class="drawer-body p-3 d-flex flex-column overflow-y-auto flex-fill h-100"
      >
        <div class="drawer-content overflow-y-auto flex-fill">
          <slot></slot>
        </div>
        <div v-if="$slots.actions" class="pt-3 w-100">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";

const emit = defineEmits(["closed"]);
const props = defineProps({
  static: Boolean,
  size: {
    type: String,
    default: "md",
  },
  title: {
    type: String,
    default: "Untitled Drawer",
  },
});

const dialog = ref(null);

const openDrawer = () => {
  dialog.value.showModal();
};

const closeDrawer = () => {
  // Delay dialog close to allow animation to play
  dialog.value.classList.add("hide");
  const webkitAnimationEnd = () => {
    dialog.value.classList.remove("hide");
    dialog.value.close();
    dialog.value.removeEventListener("webkitAnimationEnd", webkitAnimationEnd);
  };
  dialog.value.addEventListener(
    "webkitAnimationEnd",
    webkitAnimationEnd,
    false
  );
  emit("closed");
};

onMounted(() => {
  dialog.value.addEventListener("click", (e) => {
    if (
      props.static ||
      e.target.classList.contains("drawer-container") ||
      e.target.closest(".drawer-container")
    ) {
      return;
    }
    closeDrawer();
  });
});

defineExpose({
  openDrawer: openDrawer,
  closeDrawer: closeDrawer,
});
</script>

<style lang="scss">
.drawer {
  height: 100vh;
  max-height: 100vh;

  &:focus {
    outline: none;
  }

  &.drawer-sm {
    min-width: min(300px, 100vw);
    max-width: min(300px, 100vw);
  }

  &.drawer-md {
    min-width: min(350px, 100vw);
    max-width: min(350px, 100vw);
  }

  &.drawer-lg {
    min-width: min(475px, 100vw);
    max-width: min(475px, 100vw);
  }

  &.drawer-xl {
    min-width: min(600px, 100vw);
    max-width: min(600px, 100vw);
  }

  &[open] {
    -webkit-animation: show-drawer 0.75s ease normal;
  }

  &.hide {
    -webkit-animation: hide-drawer 0.5s ease normal;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .drawer-header {
    line-height: 1.5;

    .btn-close:focus {
      outline: none;
      box-shadow: none;
      opacity: 0.5;
    }
  }
}

@-webkit-keyframes show-drawer {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes hide-drawer {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
</style>
