<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <tableFilter
          label="Status"
          :options="statuses"
          :selected="selectedStatus"
          @change="(value, label) => filterChanged('status', value)"
        ></tableFilter>
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          :placeholder="`Search ${supplier} orders..`"
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="supplier-orders-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="supplier_order_number"
        @set-order-by="setOrder"
      >
        Supplier Order Number
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="total_net"
        @set-order-by="setOrder"
      >
        Total
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        width="15%"
        order-by="status"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="placed_at"
        class="text-center"
        @set-order-by="setOrder"
      >
        Placed
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="order in data" :key="order.id">
        <td class="text-nowrap">
          <SupplierOrderDrawer
            :source-url="order.quick_view_url"
          ></SupplierOrderDrawer>
          {{ order.id }}
        </td>
        <td>{{ order.supplier_order_number }}</td>
        <td>{{ order.total_net }}</td>
        <td class="text-center" v-html="order.status"></td>
        <td class="text-center">{{ order.placed_at }}</td>
        <td v-html="order.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import tableFilter from "@/components/admin/datatable/tableFilter.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import SupplierOrderDrawer from "@/components/admin/supplierOrders/supplierOrderDrawer.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  supplier: {
    type: String,
    default: "",
  },
  statuses: {
    type: Object,
    default: () => ({}),
  },
  selectedStatus: {
    type: [String, Number],
    default: null,
  },
});

const orderBy = ref("placed_at");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});
filterChanged("series", props.selectedStatus);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}
</script>
