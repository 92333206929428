<template>
  <div class="d-flex flex-column flex-sm-row justify-content-around gap-3">
    <div
      class="d-flex align-items-baseline justify-content-center flex-basis-100"
    >
      <FormAddress
        ref="deliveryAddressRef"
        :address="deliveryAddress"
        label="Delivery Address"
        :ajax-url="deliveryAddressRoute"
        show-reference
        allow-edit
        @address-changed="(data) => addressChanged('delivery', data)"
      />
    </div>
  </div>
  <div class="mt-4">
    <addressesList :ajax-url="deliveryAddressesRoute" :show-actions="false">
      <template #customActions="{ address }">
        <div class="text-center">
          <button
            class="btn btn-sm btn-primary"
            type="button"
            @click="selectDeliveryAddressFromList(address)"
          >
            <i class="fa fa-fw fa-truck"></i>
            Deliver Here
          </button>
        </div>
      </template>
    </addressesList>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import FormAddress from "@/components/admin/addresses/address.vue";
import addressesList from "@/components/admin/addresses/list.vue";

const emit = defineEmits(["step-value-changed"]);

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    required: true,
  },
  isInitialLoadingFinished: {
    type: Boolean,
    default: false,
  },
});

let customer = props.extras.preselected.customer?.id;
const deliveryAddress = ref({});
const deliveryAddressRef = ref(null);
const deliveryAddressRoute = ref(
  props.routes.delivery_address.replace("%customer%", customer)
);
const deliveryAddressesRoute = ref(
  props.routes.delivery_addresses.replace("%customer%", customer)
);

let stepData = reactive({ delivery: {} });
const addressChanged = function (key, data) {
  let addressData = JSON.parse(
    JSON.stringify(
      Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null && v != "")
      )
    )
  );
  delete addressData.value;
  delete addressData.actions;
  if (Object.keys(addressData).length <= 1) {
    stepData[key] = null;
  } else {
    stepData[key] = addressData;
  }
  updateParentWithValues();
};

const selectDeliveryAddressFromList = function (address) {
  deliveryAddress.value = address;
  addressChanged("delivery", address);
};

const updateParentWithValues = function () {
  emit("step-value-changed", stepData);
};

if (props.extras.preselected.deliveryAddress) {
  deliveryAddress.value = props.extras.preselected.deliveryAddress;
  addressChanged("delivery", deliveryAddress.value);
}
</script>
