<template>
  <div v-if="notifications.length" class="messages">
    <notification
      v-for="notificationData in notifications"
      :key="notificationData.id"
      :notification="notificationData"
    ></notification>
  </div>
</template>

<script setup>
import { ref } from "vue";
import notification from "@/components/admin/utilities/dynamicNotifications/notification.vue";

let notifications = ref([]);

window.addEventListener("pushNotification", (e) => {
  if (
    typeof e.detail === "object" &&
    !Array.isArray(e.detail) &&
    e.detail !== null
  ) {
    notifications.value.push(e.detail);
  }
});

window.addEventListener("clearNotifications", () => {
  notifications.value = [];
});
</script>
