<template>
  <div class="d-flex justify-content-between wizard-header position-relative">
    <div class="border-top spacer-line"></div>
    <template v-for="(step, i) in steps" :key="i">
      <div
        :role="
          i != currentStep && isAllPreviousStepsValid(i) ? 'button' : 'none'
        "
        :class="{
          'd-flex flex-column align-items-center progress-number-wrapper': true,
          active: currentStep == i,
        }"
        @click="attemptGoToStep(i)"
      >
        <div class="progress-number-inner-wrapper">
          <span
            class="d-flex align-items-center justify-content-center border border-2 p-2 progress-number"
          >
            <i
              v-if="
                isAllPreviousStepsValid(i) &&
                i != currentStep &&
                (i < currentStep || steps[i].validate())
              "
              class="fa fa-check text-flat"
            ></i>
            <span v-else>{{ i }}</span>
          </span>
        </div>
        <span class="fs-xs text-center">{{ step.label }}</span>
      </div>
    </template>
  </div>

  <div class="mt-4 mb-3">
    <div v-show="currentStep == 1">
      <SupplierStep
        :routes="routes"
        :steps-data="stepsData"
        :extras="extras"
        @step-value-changed="(data) => setStepData('supplier', data)"
      ></SupplierStep>
    </div>
    <div v-show="currentStep == 2">
      <ItemsStep
        :routes="routes"
        :steps-data="stepsData"
        :extras="extras"
        @step-value-changed="(data) => setStepData('items', data)"
      ></ItemsStep>
    </div>
    <div v-show="currentStep == 3">
      <ExtraStep
        :routes="routes"
        :steps-data="stepsData"
        :extras="extras"
        @step-value-changed="(data) => setStepData('extra', data)"
      ></ExtraStep>
    </div>
    <div v-if="currentStep == 4">
      <SummaryStep
        :routes="routes"
        :extras="extras"
        :steps-data="stepsData"
      ></SummaryStep>
    </div>
  </div>

  <div class="pb-3">
    <div class="d-flex justify-content-end gap-2">
      <button
        v-if="currentStep > 1"
        type="button"
        class="btn btn-secondary"
        @click="previousStep"
      >
        Back
      </button>
      <button
        v-if="currentStep == Object.keys(steps).length"
        ref="submitButtonRef"
        type="button"
        class="btn btn-primary"
        :disabled="!steps[currentStep].validate()"
        @click="requestComplete"
      >
        Complete
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        :disabled="!steps[currentStep].validate()"
        @click="nextStep"
      >
        Next
      </button>
    </div>
    <div class="text-end mt-2">
      <div ref="errorContainerRef"></div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, nextTick } from "vue";
import { debounce, pick } from "lodash";
import SupplierStep from "@/components/admin/supplierOrders/formWizard/supplierStep.vue";
import ItemsStep from "@/components/admin/supplierOrders/formWizard/itemsStep.vue";
import ExtraStep from "@/components/admin/supplierOrders/formWizard/extraStep.vue";
import SummaryStep from "@/components/admin/supplierOrders/formWizard/summaryStep.vue";

defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
});

let steps = {
  1: {
    label: "Supplier",
    validate: function () {
      if (!stepsData.supplier) {
        return false;
      }
      if (stepsData.supplier.supplier) {
        return true;
      }
      if (stepsData.supplier.name) {
        return true;
      }

      return false;
    },
  },
  2: {
    label: "Items",
    validate: function () {
      if (
        !stepsData.items ||
        !stepsData.items.items ||
        stepsData.items.items.filter((i) => Boolean(i.part)).length === 0
      ) {
        return false;
      }
      if (stepsData.items.is_drop_ship) {
        return (
          stepsData.items.items.filter(
            (i) =>
              i.quantity > i.original_quantity - (i.fulfilled_quantity ?? 0)
          ).length === 0
        );
      }
      return true;
    },
  },
  3: {
    label: "Extra",
    validate: function () {
      return true;
    },
  },
  4: {
    label: "Summary",
    validate: function () {
      return true;
    },
  },
};
const currentStep = ref(1);
const stepsData = reactive({});
const submitButtonRef = ref(null);
const errorContainerRef = ref(null);
const form = document.getElementById("wizard_form");

const previousStep = function () {
  currentStep.value--;
};
const nextStep = function () {
  currentStep.value++;
};
const attemptGoToStep = function (requestedStep) {
  if (requestedStep == currentStep.value) {
    return false;
  }
  if (!isAllPreviousStepsValid(requestedStep)) {
    return false;
  }
  currentStep.value = requestedStep;
};

const isAllPreviousStepsValid = function (stepNumber) {
  for (let i = 1; i < stepNumber; i++) {
    if (!steps[i].validate()) {
      return false;
    }
  }
  return true;
};

const setStepData = function (step, data) {
  stepsData[step] = data;
};

const requestComplete = function () {
  for (const [_, step] of Object.entries(steps)) {
    if (!step.validate()) {
      return false;
    }
  }

  completeWizard();
};

const completeWizard = debounce(ajaxSteps, 350);

async function ajaxSteps() {
  let data = JSON.parse(JSON.stringify(stepsData));

  data.submitter = submitButtonRef.value;
  if (data?.items?.is_drop_ship) {
    data.isDropShippingOrder = true;
    data.dropShipCustomerOrder = data.items.drop_ship_customer_order.value;
  }
  data.items = data.items.items.map((item) => {
    item = pick(
      item,
      data.isDropShippingOrder
        ? ["id", "customer_order_item_id", "quantity"]
        : [
            "id",
            "customer_order_item_id",
            "quantity",
            "reference",
            "rrp",
            "discount",
            "part.value",
          ]
    );
    if (item.part?.value) {
      item.part = item.part.value;
    }
    return item;
  });

  form.dispatchEvent(
    new CustomEvent("ajax-submit", {
      detail: data,
    })
  );
}

function getLastValidStepNumber() {
  for (let i = 1; i < Object.keys(steps).length; i++) {
    if (!steps[i].validate()) {
      return i;
    }
  }
  return Object.keys(steps).length;
}

nextTick(function () {
  currentStep.value = getLastValidStepNumber();
  form.ajaxForm({
    validationTarget: errorContainerRef.value,
  });
});
</script>
