<template>
  <tr>
    <td>
      <div class="d-flex gap-2">
        <img
          :src="item.part?.main_image || partPlaceholderImage"
          alt="Part Image"
          class="img-thumbnail thumbnail-xs object-fit-cover"
        />
        <PartName :item="item">
          <div v-if="item.is_on_hold" class="text-danger">In stock review</div>
          <div v-if="item.is_part_drop_shipped" class="text-danger">
            (This part is normally drop shipped)
          </div>
        </PartName>
      </div>
    </td>
    <td class="text-center">
      <template v-if="quantity !== item.quantity && fulfilledByList">
        <i
          class="fa fa-question-circle swal-info me-1"
          data-info-title="Line already fulfilled by"
          :data-info-html="fulfilledByList"
          role="button"
        ></i>
        <span class="text-decoration-line-through">
          {{ item.quantity }}
        </span>
      </template>
      {{ quantity }}
    </td>
    <td>{{ item.orderLabel }}</td>
    <td>
      <i
        class="fa fa-boxes-packing text-success"
        role="button"
        @click="emit('addItem')"
      ></i>
    </td>
  </tr>
</template>

<script setup>
import { computed } from "vue";
import PartName from "@/components/admin/parts/components/name.vue";

const emit = defineEmits(["addItem"]);

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const quantity = computed(() => {
  return Math.max(
    props.item.quantity - (props.item.fulfilled_quantity ?? 0),
    0
  );
});

const fulfilledByList = computed(() => {
  if (!props.item.linked_supplier_order_items?.length) {
    return false;
  }
  return props.item.linked_supplier_order_items
    .map(
      (item) => `Supplier Order #${item.supplier_order_id}: ${item.quantity}`
    )
    .join("<br/>");
});

const partPlaceholderImage = window.placeholderImage;
</script>
