<template>
  <div>
    <input
      id="attachments"
      ref="attachmentsField"
      type="hidden"
      name="attachments"
    />
    <button
      id="attachments_button"
      type="button"
      class="btn btn-outline-secondary"
      :class="{ 'btn-sm': small }"
      @click="openAttachmentsModal"
    >
      <i class="fa-solid fa-paperclip"></i>
      Attachments
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Eventbus } from "@/utilities/eventbus.js";

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
  attachments: {
    type: Array,
    default: () => [],
  },
  submitOnClose: {
    type: String,
    default: null,
  },
});

const attachmentsField = ref(null);

onMounted(() => {
  attachmentsField.value.value = JSON.stringify(props.attachments);

  Eventbus.on("manage-attachments-modal:attachments", (attachments) => {
    attachmentsField.value.value = JSON.stringify(attachments);

    // This submits even if nothing has been changed which may not be ideal.
    if (props.submitOnClose) {
      document.querySelector(props.submitOnClose).submit();
    }
  });
});

function openAttachmentsModal() {
  Eventbus.emit(
    "manage-attachments-modal:open",
    JSON.parse(attachmentsField.value.value)
  );
}
</script>
