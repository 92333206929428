<template>
  <button
    class="btn btn-outline-secondary btn-sm"
    role="button"
    :disabled="isLoading"
    @click.prevent="requestMerge"
  >
    <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
    Merge
  </button>
</template>
<script setup>
import { ref } from "vue";
import { route } from "ziggy-js";
import { useFetch } from "@/utilities/useFetch.js";

const props = defineProps({
  customerId: {
    type: Number,
    required: true,
  },
});

const isLoading = ref(false);

const requestMerge = async function () {
  isLoading.value = true;
  const res = await useFetch(
    route("admin.api.customers.request-merge", {
      customer: props.customerId,
    }),
    {
      method: "POST",
    }
  );
  const response = await res.json();
  if (response.success) {
    const handleMergeResponse = function (res) {
      if (res.success) {
        Swal.fire({
          target: document.getElementById("page-container"),
          icon: "success",
          title: "Customer merged successfully",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        }).then(() => {
          window.location.href = route("admin.customers.index");
        });
      } else {
        Swal.fire({
          target: document.getElementById("page-container"),
          title: res.title || "Unable to merge",
          text: res.message || "Something went wrong",
          icon: "warning",
        }).finally(() => {
          window.location.href = route("admin.customers.index");
        });
      }
    };
    if (response.customers) {
      Swal.fire({
        target: document.getElementById("page-container"),
        title: "Select customer",
        text: "Customer is not linked to any customer in Xero. Please select a customer to merge with. All orders, quotes and addresses will be re-assigned.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Merge",
        cancelButtonText: "Cancel",
        input: "select",
        inputOptions: response.customers,
      }).then((result) => {
        if (result.isConfirmed) {
          useFetch(
            route("admin.api.customers.merge", {
              customer: props.customerId,
            }),
            {
              method: "POST",
              body: {
                target: result.value,
              },
            }
          )
            .then((res) => res.json())
            .then((res) => {
              handleMergeResponse(res);
              isLoading.value = false;
            });
        }
      });
    } else {
      const targetCustomerUrl = route("admin.customers.show", {
        customer: response.target.id,
      });
      let html = `According to data in Xero, this customer was merged to <a href="${targetCustomerUrl}" target="_blank"><i class="fa fa-fw fa-external-link-alt fs-base"></i> ${response.target.name}</a>. All orders, quotes and addresses will be re-assigned.`;
      if (response.both_customers_exist_in_hubspot) {
        html +=
          "<br/><br/>Both customers exist in HubSpot and will be merged together. HubSpot merge <b>cannot be undone</b>.";
        if (response.this_hubspot_link && response.target_hubspot_link) {
          html += `<br/><br/><a href="${response.this_hubspot_link}" target="_blank"><i class="fa fa-fw fa-external-link-alt fs-base"></i> This HubSpot company</a> will be merged into <a href="${response.target_hubspot_link}" target="_blank"><i class="fa fa-fw fa-external-link-alt fs-base"></i> this HubSpot company</a>.`;
        }
      }
      html += `<br/><br/>Do you wish to proceed?`;
      Swal.fire({
        target: document.getElementById("page-container"),
        title: `Merge with ${response.target.name}?`,
        html: html,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Merge",
        cancelButtonText: "Cancel",
      })
        .then((result) => {
          if (result.isConfirmed) {
            useFetch(
              route("admin.api.customers.merge", {
                customer: props.customerId,
              }),
              {
                method: "POST",
                body: {
                  target: response.target.id,
                },
              }
            )
              .then((res) => res.json())
              .then((res) => {
                handleMergeResponse(res);
                isLoading.value = false;
              });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
  } else {
    const message = response.message || "Something went wrong";
    Swal.fire({
      target: document.getElementById("page-container"),
      title: "Unable to merge",
      text: message,
      icon: "warning",
    });
    isLoading.value = false;
  }
};
</script>
