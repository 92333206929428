<template>
  <div v-if="isLargeScreen" class="table-responsive">
    <table ref="table" class="table table-bordered table-vcenter">
      <thead>
        <tr>
          <th style="width: 30px"></th>
          <th>Picking</th>
          <th>Order</th>
          <th>Picked By</th>
          <th>Picked At</th>
          <th style="width: 30px"></th>
        </tr>
      </thead>
      <template v-for="(pick, index) in picks" :key="index">
        <template v-if="pick.id">
          <tbody
            :id="`picking_${pick.id}`"
            :class="{
              'js-table-sections-header': true,
              'show table-active': pick.open,
            }"
          >
            <tr @click="(e) => tableSectionHeaderClick(e, pick.id)">
              <td class="text-center">
                <i class="fa fa-angle-right text-muted"></i>
              </td>
              <td>
                <a v-if="pick.url" :href="pick.url" class="ajax-modal">
                  #{{ pick.id }}
                </a>
                <span v-else>#{{ pick.id }}</span>
              </td>
              <td>
                <span class="me-1">
                  <CustomerOrderDrawer
                    :source-url="pick.quick_view_url"
                  ></CustomerOrderDrawer>
                </span>
                <a
                  v-if="pick.pickable_url"
                  :href="pick.pickable_url"
                  target="_blank"
                >
                  #{{ pick.pickable }}
                </a>
                <span v-else>#{{ pick.pickable }}</span>
              </td>
              <td>
                {{ pick.picker }}
              </td>
              <td class="text-muted">
                {{ pick.picked_at }}
              </td>
              <td class="text-center">
                <i
                  class="fas fa-times text-danger"
                  role="button"
                  @click="emit('removeItem', index)"
                ></i>
              </td>
            </tr>
          </tbody>
          <tbody class="fs-xs">
            <tr>
              <th></th>
              <th colspan="3">Part</th>
              <th class="text-center" colspan="2">Quantity</th>
            </tr>
            <tr v-for="(item, itemIndex) in pick.items" :key="itemIndex">
              <td></td>
              <td colspan="3">
                <div class="d-flex gap-2">
                  <img
                    class="img-thumbnail thumbnail-xs object-fit-cover"
                    :src="item.image ? item.image : partPlaceholderImage"
                  />
                  <div>
                    <span v-if="item.quick_view_url" class="me-1">
                      <partDrawer
                        :source-url="item.quick_view_url"
                      ></partDrawer>
                    </span>
                    <a v-if="item.url" :href="item.url" target="_blank">
                      {{ item.part }}
                    </a>
                    <span v-else>
                      {{ item.part }}
                    </span>
                    <div v-if="item.sku" class="text-muted">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center" colspan="2">{{ item.quantity }}</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td class="text-center" colspan="9999">Loading picking...</td>
            </tr>
          </tbody>
        </template>
      </template>
    </table>
  </div>
  <div v-else ref="table" class="list-group">
    <div
      v-for="(pick, index) in picks"
      :id="`picking_${pick.id}`"
      :key="index"
      class="list-group-item"
    >
      <div v-if="pick.id">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            Picking {{ pick.id }}
            <div class="fs-sm text-muted">
              <span>Order&nbsp;</span>
              <span v-if="pick.quick_view_url" class="me-1">
                <CustomerOrderDrawer
                  :source-url="pick.quick_view_url"
                ></CustomerOrderDrawer>
              </span>
              <a
                v-if="pick.pickable_url"
                :href="pick.pickable_url"
                target="_blank"
              >
                #{{ pick.pickable }}
              </a>
              <span v-else>#{{ pick.pickable }}</span>
            </div>
          </h5>
          <div>
            <small class="text-end">{{ pick.picked_at }}</small>
            <small class="d-block text-end">{{ pick.picker }}</small>
          </div>
        </div>
        <ul class="list-unstyled mt-2 mb-0">
          <li
            v-for="(item, index2) in pick.items"
            :key="index2"
            :class="{
              'mb-2': index2 !== pick.items.length - 1,
            }"
          >
            <div class="d-flex gap-2">
              <img
                class="img-thumbnail thumbnail-xs object-fit-cover"
                :src="item.image ? item.image : partPlaceholderImage"
              />
              <div class="text-break">
                <span v-if="item.quick_view_url" class="me-1">
                  <partDrawer :source-url="item.quick_view_url"></partDrawer>
                </span>
                <a v-if="item.url" :href="item.url" target="_blank">
                  {{ item.part }}
                </a>
                <span v-else>
                  {{ item.part }}
                </span>
                <div v-if="item.sku" class="text-muted">
                  SKU: {{ item.sku }}
                </div>
              </div>
              <div
                class="flex-grow-1 text-end text-muted text-nowrap"
                style="flex-basis: 25px"
              >
                Qty: {{ item.quantity }}
              </div>
            </div>
          </li>
        </ul>
        <button
          type="button"
          class="btn btn-sm btn-danger text-nowrap mt-2"
          @click="emit('removeItem', index)"
        >
          <i class="fa fa-fw fa-trash me-2"></i>
          Remove
        </button>
      </div>
      <div v-else>Loading picking...</div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, nextTick, watch } from "vue";
import partDrawer from "@/components/admin/parts/partDrawer.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";

const emit = defineEmits(["removeItem"]);

const props = defineProps({
  selectedPickings: {
    type: Object,
    default: null,
  },
});
const isLargeScreen = window.isLargeScreen;
const picks = reactive(props.selectedPickings);
const table = ref(null);
const partPlaceholderImage = window.placeholderImage;

const tableSectionHeaderClick = function (event, pickId) {
  if (
    event.target.type !== "button" &&
    event.target.role !== "button" &&
    event.target.tagName.toLowerCase() !== "a" &&
    event.target.parentNode.nodeName.toLowerCase() !== "a" &&
    event.target.parentNode.nodeName.toLowerCase() !== "button"
  ) {
    picks[pickId]["open"] = !picks[pickId]["open"];
  }
};

watch(
  () => picks,
  (newValue) => {
    let newPickId = null;
    for (const [pickId, data] of Object.entries(newValue)) {
      if (!Object.prototype.hasOwnProperty.call(data, "open")) {
        data["open"] = true;
        newPickId = pickId;
      }
    }
    nextTick(() => {
      // Triggered to add various event listeners from admin.js (like ajaxModal)
      Eventbus.emit("datatable.updated", table.value);
      if (!isLargeScreen && newPickId) {
        // On mobile we want to scroll to the newly added picking,
        // so that the user can see the added picking
        // meanwhile on desktop everything is already visible anyways
        const pickElement = document.getElementById("picking_" + newPickId);
        if (pickElement) {
          pickElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    });
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
