<template>
  <div v-if="!strippedVersion" class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all tractors.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div class="d-flex gap-2 mt-2 justify-content-end">
        <TableFilter
          label="Status"
          :options="statuses"
          :selected="selectedStatus"
          @change="(value, label) => filterChanged('status', value)"
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="tractors-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="title"
        @set-order-by="setOrder"
      >
        Title
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="status"
        width="15%"
        class="text-center"
        @set-order-by="setOrder"
      >
        Status
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_active"
        class="text-center"
        @set-order-by="setOrder"
      >
        Active
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_scrap"
        class="text-center"
        @set-order-by="setOrder"
      >
        Scrap
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="tractor in data" :key="tractor.id">
        <td>{{ tractor.title }}</td>
        <td class="text-center" v-html="tractor.status"></td>
        <td class="text-center">
          <i
            :class="{
              'fa fa-check text-success': tractor.is_active,
              'fa fa-times': !tractor.is_active,
            }"
          ></i>
        </td>
        <td class="text-center">
          <i
            :class="{
              'fa fa-check text-success': tractor.is_scrap,
              'fa fa-times': !tractor.is_scrap,
            }"
          ></i>
        </td>
        <td v-html="tractor.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div
        v-for="tractor in data"
        :key="tractor.id"
        class="list-group-item d-flex flex-column gap-3"
      >
        <div class="d-flex justify-content-between">
          <h5 class="mb-0">{{ tractor.title }}</h5>
        </div>
        <div class="d-flex flex-column gap-1">
          <div class="d-flex align-items-center justify-content-between">
            <div>Status</div>
            <div class="text-end" v-html="tractor.status"></div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>Active</div>
            <div>
              <i
                :class="{
                  'fa fa-check text-success': tractor.is_active,
                  'fa fa-times': !tractor.is_active,
                }"
              ></i>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>Scrap</div>
            <div>
              <i
                :class="{
                  'fa fa-check text-success': tractor.is_scrap,
                  'fa fa-times': !tractor.is_scrap,
                }"
              ></i>
            </div>
          </div>
        </div>
        <div v-html="tractor.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: null,
  },
  statuses: {
    type: Object,
    default: () => ({}),
  },
  selectedStatus: {
    type: [String, Number],
    default: null,
  },
  strippedVersion: {
    type: Boolean,
    default: false,
  },
});

const orderBy = ref("title");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});
filterChanged("series", props.selectedStatus);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};

function filterChanged(key, value) {
  if (customFilter[key] == value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
}
</script>
