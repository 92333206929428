<template>
  <div class="d-flex flex-column">
    <div
      v-if="packingNoteUrl"
      class="w-100 d-flex gap-3 justify-content-center"
    >
      <a
        :href="packingNoteUrl"
        target="_blank"
        class="option-container d-flex flex-column justify-content-center align-items-center border rounded p-3 mw-100"
      >
        <i class="fa fa-print fs-1 mb-1"></i>
        <div class="text-uppercase fs-3 text-nowrap">Packing Note</div>
      </a>
    </div>

    <div v-if="stepsData.pickings.address.country_code !== 'GB'">
      <h3 class="text-center mt-5">International Invoices</h3>
      <ul class="list-group w-25 mx-auto">
        <li
          v-for="pickings in props.stepsData.pickings.pickings"
          :key="pickings.id"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          Customer Order #{{ pickings.pickable }}
          <a
            ref="invoiceLinks"
            :href="
              routes.international_invoice.replace(
                '%customer_order%',
                pickings.pickable
              )
            "
            class="btn btn-sm btn-outline-primary"
            data-bs-class="international-invoice-modal"
            data-bs-backdrop="static"
          >
            <i class="fa fa-print"></i>
            Print
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
});
const packingNoteUrl = computed(() => {
  if (!props.routes.packing_note) {
    return null;
  }
  const url = new URL(props.routes.packing_note);
  const { pickings } = props.stepsData;
  if (!pickings || !pickings.pickings) {
    return null;
  }
  const pickingIds = Object.keys(pickings.pickings);
  if (!pickingIds.length) {
    return null;
  }

  const search = new URLSearchParams(
    pickingIds.map((id) => ["pickings[]", id])
  );
  url.search = search.toString();

  return url.toString();
});
const invoiceLinks = ref(null);

onMounted(() => {
  if (invoiceLinks.value) {
    invoiceLinks.value.forEach((link) => {
      link.ajaxModal();
    });
  }
});
</script>

<style lang="scss" scoped>
.option-container {
  color: #36474d;
  transition: all 0.2s;
  width: 300px;
  aspect-ratio: 1/1;
  scale: 1;

  &.disabled {
    opacity: 0.5;
  }
  &:not(.disabled):hover {
    color: #069fba;
    box-shadow: 0 0 1rem 3px rgba(6, 159, 186, 0.3);
    scale: 1.01;
    cursor: pointer;
  }
}
</style>
