<template>
  <div class="w-100 d-flex flex-column gap-1">
    <div class="form-select-wrapper">
      <input
        ref="hiddenInput"
        type="hidden"
        :name="name"
        :data-error-target="errorTarget"
        :value="inputValue"
      />
      <VSelect
        :id="name"
        ref="vselect"
        v-model="selected"
        :label="label"
        :options="options"
        :class="{
          'with-image': withImage,
        }"
        class="w-100"
        append-to-body
        filterable
        :calculate-position="withPopper"
        :placeholder="placeholder"
        :clearable="clearable"
        :filter-by="filterBy"
        :taggable="taggable"
        :disabled="disabled"
        :multiple="multiple"
        @update:model-value="valueChanged"
        @search="search"
        @search:focus="searchFocus"
        @search:blur="searchBlur"
      >
        <template #no-options>
          <slot name="no-options">Type to search...</slot>
        </template>
        <template #option="option">
          <slot name="option" :option="option"></slot>
        </template>
        <template #selected-option="option">
          <slot name="selected-option" :option="option"></slot>
        </template>
      </VSelect>
      <p v-if="helperText" class="m-0 text-muted" v-html="helperText"></p>
    </div>
  </div>
</template>

<script setup>
import VSelect from "vue-select";
import { ref, nextTick, isProxy, toRaw, watch } from "vue";
import { debounce } from "lodash";
import { createPopper } from "@popperjs/core";
import { useFetch } from "@/utilities/useFetch.js";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  value: {
    type: [Object, Number],
    default: null,
  },
  modelValue: {
    type: [Object, Number, String],
    default: null,
  },
  label: {
    type: String,
    default: "label",
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "",
  },
  multiple: Boolean,
  withImage: Boolean,
  disabled: Boolean,
  taggable: Boolean,
  clearable: Boolean,
  preloadOptions: Boolean,
  forceWidth: {
    type: String,
    default: null,
  },
  helperText: {
    type: String,
    default: "",
  },
  url: {
    type: String,
    default: null,
  },
  noOptionsTemplate: {
    type: String,
    default: "",
  },
  minDropdownWidth: {
    type: Number,
    default: 0,
  },
  searchCallback: {
    type: Function,
    default: null,
  },
  errorTarget: {
    type: String,
    default: ".form-select-wrapper",
  },
});

const options = ref(props.options);
watch(
  () => props.options,
  () => (options.value = props.options)
);

const selected = ref(props.modelValue ? props.modelValue : props.value);
const inputValue = ref("");
const hiddenInput = ref(null);
const vselect = ref(null);

if (selected.value) {
  valueChanged(selected.value);
}

watch(
  () => props.modelValue,
  () => {
    if (selected.value === props.modelValue) {
      return;
    }
    selected.value = props.modelValue;
    valueChanged(selected.value);
  }
);

function valueChanged(value) {
  if (
    value !== null &&
    typeof value === "object" &&
    Object.keys(value).length === 1 &&
    Object.keys(value).includes("label")
  ) {
    value = value.label;
  }
  emit("update:modelValue", value);

  if (isProxy(value)) {
    value = toRaw(value);
  }

  if (typeof value === "object" && !Array.isArray(value) && value !== null) {
    value = value.value;
  }

  if (Array.isArray(value)) {
    value = value.map((v) => v.value).join(",");
  }

  inputValue.value = value;

  if (hiddenInput.value) {
    const event = new CustomEvent("form-select-change", {
      detail: {
        name: props.name,
        newValue: inputValue.value,
      },
    });

    nextTick(() => {
      document.body.dispatchEvent(event);
      // There's a chance that we've got destroyed in last tick.
      // Would happen if this element (or parent element) is wrapped in v-if.
      if (hiddenInput.value) {
        hiddenInput.value.dispatchEvent(new Event("change"));
      }
    });
  }
}

const search = debounce(props.searchCallback || ajaxSearch, 350);

let fetchAbort, fetchAbortSignal, isOptionPreloaded;

async function fetchResults(searchTerm) {
  options.value = [];

  // Abort the old one if there was one.
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // Reinitialise the abort controller for each new request.
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  let urlToFetch = new URL(props.url);
  if (searchTerm) {
    urlToFetch.searchParams.set("search", searchTerm);
  }

  const res = await useFetch(urlToFetch, { signal: fetchAbortSignal });

  let response = await res.json();

  options.value = response.data;
  if (props.preloadOptions) {
    isOptionPreloaded = true;
  }
}

async function ajaxSearch(searchTerm) {
  if (props.preloadOptions && isOptionPreloaded) {
    return;
  }

  if (props.url === null || (!props.preloadOptions && !searchTerm)) {
    return;
  }

  await fetchResults(searchTerm);
}

if (props.preloadOptions) {
  search();
}

const filterBy = function (_, label, search) {
  // If props.searchCallback or props.url provided then all filtering needs to be handled on server side.
  if (props.searchCallback || (props.url !== null && !props.preloadOptions)) {
    return true;
  }
  return (
    (label || "").toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
  );
};

const withPopper = function (dropdownList, component, { width }) {
  let suggestedWidth = parseInt(width, 10);
  let preferredWidth = dropdownList.offsetWidth;

  // Page gutters
  let gutters = 76;
  if (window.innerWidth < 768) {
    gutters = 56;
  }
  let finalWidth = Math.min(
    Math.max(suggestedWidth, preferredWidth, props.minDropdownWidth),
    document.documentElement.clientWidth - gutters
  );
  dropdownList.style.width = finalWidth + "px";

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1px.
   */
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle(
            "drop-up",
            state.placement === "top" ||
              state.placement === "top-start" ||
              state.placement === "top-end"
          );
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy();
};

// Focus & Blur to prevent vselect changing in size when focusing a search box.
const searchFocus = function () {
  vselect.value.$refs.toggle.style.minWidth =
    vselect.value.$refs.toggle.offsetWidth + "px";
};

const searchBlur = function () {
  vselect.value.$refs.toggle.style.minWidth = null;
};

defineExpose({
  fetchResults,
});
</script>
