<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all kits.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="kits-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="sku"
        @set-order-by="setOrder"
      >
        SKU
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="is_active"
        class="text-center"
        @set-order-by="setOrder"
      >
        Active
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="stock"
        class="text-center"
        @set-order-by="setOrder"
      >
        Stock
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="retail_price"
        @set-order-by="setOrder"
      >
        Price
      </SortableTh>
      <th>Components</th>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="kit in data" :key="kit.id">
        <td>{{ kit.name }}</td>
        <td>{{ kit.sku }}</td>
        <td class="text-center">
          <template v-if="kit.is_active">
            <i class="fa fa-check text-success"></i>
          </template>
          <template v-else>
            <i class="fa fa-times"></i>
          </template>
        </td>
        <td class="text-center" v-html="kit.stock"></td>
        <td>{{ kit.retail_price }}</td>
        <td>
          <ul class="m-0 ps-3">
            <template v-if="kit.components.length > 5">
              <li
                v-for="kitComponent in kit.components.slice(0, 4)"
                :key="kitComponent.id"
              >
                {{ kitComponent }}
              </li>
              <li>{{ kit.components.length - 4 }} more...</li>
            </template>
            <template v-else>
              <li v-for="kitComponent in kit.components" :key="kitComponent.id">
                {{ kitComponent }}
              </li>
            </template>
          </ul>
        </td>
        <td v-html="kit.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
});

const orderBy = ref("name");
const orderAsc = ref(true);
const limit = ref(50);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
