<template>
  <a class="btn btn-outline-primary btn-sm mb-2" @click="showModal()">
    <i class="fas fa-file-invoice"></i>
    Link to HubSpot
  </a>
  <Modal ref="modal">
    <template #title>Link to deal</template>

    <div>
      <p>Select deal in HubSpot to link this order to</p>
      <FormSelect
        v-model="selectedDeal"
        class="mb-4"
        :options="deals"
        placeholder="Search by HubSpot name"
      ></FormSelect>
    </div>

    <template #footer>
      <div class="d-flex gap-3 justify-content-end">
        <button type="button" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="link()">
          Link
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch, useTemplateRef } from "vue";
import Modal from "@/components/admin/utilities/Modal.vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import { useFetch } from "@/utilities/useFetch.js";

const modal = useTemplateRef("modal");
const firstLoad = ref(true);
const deals = ref([]);
const selectedDeal = ref(null);
const emit = defineEmits(["linkDeal"]);
const error = ref(null);
const props = defineProps({
  sendUpdateRequest: {
    type: Boolean,
    default: false,
  },
  updateRoute: {
    type: String,
    default: null,
  },
  dealsRoute: {
    type: String,
    default: null,
  },
  orderId: {
    type: Number,
    default: null,
  },
});

watch(
  () => firstLoad.value,
  () => {
    if (!firstLoad.value) {
      fetchDeals();
    }
  }
);

const fetchDeals = async () => {
  const response = await useFetch(props.dealsRoute);
  const data = await response.json();

  deals.value = data;
};

const showModal = () => {
  modal.value.bsModal.show();
  firstLoad.value = false;
};

const closeModal = () => {
  modal.value.bsModal.hide();
};

const link = () => {
  if (props.sendUpdateRequest) {
    linkDeal();
  } else {
    emit("linkDeal", selectedDeal.value);
  }

  closeModal();
};

const cancel = () => {
  selectedDeal.value = null;
  closeModal();
};

const linkDeal = async () => {
  const response = await useFetch(props.updateRoute, {
    method: "POST",
    body: {
      hubspot_id: selectedDeal.value.id,
      order_id: props.orderId,
    },
  });

  const data = await response.json();

  if (data.error) {
    error.value = data.error;
  }

  window.location.reload();
};
</script>
