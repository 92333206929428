<template>
  <i
    class="text-primary"
    :class="{
      'fa fa-magnifying-glass': !isWaitingToOpen,
      'fa fa-spin fa-spinner': isWaitingToOpen,
    }"
    role="button"
    @click="openDrawer"
  ></i>
  <drawer
    v-if="part"
    ref="drawerRef"
    :title="part.name"
    size="lg"
    @closed="part = null"
  >
    <template #default>
      <div class="d-flex gap-3">
        <img
          class="img-thumbnail thumbnail-md object-fit-cover"
          :src="part.main_image ? part.main_image : partPlaceholderImage"
        />
        <div>
          <div>{{ part.sku }}</div>
          <div v-if="part.description" class="text-muted ellipsis-2">
            {{ part.description }}
          </div>
        </div>
      </div>
      <dl class="mt-3 d-grid gap-3" style="grid-template-columns: 1fr 1fr">
        <div class="dl-tile">
          <dt>Retail Price</dt>
          <dd>£{{ priceFormat(part.retail_price) }}</dd>
        </div>
        <div class="dl-tile">
          <dt>Stock</dt>
          <dd
            :class="{
              'text-success': part.final_stock > 0,
              'text-danger': part.final_stock <= 0,
            }"
          >
            {{ part.free_stock }}
            <template v-if="part.unallocated_stock !== 0">
              ({{ part.unallocated_stock }})
            </template>
          </dd>
        </div>
        <div class="dl-tile">
          <dt>Status</dt>
          <dd>
            <span
              v-if="part.is_active"
              class="fs-xs fw-semibold px-2 d-inline-block rounded-pill text-nowrap text-bg-success"
            >
              Active
            </span>
            <span
              v-else
              class="fs-xs fw-semibold px-2 d-inline-block rounded-pill text-nowrap text-bg-danger"
            >
              Disabled
            </span>
          </dd>
        </div>
        <div class="dl-tile">
          <dt>Availability Status</dt>
          <dd>
            <template v-if="part.is_order_on_request">
              Order on request
            </template>
            <template v-else-if="part.is_drop_shipped">Drop shipped</template>
            <template v-else>Stocked in warehouse</template>
          </dd>
        </div>
        <div class="dl-tile">
          <dt>Category</dt>
          <dd>{{ part.landing_category }}</dd>
        </div>
        <div class="dl-tile">
          <dt>Delivery Band</dt>
          <dd>{{ part.delivery_band ? part.delivery_band : "-" }}</dd>
        </div>
        <div
          v-if="part.equivalent_parts"
          class="dl-tile"
          style="grid-column: 1/3"
        >
          <dt>Equivalent Parts</dt>
          <dd>{{ part.equivalent_parts }}</dd>
        </div>
        <div
          v-if="part.private_equivalent_parts"
          class="dl-tile"
          style="grid-column: 1/3"
        >
          <dt>Private Equivalent Parts</dt>
          <dd>{{ part.private_equivalent_parts }}</dd>
        </div>
        <div
          v-if="part.secondary_private_equivalent_parts"
          class="dl-tile"
          style="grid-column: 1/3"
        >
          <dt>Secondary Private Equivalent Parts</dt>
          <dd>{{ part.secondary_private_equivalent_parts }}</dd>
        </div>
      </dl>
    </template>
  </drawer>
</template>

<script setup>
import { ref, nextTick } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import { priceFormat } from "@/utilities/priceFormat.js";
import drawer from "@/components/admin/utilities/drawer.vue";

const partPlaceholderImage = window.placeholderImage;

const props = defineProps({
  partData: {
    type: Object,
    default: null,
  },
  sourceUrl: {
    type: String,
    default: null,
  },
});

const isWaitingToOpen = ref(false);
const isLoading = ref(false);
const hasFailed = ref(false);
const drawerRef = ref(null);
const part = ref(null);

const fetchData = async () => {
  if (isLoading.value || hasFailed.value) {
    return;
  }
  isLoading.value = true;
  const response = await useFetch(props.sourceUrl);
  if (!response.ok) {
    hasFailed.value = true;
    isLoading.value = false;
    return;
  }
  const partObject = (await response.json()).data;
  partObject.final_stock =
    parseInt(partObject.free_stock) + parseInt(partObject.unallocated_stock);
  part.value = partObject;
  isLoading.value = false;
  if (isWaitingToOpen.value) {
    isWaitingToOpen.value = false;
    nextTick(() => {
      // Need to be in nextTick because the drawerRef is not yet mounted as it depends on the v-if="part"
      openDrawer();
    });
  }
};

if (props.partData) {
  part.value = props.partData;
}

const openDrawer = () => {
  if (!part.value) {
    isWaitingToOpen.value = true;
    fetchData();
    return;
  }

  drawerRef.value?.openDrawer();
};
</script>
