<template>
  <a class="btn btn-sm btn-primary" @click="showModal()">
    <i class="fas fa-user-plus"></i>
    Import from HubSpot
  </a>
  <Modal ref="modal">
    <template #title>Import Customer</template>

    <div>
      <div
        v-if="error"
        class="alert alert-danger d-flex justify-content-between align-items-center"
      >
        <span>{{ error }}</span>
        <a
          v-if="viewCustomerLink"
          :href="viewCustomerLink"
          target="_blank"
          class="btn btn-sm btn-primary"
        >
          View Customer
        </a>
      </div>
      <p>Select Customer in HubSpot to import</p>
      <FormSelect
        v-model="selectedCustomer"
        class="mb-4"
        :url="companiesRoute"
        placeholder="Search by HubSpot name"
      ></FormSelect>
    </div>

    <template #footer>
      <div class="d-flex gap-3 justify-content-end">
        <button type="button" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="importCustomer()">
          Import
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";
import Modal from "@/components/admin/utilities/Modal.vue";
import FormSelect from "@/components/admin/utilities/formSelect.vue";
import { useFetch } from "@/utilities/useFetch.js";

const modal = ref(null);
const selectedCustomer = ref(null);
const searchTerm = ref(null);
const error = ref(null);
const viewCustomerLink = ref(null);

const props = defineProps({
  companiesRoute: {
    type: String,
    required: true,
  },
  importRoute: {
    type: String,
    required: true,
  },
});

const showModal = () => {
  modal.value.bsModal.show();
};

const closeModal = () => {
  modal.value.bsModal.hide();
};

const cancel = () => {
  selectedCustomer.value = null;
  searchTerm.value = "";
  error.value = null;
  closeModal();
};

const importCustomer = async () => {
  if (!selectedCustomer.value) return;

  const response = await useFetch(props.importRoute, {
    method: "POST",
    body: { hubspot_id: selectedCustomer.value.value },
  });

  const data = await response.json();

  if (data.error) {
    error.value = data.error;
    viewCustomerLink.value = data.profile_url;
    selectedCustomer.value = null;
  } else if (data.success) {
    window.location.href = data.redirect_url;
  }
};
</script>
