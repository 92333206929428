<template>
  <div class="form-group mt-2">
    <input
      type="text"
      class="form-control"
      :class="{ 'is-invalid': !isSkuValid }"
      placeholder="New part SKU"
      :value="model"
      @input="checkSku($event.target.value)"
    />
    <span v-if="!isSkuValid" class="invalid-feedback" role="alert">
      SKU is already in use.
    </span>
  </div>
</template>

<script setup>
import { debounce } from "lodash";
import { useVModel } from "@vueuse/core";
import { ref } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import { Eventbus } from "@/utilities/eventbus.js";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  skuCheckUrl: {
    type: String,
    required: true,
  },
  itemSkus: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String,
    default: "",
  },
});

const model = useVModel(props, "modelValue", emit);
const isSkuValid = ref(true);

const checkSku = debounce(async (sku) => {
  if (!sku) {
    model.value = "";
    isSkuValid.value = true;
    return;
  }

  if (props.itemSkus.includes(sku)) {
    _invalidSku(sku, false);
    return;
  }

  const response = await useFetch(props.skuCheckUrl, {
    method: "POST",
    body: { sku },
  });
  const data = await response.json();

  if (!data.valid) {
    _invalidSku(sku);
    return;
  }

  _validSku(sku);
}, 500);

const _validSku = (sku) => {
  if (model.value !== "" && model.value !== sku) {
    // Invalidate any previous SKU first
    Eventbus.emit("skuInvalid", model.value);
  }
  model.value = sku;
  isSkuValid.value = true;
  Eventbus.emit("skuValid", sku);
};

const _invalidSku = (sku, triggerEvent = true) => {
  if (model.value !== "" && model.value !== sku) {
    // Invalidate any previous SKU first
    Eventbus.emit("skuInvalid", model.value);
  }
  model.value = "";
  isSkuValid.value = false;
  if (triggerEvent) {
    Eventbus.emit("skuInvalid", sku);
  }
};

if (model) {
  checkSku(model.value);
}
</script>

<style scoped lang="scss"></style>
