<template>
  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    refresh-on="quotes-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <th>Part</th>
      <th>Customer</th>
      <th v-if="columns.includes('actions')"></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="quoteItem in data" :key="quoteItem.id">
        <td class="text-nowrap">
          <QuotesDrawer
            v-if="quoteItem.quote.quick_view_url"
            :source-url="quoteItem.quote.quick_view_url"
          />
          {{ quoteItem.quote.id }}
        </td>
        <td>{{ quoteItem.part }}</td>
        <td>{{ quoteItem.quote.customer }}</td>
        <td v-html="quoteItem.actions"></td>
      </tr>
    </template>
  </Datatable>
</template>

<script setup>
import { ref } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import QuotesDrawer from "@/components/admin/quotes/quotesDrawer.vue";

defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  statuses: {
    type: Object,
    default: () => ({}),
  },
  columns: {
    type: Array,
    default: () => [],
  },
});

const orderBy = ref("id");
const orderAsc = ref(false);
const limit = ref(10);
const searchTerm = ref(null);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
