<template>
  <div :class="wrapperClass">
    <label
      :for="id"
      :class="{
        'control-label': true,
        required: required,
      }"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      v-model="_value"
      class="form-control"
      type="text"
      :required="required"
      :disabled="disabled"
      :name="inputName"
      @input="validate"
    />
    <template v-if="showValidation">
      <div v-if="validationProgress === -1 && errorMessage" class="text-danger">
        {{ errorMessage }}
      </div>
      <div v-else-if="validationProgress === 0" class="text-muted">
        <i class="fa fa-spin fa-spinner"></i>
        Checking availability...
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { debounce } from "lodash";
import { useFetch } from "@/utilities/useFetch.js";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  inputName: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showValidation: {
    type: Boolean,
    default: true,
  },
  excludeId: {
    type: [Number, String],
    default: null,
  },
  validationUrl: {
    type: String,
    default: "",
  },
  validationRegex: {
    type: String,
    default: "",
  },
  validationFailedMessage: {
    type: String,
    default: "The input is invalid.",
  },
  wrapperClass: {
    type: String,
    default: "mb-4 form-group",
  },
});

// -1: Invalid, 0: In progress, 1: Valid
const validationProgress = ref(1);
const errorMessage = ref(null);
const _value = ref(props.modelValue);
const id = "live_input_" + Math.floor(Math.random() * Date.now()).toString(36);
const regexValidation = computed(() => {
  if (props.validationRegex) {
    return new RegExp(props.validationRegex);
  }
  return null;
});

const requestAjaxValidation = debounce(ajaxValidate, 300);
let fetchAbort, fetchAbortSignal;
async function ajaxValidate(input) {
  if (!props.validationUrl) {
    endValidation();
    return;
  }

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  let url = new URL(props.validationUrl);
  url.searchParams.set("value", input.target.value);
  if (props.excludeId) {
    url.searchParams.set("exclude", props.excludeId);
  }
  const res = await useFetch(url, { signal: fetchAbortSignal });

  let response = await res.json();
  endValidation(
    response.valid
      ? null
      : response.message ||
          props.validationFailedMessage ||
          "The input is invalid."
  );
}

function validate(input) {
  startValidation();
  if (!input.target.value) {
    endValidation();
    return;
  }

  // Very basic check to see if it's an email
  if (
    regexValidation.value &&
    !regexValidation.value.test(input.target.value)
  ) {
    endValidation(props.validationFailedMessage);
    return;
  }
  requestAjaxValidation(input);
}

function startValidation() {
  validationProgress.value = 0;
  errorMessage.value = null;
}
function endValidation(error = null) {
  if (error == null) {
    validationProgress.value = 1;
    emit("update:modelValue", _value.value);
  } else {
    validationProgress.value = -1;
    errorMessage.value = error;
  }
}
</script>
