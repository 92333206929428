<template>
  <tr>
    <td style="max-width: 200px">
      <div v-if="fromOrder" class="d-flex gap-2">
        <img
          :src="part?.main_image || partPlaceholderImage"
          alt="Part Image"
          class="img-thumbnail thumbnail-xs object-fit-cover"
        />
        <PartName :item="{ part: part }">
          <div class="text-muted">Customer Order: #{{ fromOrder }}</div>
          <div v-if="isNormallyDropShipped" class="text-danger">
            (This part is normally drop shipped)
          </div>
        </PartName>
      </div>
      <PartFormSelect
        v-else
        v-model="part"
        only-existing
        @update:model-value="updateRrp"
      />
    </td>
    <td>
      <input v-model="reference" type="text" class="form-control" />
    </td>
    <td class="text-center quantity">
      <input
        v-model.number="quantity"
        class="form-control text-center m-auto qty-input"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        placeholder="1"
      />
    </td>
    <td class="text-center">
      <div class="input-group">
        <span class="input-group-text">
          <i class="fa fa-gbp"></i>
        </span>
        <input
          v-model.number="rrp"
          class="form-control"
          type="text"
          inputmode="numeric"
          pattern="\d+(\.\d{1,2})?"
          placeholder="0.00"
        />
      </div>
    </td>
    <td class="text-center">
      <div class="input-group">
        <input
          v-model.number="discount"
          class="form-control"
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          placeholder="0"
        />
        <span class="input-group-text">
          <i class="fa fa-percent"></i>
        </span>
      </div>
    </td>
    <td class="text-center">
      <i
        class="fas fa-times text-danger"
        role="button"
        @click="emit('removeItem')"
      ></i>
    </td>
  </tr>
</template>

<script setup>
import PartFormSelect from "@/components/admin/utilities/partFormSelect.vue";
import PartName from "@/components/admin/parts/components/name.vue";

const emit = defineEmits(["removeItem"]);
defineProps({
  fromOrder: {
    type: Number,
    default: null,
  },
  isNormallyDropShipped: {
    type: Boolean,
    default: false,
  },
});

const part = defineModel("part", { type: Object });
const quantity = defineModel("quantity", { type: Number });
const reference = defineModel("reference", { type: String });
const rrp = defineModel("rrp", { type: Number });
const discount = defineModel("discount", { type: Number });

const partPlaceholderImage = window.placeholderImage;

const updateRrp = (_part) => {
  if (_part && typeof _part === "object") {
    rrp.value = _part.retail_price;
  }
};
</script>
