export const addressFormat = (
  addressObj,
  includeReference = false,
  separator = "<br/>"
) => {
  addressObj = Object.assign({}, addressObj, {
    country:
      typeof addressObj.country === "object"
        ? addressObj.country?.label
        : addressObj.country,
  });

  let header = "";
  if (includeReference) {
    if (addressObj.is_default) {
      header += "[DEFAULT]";
    }
    if (addressObj.reference) {
      header += " " + addressObj.reference;
    }
  }

  let base = header
    ? `<div class="text-muted fst-italic">${header.trim()}</div>`
    : "";
  let attributes = [
    "line_1",
    "line_2",
    "line_3",
    "line_4",
    "town",
    "county",
    "postcode",
    "country",
  ];

  return (
    base +
    Object.entries(addressObj)
      // Filter out all empty and meta fields from address object
      .filter(([key, val]) => attributes.includes(key) && val)
      // Sort fields to match attributes order
      .sort((a, b) => attributes.indexOf(a[0]) - attributes.indexOf(b[0]))
      // Map to get values
      .map((x) => x[1])
      // Finally join to a final string
      .join(separator)
  );
};
