<template>
  <div class="d-flex justify-content-center my-4">
    <!-- Pay on account card -->
    <div
      class="d-flex flex-column justify-content-center align-items-center border rounded payment-option-container"
      :class="{
        active: selectedPaymentMethod === accountGatewayType,
        inactive:
          selectedPaymentMethod != null &&
          selectedPaymentMethod !== accountGatewayType,
        'me-3': selectedPaymentMethod === null,
        'me-0': selectedPaymentMethod != null,
        disabled: !_hasEnoughAccountFunds || availableCredit === -Infinity,
      }"
    >
      <div
        v-if="
          selectedPaymentMethod === null ||
          selectedPaymentMethod === accountGatewayType
        "
        class="p-3 w-100"
      >
        <!-- Select screen -->
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <i class="fa fa-file-invoice-dollar fs-1 mb-1"></i>
          <template v-if="offlinePaymentMode === 'account'">
            <div class="text-uppercase fs-3 text-nowrap">Pay on account</div>
            <div
              v-if="availableCredit < Infinity"
              class="d-flex flex-column align-items-center text-muted"
            >
              <span v-if="availableCredit === -Infinity">
                Getting available credit...
              </span>
              <span v-else>
                Available Credit: {{ paymentCurrencySymbol
                }}{{ priceFormat(availableCredit.toFixed(2)) }}
              </span>
              <span v-if="overdueCredit" class="text-danger">
                ({{ paymentCurrencySymbol
                }}{{ priceFormat(overdueCredit.toFixed(2)) }}
                overdue)
              </span>
            </div>
          </template>
          <div v-else class="text-uppercase fs-3 text-nowrap">Pay by BACS</div>
        </div>
        <!-- Actual card content -->
        <template v-if="selectedPaymentMethod === accountGatewayType">
          <div class="payment-option-content mt-2 w-100">
            <div class="d-flex flex-column align-items-center">
              <span>
                {{ payableLabel }} Value: {{ paymentCurrencySymbol
                }}{{ priceFormat(paymentValue.toFixed(2)) }}
              </span>
              <span
                v-if="
                  offlinePaymentMode === 'account' && availableCredit < Infinity
                "
              >
                Remaining Credit: {{ paymentCurrencySymbol
                }}{{ priceFormat(remainingCredit.toFixed(2)) }}
              </span>
            </div>
            <div class="d-flex flex-column align-items-center gap-1 mt-4">
              <button
                type="button"
                class="btn btn-primary"
                @click="payWithAccount"
                v-html="
                  offlinePaymentMode === 'account'
                    ? 'Raise Invoice On Account'
                    : 'Raise Invoice For BACS'
                "
              ></button>
            </div>
          </div>
          <a
            class="back-button position-absolute text-muted"
            role="button"
            @click="back"
          >
            <i class="fa fa-arrow-left"></i>
            Back
          </a>
        </template>
      </div>
      <div
        v-if="selectedPaymentMethod !== accountGatewayType"
        class="position-absolute start-0 top-0 w-100 h-100"
        @click="
          _hasEnoughAccountFunds ? selectGateway(accountGatewayType) : null
        "
      />
    </div>
    <!-- Stripe MOTO -->
    <div
      v-if="isCardPaymentAvailable"
      class="d-flex flex-column justify-content-center align-items-center border rounded payment-option-container"
      :class="{
        active: selectedPaymentMethod === cardMotoGatewayType,
        inactive:
          selectedPaymentMethod != null &&
          selectedPaymentMethod !== cardMotoGatewayType,
        'ms-3': selectedPaymentMethod === null,
        'ms-0': selectedPaymentMethod != null,
      }"
    >
      <div
        v-if="
          selectedPaymentMethod === null ||
          selectedPaymentMethod === cardMotoGatewayType
        "
        class="p-3 w-100"
      >
        <!-- Select screen -->
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <i class="fa fa-phone fs-1 mb-1"></i>
          <div class="text-uppercase fs-3 text-nowrap">Pay over phone</div>
        </div>
        <!-- Actual card content -->
        <template v-if="selectedPaymentMethod === cardMotoGatewayType">
          <div class="payment-option-content w-100">
            <div class="d-flex flex-column align-items-center">
              <span>
                {{ payableLabel }}:
                <span class="fw-semibold">
                  {{ paymentCurrencySymbol
                  }}{{ priceFormat(paymentValue.toFixed(2)) }}
                </span>
              </span>
            </div>
            <div class="d-flex justify-content-center w-100 mt-3">
              <div
                id="card-element"
                class="border rounded p-2"
                style="flex: 0 1 400px"
              ></div>
            </div>
            <div class="d-flex flex-column align-items-center gap-1">
              <div ref="cardErrorRef" class="mt-2 text-danger"></div>
              <button
                type="button"
                class="btn btn-primary mt-2"
                :disabled="disablePayWithCardButton"
                @click="payWithCard"
              >
                Take Payment
              </button>
            </div>
          </div>
          <a
            class="back-button position-absolute text-muted"
            role="button"
            @click="back"
          >
            <i class="fa fa-arrow-left"></i>
            Back
          </a>
        </template>
      </div>
      <div
        v-if="selectedPaymentMethod !== cardMotoGatewayType"
        class="position-absolute start-0 top-0 w-100 h-100"
        @click="selectGateway(cardMotoGatewayType)"
      />
    </div>
    <!-- Stripe Checkout (payment link) -->
    <div
      v-if="isCardPaymentAvailable"
      class="d-flex flex-column justify-content-center align-items-center border rounded payment-option-container"
      :class="{
        active: selectedPaymentMethod === cardLinkGatewayType,
        inactive:
          selectedPaymentMethod != null &&
          selectedPaymentMethod !== cardLinkGatewayType,
        disabled: !email,
        'ms-3': selectedPaymentMethod === null,
        'ms-0': selectedPaymentMethod != null,
      }"
    >
      <div
        v-if="
          selectedPaymentMethod === null ||
          selectedPaymentMethod === cardLinkGatewayType
        "
        class="p-3 w-100"
      >
        <!-- Select screen -->
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <i class="fa fa-link fs-1 mb-1"></i>
          <div class="text-uppercase fs-3 text-nowrap">Send Payment Link</div>
          <small v-if="!email">Customer has no email</small>
        </div>
        <!-- Actual card content -->
        <template v-if="selectedPaymentMethod === cardLinkGatewayType">
          <div class="payment-option-content w-100">
            <div class="d-flex flex-column align-items-center">
              <span v-if="email">
                Email:
                <span class="fw-semibold">{{ email }}</span>
              </span>
              <span>
                {{ payableLabel }}:
                <span class="fw-semibold">
                  {{ paymentCurrencySymbol
                  }}{{ priceFormat(paymentValue.toFixed(2)) }}
                </span>
              </span>
            </div>

            <div class="d-flex flex-column align-items-center gap-1">
              <button
                type="button"
                class="btn btn-primary mt-2"
                @click="sendPaymentLink"
              >
                Send Link
              </button>
            </div>
          </div>
          <a
            class="back-button position-absolute text-muted"
            role="button"
            @click="back"
          >
            <i class="fa fa-arrow-left"></i>
            Back
          </a>
        </template>
      </div>
      <div
        v-if="selectedPaymentMethod !== cardLinkGatewayType"
        class="position-absolute start-0 top-0 w-100 h-100"
        @click="selectGateway(cardLinkGatewayType)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import { useFetch } from "@/utilities/useFetch.js";
import { priceFormat } from "@/utilities/priceFormat.js";

const props = defineProps({
  isAccountAvailable: {
    type: Boolean,
    default: true,
  },
  hasEnoughAccountFunds: {
    type: Boolean,
    default: false,
  },
  isCardPaymentAvailable: {
    type: Boolean,
    default: false,
  },
  // availableCredit: Number,
  // overdueCredit: Number,
  // remainingCredit: Number,
  paymentValue: {
    type: Number,
    default: 0,
  },
  paymentCurrencySymbol: {
    type: String,
    default: "£",
  },
  payableLabel: {
    type: String,
    default: "",
  },
  paymentUrl: {
    type: String,
    required: true,
  },
  paymentMeta: {
    type: Object,
    default: () => ({}),
  },
  stripePublishableKey: {
    type: String,
    default: "",
  },
  accountGatewayType: {
    type: String,
    required: true,
  },
  bacsGatewayType: {
    type: String,
    required: true,
  },
  cardMotoGatewayType: {
    type: String,
    required: true,
  },
  cardLinkGatewayType: {
    type: String,
    required: true,
  },
  billingAddress: {
    type: Object,
    default: () => ({}),
  },
  email: {
    type: String,
    default: "",
  },
  creditUrl: {
    type: String,
    default: "",
  },
});

const offlinePaymentMode = ref(props.isAccountAvailable ? "account" : "BACS");
const selectedPaymentMethod = ref(null);
const disablePayWithCardButton = ref(true);
const cardErrorRef = ref(null);
const _hasEnoughAccountFunds = ref(
  offlinePaymentMode.value === "account" ? props.hasEnoughAccountFunds : true
);
const availableCredit = ref(-Infinity);
const overdueCredit = ref(null);
const remainingCredit = ref(null);

let stripeBillingDetails = props.billingAddress
  ? {
      address: {
        line1: props.billingAddress.line_1,
        line2: props.billingAddress.line_2,
        city: props.billingAddress.town,
        state: props.billingAddress.county,
        country: props.billingAddress.country,
        // postcode is captured by Stripe Card input
        // 'postal_code': null,
      },
      email: props.billingAddress.email,
      name: props.billingAddress.name,
      phone: props.billingAddress.telephone,
    }
  : {};
if (
  stripeBillingDetails.address.country &&
  stripeBillingDetails.address.country != "GB"
) {
  switch (stripeBillingDetails.address.country) {
    case "UK":
    case "United Kingdom":
    case "GB":
    case "Great Britain":
      stripeBillingDetails.address.country = "GB";
      break;
    default:
      stripeBillingDetails.address.country = null;
  }
}

onMounted(async () => {
  const res = await useFetch(props.creditUrl);
  const result = await res.json();
  if (res.status === 200) {
    availableCredit.value = result.available;
    overdueCredit.value = result.overdue;
    remainingCredit.value = result.remaining;
  }
});

let stripe = null;
let cardElement = null;
function selectGateway(paymentMethod) {
  selectedPaymentMethod.value = paymentMethod;
  if (paymentMethod === props.cardMotoGatewayType) {
    nextTick(function () {
      stripe = Stripe(props.stripePublishableKey);

      const elements = stripe.elements();

      const style = window.isDarkMode
        ? {
            base: {
              color: "#eeeeee",
              fontFamily:
                '"Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              fontSize: "18px",
              "::placeholder": {
                color: "rgba(255, 255, 255, 0.75)",
              },
            },
          }
        : {
            base: {
              color: "#36474D",
              fontFamily:
                '"Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              fontSize: "18px",
              "::placeholder": {
                color: "rgba(33, 37, 41, 0.75)",
              },
            },
          };

      cardElement = elements.create("card", { style });
      cardElement.mount("#card-element");
      cardElement.on("change", function (event) {
        disablePayWithCardButton.value = !event.complete;
        if (event.error) {
          cardErrorRef.value.textContent = event.error.message;
        } else {
          cardErrorRef.value.textContent = "";
        }
      });
    });
  }
}

function back() {
  selectedPaymentMethod.value = null;
}

async function sendPaymentLink(event) {
  disableSubmitButton(event.target);
  clearErrorMessages(event.target);
  const res = await useFetch(props.paymentUrl, {
    method: "POST",
    body: {
      ...props.paymentMeta,
      ...{
        payment_gateway_type: props.cardLinkGatewayType,
      },
    },
  });

  const result = await res.json();
  if (res.status === 422 || res.status === 400) {
    let message = result.message || "Unknown issue occurred";
    showErrorAfterButton(event.target, message);
    enableSubmitButton(event.target);
  } else if (result.redirect) {
    window.location = result.redirect;
  }
}

async function payWithCard(event) {
  disableSubmitButton(event.target);
  clearErrorMessages(event.target);
  const result = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: stripeBillingDetails,
  });

  const paymentMethodHandler = async (result) => {
    if (result.error) {
      showErrorAfterButton(event.target, result.error);
      enableSubmitButton(event.target);
    } else {
      const res = await useFetch(props.paymentUrl, {
        method: "POST",
        body: {
          ...props.paymentMeta,
          ...{
            payment_gateway_type: props.cardMotoGatewayType,
            stripe_payment_method_id: result.paymentMethod.id,
          },
        },
      });

      const handlerResult = await res.json();
      if (res.status === 422 || res.status === 400) {
        let message = handlerResult.message || "Unknown issue occurred";
        showErrorAfterButton(event.target, message);
        enableSubmitButton(event.target);
      } else if (handlerResult.redirect) {
        window.location = handlerResult.redirect;
      }
    }
  };

  paymentMethodHandler(result);
}

async function payWithAccount(event) {
  disableSubmitButton(event.target);
  clearErrorMessages(event.target);
  const res = await useFetch(props.paymentUrl, {
    method: "POST",
    body: {
      ...props.paymentMeta,
      ...{
        payment_gateway_type:
          offlinePaymentMode.value === "account"
            ? props.accountGatewayType
            : props.bacsGatewayType,
      },
    },
  });

  const result = await res.json();
  if (res.status === 422 || res.status === 400) {
    let message = result.message || "Unknown issue occurred";
    showErrorAfterButton(event.target, message);
    enableSubmitButton(event.target);
  } else if (result.redirect) {
    window.location = result.redirect;
  }
}

function disableSubmitButton(button) {
  button.dataset.originalHTML = button.innerHTML;
  button.disabled = true;
  button.style.minWidth = button.offsetWidth + "px";
  button.innerHTML = '<i class="fa fa-spin fa-spinner"></i>';
}

function enableSubmitButton(button) {
  button.innerHTML = button.dataset.originalHTML;
  button.disabled = false;
  button.style.removeProperty("min-width");
}

function showErrorAfterButton(button, message) {
  let errorHTML = document.createElement("div");
  errorHTML.classList.add("text-danger", "form-error");
  errorHTML.innerText = message;
  button.insertAdjacentElement("afterend", errorHTML);
}

function clearErrorMessages(button) {
  button.parentNode.querySelectorAll(".form-error").forEach((e) => e.remove());
}
</script>

<style lang="scss" scoped>
.payment-option-container {
  aspect-ratio: 1/1;
  flex: 0 1 400px;
  max-height: 400px;
  scale: 1;
  // color: #069fba;
  transition:
    all 0.2s,
    flex-grow 1s,
    flex-shrink 1s,
    flex-basis 1s,
    transform 1s,
    margin 1s;

  &:not(.active):not(.disabled) {
    cursor: pointer;
  }

  &:not(.active):not(.disabled):hover {
    color: #069fba;
    box-shadow: 0 0 1rem 3px rgba(6, 159, 186, 0.3);
    scale: 1.01;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.active {
    flex-grow: 1;
    aspect-ratio: unset;
    min-height: 400px;
  }

  &.inactive {
    flex: 0 0 0;
    transform: scaleX(0) translateX(200px);
    border: unset !important;
  }

  .back-button {
    top: 16px;
    left: 16px;
  }
}
</style>
