<template>
  <div
    :class="notificationClass"
    role="alert"
    style="white-space: pre"
    v-html="notification.text"
  ></div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  notification: {
    type: Object,
    required: true,
    validator: function (value) {
      return (
        Object.prototype.hasOwnProperty.call(value, "icon") &&
        Object.prototype.hasOwnProperty.call(value, "text")
      );
    },
  },
});

const notificationClass = computed(
  () => "alert alert-" + props.notification.icon
);
</script>
