<template>
  <div class="col d-flex flex-column gap-1">
    <ThreeStateToggle
      v-model="state"
      :name="inputName + '_state'"
      :options="Object.values(creditLimitStates)"
      toggle-style="traffic-light"
    />
    <div
      v-show="state === creditLimitStates.FixedCredit.value"
      class="input-group"
    >
      <span class="input-group-text">
        <i class="fas fa-pound-sign"></i>
      </span>

      <input
        class="form-control"
        type="text"
        :name="inputName + '_value'"
        :value="creditLimit != null ? creditLimit.toFixed(2) : null"
        inputmode="numeric"
        pattern="\d+(\.\d{1,2})?"
        placeholder="1.00"
        title="Credit limit in pounds, e.g. 95.50"
        data-error-target=".input-group"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import ThreeStateToggle from "@/components/admin/utilities/threeStateToggle.vue";

/**
 * @typedef {Object} CreditLimitState
 * @property {Object} NoCreditAllowed
 * @property {String} NoCreditAllowed.label
 * @property {String} NoCreditAllowed.value
 * @property {Object} FixedCredit
 * @property {String} FixedCredit.label
 * @property {String} FixedCredit.value
 * @property {Object} InfiniteCredit
 * @property {String} InfiniteCredit.label
 * @property {String} InfiniteCredit.value
 */

/**
 * @type {Object} props
 * @property {String} inputName
 * @property {Number|null} creditLimit
 * @property {CreditLimitState} creditLimitStates
 */
const props = defineProps({
  inputName: {
    type: String,
    required: true,
  },
  creditLimit: {
    type: Number,
    default: null,
  },
  creditLimitStates: {
    type: Object,
    required: true,
  },
});

const state = ref(props.creditLimitStates.NoCreditAllowed.value);

onMounted(() => {
  if (props.creditLimit != null) {
    state.value =
      props.creditLimit === 0
        ? props.creditLimitStates.InfiniteCredit.value
        : props.creditLimitStates.FixedCredit.value;
  }
});
</script>
