<template>
  <PickingRequired
    :items="itemsToPick"
    :wording="wording"
    :locations-route="locationsRoute"
    :locations="locations"
    @requests-picking="pick"
  />
  <AlreadyPicked
    :items="pickedItems"
    :wording="wording"
    @remove-picking="removePick"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import PickingRequired from "@/components/admin/picking/requiresPicking/list.vue";
import AlreadyPicked from "@/components/admin/picking/alreadyPicked/list.vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  locations: {
    type: Array,
    default: () => [],
  },
  wording: {
    type: String,
    default: "picking",
    validator: (propValue) => {
      return ["picking", "allocation"].includes(propValue);
    },
  },
  locationsRoute: {
    type: String,
    default: "",
  },
});

const baseItems = ref(props.items);

const allItems = computed(() => {
  return baseItems.value.map((item) => {
    if (item.picking_lines == null) {
      item.picking_lines = {};
    }
    item.picked_quantity = Object.values(item.picking_lines).reduce(
      (accumulator, pickingLine) => accumulator + pickingLine.quantity,
      0
    );
    return item;
  });
});

const itemsToPick = computed(() => {
  return allItems.value.filter((item) => {
    return item.picked_quantity !== item.total_quantity;
  });
});

const pickedItems = computed(() => {
  return allItems.value.filter(
    (item) => Object.keys(item.picking_lines).length > 0
  );
});

function pick(item, quantity, location) {
  if (item.picking_lines === null) {
    item.picking_lines = {};
  }

  // Try to find existing pick from selected location as we might get away with increasing quantity only.
  for (const [, pickingLine] of Object.entries(item.picking_lines)) {
    if (pickingLine.location.value === location) {
      pickingLine.quantity += quantity;
      return;
    }
  }

  const uuid = Math.floor(Math.random() * Date.now()).toString(36);
  item.picking_lines[uuid] = {
    uuid,
    location:
      props.locations.filter((l) => l.value === location)[0] ?? "Unspecified",
    quantity: quantity,
  };
}

function removePick(uuid) {
  baseItems.value.forEach((item) => delete item.picking_lines[uuid]);
}
</script>
