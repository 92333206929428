<template>
  <input v-if="name" type="hidden" :name="name" :value="currentValue" />
  <div
    :class="{
      'form-check form-switch toggle-wrapper': true,
      [toggleStyle]: true,
      [classes]: true,
    }"
  >
    <input
      :id="id"
      ref="toggle"
      class="form-check-input"
      type="checkbox"
      :disabled="disabled"
      @change.prevent="toggleChange"
    />
  </div>
</template>

<script setup>
import { ref, nextTick, watch } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: Boolean,
  disabled: Boolean,
  name: {
    type: String,
    default: null,
  },
  toggleStyle: {
    type: String,
    default: "traffic-light",
    validator(value) {
      return ["default", "traffic-light"].includes(value);
    },
  },
  classes: {
    type: String,
    default: "",
  },
});

const currentValue = ref(props.modelValue ?? false);
const toggle = ref(null);
const id = Math.random().toString(36);

nextTick(() => setToggleStateByValue(currentValue.value));
watch(currentValue, (newValue) => emit("update:modelValue", newValue));
watch(
  () => props.modelValue,
  (newValue) => setToggleStateByValue(newValue)
);

const toggleChange = function () {
  currentValue.value = !currentValue.value;
  setToggleStateByValue(currentValue.value);
};

const setToggleStateByValue = function (value) {
  toggle.value.checked = value;
};
</script>
