<template>
  <div
    v-if="Object.keys(options).length !== 0"
    class="dropdown table-filter-dropdown"
  >
    <button
      type="button"
      class="btn btn-alt-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ buttonLabel }}
    </button>
    <ul class="dropdown-menu">
      <li>
        <a
          v-for="option in mappedOptions"
          :key="option.value"
          class="dropdown-item"
          :class="{ active: selectedOption == option.value }"
          role="button"
          @click.prevent="btnClick(option)"
        >
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: [String, Number],
    default: null,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    required: true,
  },
});

const selectedOption = ref(props.selected);
const mappedOptions = computed(() => {
  return props.options.map((option, key) => {
    if (typeof option === "object") {
      return {
        value: option.value ? option.value : option.id ? option.id : key,
        label: option.label
          ? option.label
          : option.title
            ? option.title
            : option.name
              ? option.name
              : option,
      };
    }

    return {
      value: option,
      label: option,
    };
  });
});

const buttonLabel = ref("");
updateLabel(
  props.selected
    ? props.options.find((option) => option.value === props.selected).label
    : null
);

const emit = defineEmits(["change"]);
function btnClick(option) {
  if (selectedOption.value === option.value) {
    if (!props.clearable) {
      return;
    }
    selectedOption.value = null;
    updateLabel();
  } else {
    selectedOption.value = option.value;
    updateLabel(option.label);
  }

  emit("change", option.value, option.label);
}

function updateLabel(value) {
  buttonLabel.value = props.label + (value ? ": " + value : "");
}
</script>
