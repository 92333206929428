<template>
  <label for="customer" class="control-label required">Customer</label>
  <FormSelect
    v-model="customer"
    name="customer"
    :url="routes.customers"
    placeholder="Select customer"
    @update:model-value="updateParentWithValues"
  ></FormSelect>
</template>

<script setup>
import { ref } from "vue";
import { debounce } from "lodash";
import { useFetch } from "@/utilities/useFetch.js";
import FormSelect from "@/components/admin/utilities/formSelect.vue";

const emit = defineEmits(["step-value-changed"]);
const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  extras: {
    type: Object,
    default: () => ({}),
  },
  stepsData: {
    type: Object,
    default: () => ({}),
  },
  isInitialLoadingFinished: Boolean,
  isFromQuote: Boolean,
});

let customer = ref(props.extras.preselected.customer);

function updateParentWithValues() {
  emit("step-value-changed", {
    customer: customer.value.value,
    name: customer.value.name,
    email: customer.value.email,
    telephone: customer.value.telephone,
    defaultCurrencyCode: customer.value.default_currency_code,
  });

  window.dispatchEvent(new CustomEvent("clearNotifications"));

  if (customer.value.value) {
    fetchComment(
      props.routes.customer_comments.replace("%customer%", customer.value.value)
    );
  }
}

const fetchComment = debounce(ajaxComment, 350);

let fetchAbort, fetchAbortSignal;
async function ajaxComment(url) {
  if (!url) {
    return;
  }

  // Abort the old one if there was one.
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // Reinitialise the abort controller for each new request.
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  const res = await useFetch(url, { signal: fetchAbortSignal });
  let response = await res.json();
  if (response.comments) {
    window.dispatchEvent(
      new CustomEvent("pushNotification", {
        detail: {
          icon: "warning",
          text: "<b>Customer Notes: </b>" + response.comments,
        },
      })
    );
  }
}
</script>
