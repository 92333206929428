<template>
  <div class="row mb-4 datatable-options">
    <div class="col-md-2">
      <select v-model.number="limit" class="form-select">
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    </div>
    <div class="col ms-auto mt-2 mt-md-0">
      <div class="input-group">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control"
          placeholder="Search all refunds.."
        />
        <span class="input-group-text bg-body">
          <i class="fa fa-search"></i>
        </span>
      </div>
      <div
        v-if="years.length > 0"
        class="d-flex gap-2 mt-2 justify-content-end"
      >
        <TableFilter
          label="Year Issued"
          :options="years"
          @change="(value, label) => filterChanged('created_at_year', value)"
        />
        <TableFilter
          label="Month Issued"
          :options="months"
          @change="(value, label) => filterChanged('created_at_month', value)"
        />
      </div>
    </div>
  </div>

  <Datatable
    :url="ajaxUrl"
    :limit="limit"
    :order-by="orderBy"
    :order-asc="orderAsc"
    :search="searchTerm"
    :custom-filter="customFilter"
    refresh-on="refunds-list:refresh"
  >
    <template #headings>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="id"
        @set-order-by="setOrder"
      >
        ID
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="credit_note_number"
        @set-order-by="setOrder"
      >
        Credit Note
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer"
        @set-order-by="setOrder"
      >
        Customer
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="customer_order_id"
        @set-order-by="setOrder"
      >
        Order
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="total"
        @set-order-by="setOrder"
      >
        Total Refund Amount
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_by"
        @set-order-by="setOrder"
      >
        Issued by
      </SortableTh>
      <SortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Issued on
      </SortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="refund in data" :key="refund.id">
        <td>{{ refund.id }}</td>
        <td>{{ refund.credit_note_number }}</td>
        <td v-html="refund.customer"></td>
        <td>
          <span
            v-if="refund.customer_order_quick_view_url !== null"
            class="me-1"
          >
            <CustomerOrderDrawer
              :source-url="refund.customer_order_quick_view_url"
            ></CustomerOrderDrawer>
          </span>
          <span v-html="refund.customer_order"></span>
        </td>
        <td>{{ refund.formatted_total }}</td>
        <td>{{ refund.created_by }}</td>
        <td>{{ refund.created_at }}</td>
        <td v-html="refund.actions"></td>
      </tr>
    </template>
    <template #smallScreenLayout="{ data }">
      <div v-for="refund in data" :key="refund.id" class="list-group-item">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Refund {{ refund.id }}</h5>
          <small>{{ refund.created_at }}</small>
        </div>
        <p class="mb-1">
          Customer order
          <CustomerOrderDrawer
            :source-url="refund.customer_order.quick_view_url"
          />
          {{ refund.customer_order.id }} for
          <span v-html="refund.customer_order.customer"></span>
        </p>
        <small>{{ refund.formatted_total }}</small>
        <small class="d-block">{{ refund.created_by }}</small>
        <div class="mt-2" v-html="refund.actions"></div>
      </div>
    </template>
  </Datatable>
</template>

<script setup>
import { ref, reactive } from "vue";
import Datatable from "@/components/admin/utilities/datatable.vue";
import SortableTh from "@/components/admin/datatable/sortableTh.vue";
import CustomerOrderDrawer from "@/components/admin/customerOrders/customerOrderDrawer.vue";
import TableFilter from "@/components/admin/datatable/tableFilter.vue";

const props = defineProps({
  ajaxUrl: {
    type: String,
    default: "",
  },
  minYear: {
    type: Number,
    default: 0,
  },
});

const orderBy = ref("id");
const orderAsc = ref(false);
const limit = ref(50);
const searchTerm = ref(null);
const customFilter = reactive({});
const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];
const years = props.minYear
  ? Array.from(
      { length: new Date().getFullYear() - props.minYear + 1 },
      (_, k) => {
        return { label: k + props.minYear, value: k + props.minYear };
      }
    )
  : [];

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
const filterChanged = function (key, value) {
  if (customFilter[key] == value || !value) {
    delete customFilter[key];
  } else {
    customFilter[key] = value;
  }
};
</script>
