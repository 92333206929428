<template>
  <div class="table-responsive">
    <table ref="table" class="table table-bordered table-vcenter">
      <thead>
        <tr>
          <th style="width: 30px"></th>
          <th>Order</th>
          <th>Customer</th>
        </tr>
      </thead>
      <template v-for="(order, index) in orders" :key="index">
        <template v-if="order.id">
          <tbody
            :class="{
              'js-table-sections-header': true,
              'show table-active': order.open,
            }"
          >
            <tr @click="(e) => tableSectionHeaderClick(e, order.id)">
              <td class="text-center">
                <i class="fa fa-angle-right text-muted"></i>
              </td>
              <td v-html="order.url"></td>
              <td>{{ order.customer_name }}</td>
            </tr>
          </tbody>
          <tbody class="fs-xs">
            <tr>
              <th></th>
              <th>Part</th>
              <th class="text-center">Outstanding Quantity</th>
            </tr>
            <tr v-for="(item, itemIndex) in order.items" :key="itemIndex">
              <td></td>
              <td>
                <div class="d-flex gap-2">
                  <img
                    class="img-thumbnail thumbnail-xs object-fit-cover"
                    :src="
                      item.main_image ? item.main_image : partPlaceholderImage
                    "
                  />
                  <div>
                    <a v-if="item.url" :href="item.url" target="_blank">
                      {{ item.name }}
                    </a>
                    <span v-else>
                      {{ item.name }}
                    </span>
                    <div v-if="item.sku" class="text-muted">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">{{ item.unallocated }}</td>
            </tr>
          </tbody>
        </template>
      </template>
    </table>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from "vue";

const props = defineProps({
  suggestedOrders: {
    type: Array,
    default: () => [],
  },
});

const orders = reactive(
  Array.isArray(props.suggestedOrders)
    ? props.suggestedOrders.reduce(
        (obj, item) => ({ ...obj, [item.id]: item }),
        {}
      )
    : {}
);
const table = ref(null);
const partPlaceholderImage = window.placeholderImage;

const tableSectionHeaderClick = function (event, orderId) {
  if (
    event.target.type !== "button" &&
    event.target.role !== "button" &&
    event.target.tagName.toLowerCase() !== "a" &&
    event.target.parentNode.nodeName.toLowerCase() !== "a" &&
    event.target.parentNode.nodeName.toLowerCase() !== "button"
  ) {
    orders[orderId]["open"] = !orders[orderId]["open"];
  }
};
watch(
  () => orders,
  (newValue) => {
    for (const [_, data] of Object.entries(newValue)) {
      if (!Object.prototype.hasOwnProperty.call(data, "open")) {
        data["open"] = false;
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
