<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped table-vcenter mb-0">
      <thead>
        <tr>
          <th width="50%">Part</th>
          <th class="text-center" width="25%">Qty</th>
          <th width="25%">Unit Price</th>
          <th style="width: 2%"></th>
        </tr>
      </thead>
      <tbody>
        <KitComponent
          v-for="(component, index) in components"
          :key="component.id"
          :index="index"
          :kit-component="component"
          :parts-url="routes.parts"
          @remove-item="() => removeLine(index)"
        />
      </tbody>
    </table>
  </div>
  <div class="d-flex gap-2 mt-3">
    <button class="btn btn-sm btn-secondary" type="button" @click="addLine">
      Add Component
    </button>
  </div>
</template>

<script setup>
import { nextTick, reactive, watch } from "vue";
import KitComponent from "@/components/admin/kits/form/kitComponent.vue";
import { Eventbus } from "@/utilities/eventbus.js";

const props = defineProps({
  routes: {
    type: Object,
    default: () => ({}),
  },
  preselected: {
    type: Array,
    default: () => [],
  },
});

const components = reactive(props.preselected ? props.preselected : []);

watch(
  () => ({ ...components }),
  () => {
    let suggestedTotal = 0;
    for (const [_, component] of Object.entries(components)) {
      if (component.part && component.part.retail_price) {
        suggestedTotal += component.part.retail_price * component.quantity;
      }
    }

    window.dispatchEvent(
      new CustomEvent("suggestedKitPriceUpdated", {
        detail: suggestedTotal,
      })
    );
  },
  {
    deep: true,
    immediate: true,
  }
);

function addLine() {
  components.push({
    id: Math.floor(Math.random() * Date.now()).toString(36),
    quantity: 1,
  });
}

function removeLine(removeAtIndex) {
  Eventbus.emit("disable-kit-component-part-watcher"); // Emit a signal to disable watcher.

  const component = components[removeAtIndex];
  const newComponents = [...components];
  newComponents.splice(removeAtIndex, 1);

  components.splice(0, components.length, ...newComponents);

  Eventbus.emit("kit-component-part-selected", {
    index: removeAtIndex,
    oldPart: undefined,
    part: {
      delete: true,
      ...component.part,
    },
  });

  // Use Vue's `nextTick` to ensure the watcher is re-enabled after reactivity settles.
  nextTick(() => {
    Eventbus.emit("enable-kit-component-part-watcher"); // Re-enable watcher after removal.
  });
}

if (components.length === 0) {
  addLine();
}
</script>
