<template>
  <div class="mb-4">
    <div class="table-responsive-md">
      <table
        class="table table-bordered table-striped table-vcenter items-table mb-0"
      >
        <thead>
          <tr>
            <th style="min-width: 150px">Reason</th>
            <th class="text-center" style="width: 260px">
              Quotes With This Reason
            </th>
            <th style="width: 2%"></th>
          </tr>
        </thead>
        <tbody>
          <reason
            v-for="(reason, id) in reasonList"
            :id="id"
            :key="id"
            v-model="reasonList[id].reason"
            :count="reasonList[id].quotes_count"
            @remove-line="removeLine(id)"
          ></reason>
        </tbody>
      </table>
    </div>
    <button
      class="btn btn-sm btn-secondary mt-3"
      type="button"
      @click="addLine"
    >
      Add Reason
    </button>
  </div>
</template>

<script setup>
import { reactive } from "vue";

const props = defineProps({
  reasons: {
    type: Object,
    default: null,
  },
});

let reasonList = reactive(props.reasons || {});

const removeLine = function (id) {
  delete reasonList[id];
};

const addLine = function () {
  reasonList[Math.floor(Math.random() * Date.now()).toString(36)] = {
    reason: "",
    quotes_count: 0,
  };
};

if (Object.keys(reasonList).length === 0) {
  addLine();
}
</script>
